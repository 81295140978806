import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import { environment } from "../../environments/environment";
import { AuthenticationService } from "./authentication.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
	providedIn: "root",
})
export class DetailsService {
	globalDetails = {};
	globalBrands = [];
	globalCampaigns = [];
	globalPartners = [];
	anticipos = {};
	activeDeals = [];

	constructor(
		private http: HttpClient,
		private apollo: Apollo,
		private authService: AuthenticationService
	) {}

	async getPartners() {
		//await this.authService.verifyToken()

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners {
							nodes {
								createdAt
								type
								ragioneSociale
								id
								telefonoGenerale
								emailGenerale
								emailAmm
								nomeCognomeLegale
								pivaCodiceFiscale
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getMainContractor(partnerId) {
		//await this.authService.verifyToken()
		let temp: any;
		await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners(condition:{
							id: ${partnerId}
						  }) {
							nodes {
							nomeMainContractor
							idMainContractor
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise()
			.then((res) => {
				temp = res.data;
				temp = temp.allPartnerPartners.nodes[0];
			});
		return temp;
	}
	async getPartnersCampaignsAndListing(partnerId, detailListId) {
		// await this.authService.verifyToken()

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(condition:{
          id: ${partnerId}
        }){
          nodes{
            id
            nomeCognomeLegale
            ragioneSociale
            emailBo
            emailGenerale
            emailAmm
            partnerContractByPartnerId{
              partnerDealsByContractId{
                nodes{
                  id
                  partnerDetailsByDealId(condition:{
                    detailList:"${detailListId}"
                  }){
                    nodes{
                      id
                      createdAt
                      updatedAt
                      deleted
                      googleSheetLink
                      status
                      dealId
					  idPrebill
                      amountIn
                      amountOut
                      anticipo
                      meseDiCompetenza
                      partnerId
                    }
                  }
                  partnerCampaignByCampaignId{
                    id
                    name
                  }
                  partnerListingByListingId{
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
      `,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	getUserId() {
		// TO DO, CHANGE AFTER TOKEN ID
		var myRawToken = localStorage.getItem("anagraficaToken");
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(myRawToken);
		return decodedToken.data.id;
	}

	async createDetail(
		partnerId,
		link,
		amountIn,
		amountOut,
		volume,
		dealId,
		detailListId,
		startMonth,
		anticipo
	) {
		await this.authService.verifyToken();
		var mut = `
    mutation{
      createPartnerDetail(input:{
        clientMutationId: "${this.getUserId()}"
        partnerDetail:{
          googleSheetLink:"${link}"
          createdAt:"${new Date().toISOString()}"
          updatedAt:"${new Date().toISOString()}"
          deleted:false
          status:"open"
          anticipo:"${anticipo}"
          dealId:${dealId}
          amountIn:"${amountIn}"
          amountOut:"${amountOut}"
          volume: ${volume}
          meseDiCompetenza:"${startMonth}"
          detailList:"${detailListId}"
          partnerId: "${partnerId}"
        }
      }){
        partnerDetail{
          googleSheetLink
          createdAt
          updatedAt
          deleted
          status
          anticipo
          dealId
          amountIn
          amountOut
          volume
          meseDiCompetenza
          detailList
          partnerId
          id  
          partnerDealByDealId{
            partnerCampaignByCampaignId{
              id
              name
            }
            partnerListingByListingId{
              id
              name
            }
          }
          partnerPartnerByPartnerId{
            id
            nomeCognomeLegale
            ragioneSociale
            emailBo
            emailGenerale
            emailAmm
            agentUserByAccountId{
              username
              email
            }
          }
        }
      }
    }
    `;

		// console.log(mut)

		return await this.apollo
			.mutate({
				mutation: gql`
					${mut}
				`,
			})
			.toPromise();
	}

	async deleteDetail(detailId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
	  mutation{
		deletePartnerDetailById(input:{
		  clientMutationId:"${this.getUserId()}"
		  id: ${detailId}
		}){
		partnerDetail{
		  status
		  id
		  partnerId
		  anticipo
		}
	  }
	}
	`,
			})
			.toPromise();
	}

	async getIdPreBill(idPrebill) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
	  query{
		allPrebills(condition: { idPrebill: ${idPrebill}}) {
			nodes {
			  idPrebill
			}
		  }
	}
	`,
			})
			.toPromise();
	}
	async getLastIdPrebill() {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
					query {
						allPrebills(last: 1) {
							nodes {
								idPrebill
							}
						}
					}
				`,
			})
			.toPromise()
			.then((res) => {
				let temp: any = res.data;
				temp = temp.allPrebills.nodes[0].idPrebill;
				return temp;
			});
	}

	async createIdPreBill(preBill) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
					mutation {
						createPrebill(
							input: {
								prebill: {
									createdAt: "${new Date().toISOString()}"
									updatedAt: "${new Date().toISOString()}"
									idDetail: ${preBill.idDetail}
									idPartner: ${preBill.partnerId}
									idPrebill: ${preBill.idPreBill}
									idCampaign:${preBill.campaignId}
									googleSheetLink: "${preBill.googleSheet}"
								}
							}
						) {
							prebill {
								idPrebill
							}
						}
					}
				`,
			})
			.toPromise();
	}

	async updateIdPrebillDetails(idPrebill, idDetail) {
		return await this.apollo
			.mutate({
				mutation: gql`
				mutation {
					updatePartnerDetailById(
					  input: {
						partnerDetailPatch: { idPrebill: ${idPrebill} }
						id: ${idDetail}
						clientMutationId: "1"
					  }
					) {
					  clientMutationId
					}
				  }
				  
				`,
			})
			.toPromise();
	}

	async getDetailsByDetailList(detailListId) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerDetails(condition:{
          detailList:"${detailListId}"
        }) 
        {
          nodes{
            googleSheetLink
            createdAt
            updatedAt
            deleted
            status
            anticipo
            dealId
            amountIn
            amountOut
            volume
            meseDiCompetenza
            detailList
            partnerId
            emailStatus
			idPrebill
            id
            partnerDealByDealId{
              partnerCampaignByCampaignId{
                name
                id
				mandataria
              }
              partnerListingByListingId{
                id
                name
              }
            }
            partnerPartnerByPartnerId{
              id
              nomeCognomeLegale
              ragioneSociale
              emailBo
              emailGenerale
              emailAmm
			  nomeMainContractor
              agentUserByAccountId{
                username
                email
              }
            }
          }
        }
      }
      `,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getBrandsAndCampaigns() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerBrands {
							nodes {
								id
								name
								partnerCampaignsByBrandId {
									nodes {
										id
										name
									}
								}
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getActiveDeals() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerDeals(condition: { isActive: true }) {
							nodes {
								id
								campaignId
								partnerContractByContractId {
									id
									partnerId
								}
								brandId
								listingId
								isActive
								startMonth
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getAnticipoForPartner(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerTotaleAnticipos(condition:{
          partnerId:"${partnerId}"
        }){
          nodes{
            partnerId
            anticipo
          }
        }
      }
      `,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getPartnerCampaignsAndListinos() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners {
							nodes {
								id
								nomeCognomeLegale
								ragioneSociale
								partnerContractByPartnerId {
									partnerDealsByContractId(
										condition: { isActive: true }
									) {
										nodes {
											id
											partnerCampaignByCampaignId {
												id
												name
											}
											partnerListingByListingId {
												id
												name
											}
										}
									}
								}
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async updateDetail(detailId, url, amIn, amOut, vol, ant, meseDiCompetenza) {
		await this.authService.verifyToken();

		var mut = `
    mutation{
      updatePartnerDetailById(input:{
        clientMutationId:"${this.getUserId()}"
        id: ${+detailId}
        partnerDetailPatch:{
          amountIn:"${amIn}"
          amountOut:"${amOut}"
          googleSheetLink:"${url}"
          anticipo: "${ant}"
          volume: ${vol}
          meseDiCompetenza:"${meseDiCompetenza}"
        }
      }){
        partnerDetail{
          googleSheetLink
          createdAt
          updatedAt
          deleted
          status
          anticipo
          dealId
          amountIn
          amountOut
          volume
          meseDiCompetenza
          detailList
          partnerId
          id
          partnerDealByDealId{
            partnerCampaignByCampaignId{
              name
              id
            }
            partnerListingByListingId{
              id
              name
            }
          }
          partnerPartnerByPartnerId{
            id
            nomeCognomeLegale
            ragioneSociale
            emailBo
            emailGenerale
            emailAmm
            agentUserByAccountId{
              username
              email
            }
          }
        }
      }
    }`;

		// console.log(mut)

		return await this.apollo
			.mutate({
				mutation: gql`
					${mut}
				`,
			})
			.toPromise();
	}

	async changeStatus(detailId, status) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerDetailById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${detailId}
          partnerDetailPatch:{
            status:"${status}"
          }
        }){
          partnerDetail{
            status
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async getDetailsCharts(startMonth) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      query{
        allPartnerReportDettaglios(filter:{
          meseDiCompetenza:{equalTo:"${startMonth}"}
        }){
          nodes{
            volume
            anticipo
            amountOut
            amountIn
            status
            cstatus
            cinviato
            cpagato
            copen
          }
        }
      }
      `,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getTotalAnticipos() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerTotaleAnticipos {
							nodes {
								anticipo
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async sendEmail(
		emails,
		cc,
		subject,
		partnerName,
		details,
		totalAnticipo,
		type,
		accountName,
		accountEmail,
		notes,
		templateId,
		idPrebill
	) {
		await this.authService.verifyToken();
		var mailBody = {
			to: emails,
			cc: cc,
			from: "noreply@wearefiber.com",
			subject: `${subject}`,
			text: "Fiber",
			partner_name: `${partnerName}`,
			details: details, // OR DEALS
			amount: `${totalAnticipo}`,
			type: `${type}`, // Faturazzione vs Bonifico
			accountName: `${accountName}`,
			accountEmail: `${accountEmail}`,
			notes: `${notes}`, // Ragione Sociale
			templateId: `${templateId}`,
			idPrebill: `${idPrebill}`,
		};

		return await this.http
			.post(`http://localhost:3000/sendgrid`, mailBody, {
				headers: {
					"Content-Type": "application/json",
					responseType: "text",
				},
			})
			.toPromise();
	}

	async sendEmailCommissioning(
		emails,
		campaignId,
		listingId,
		subject,
		accountEmails,
		fileName
	) {
		await this.authService.verifyToken();

		var mailBody = {
			to: emails,
			cc: accountEmails, // Plus analyst emails
			from: "noreply@wearefiber.com",
			subject: `${subject}`,
			campaign_id: campaignId,
			listing_id: listingId,
			file: `https://api.partners.wearefiber.com/media/${fileName}`,
		};

		return await this.http
			.post(`${environment.API_URL}commissioning`, mailBody)
			.toPromise();
	}
}

// TO DO
// new mail for partner confirmation
