import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import { AuthenticationService } from "./authentication.service";
import gql from "graphql-tag";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
	providedIn: "root",
})
export class DetailsListService {
	showDashboard: boolean = false;
	showTable: boolean = false;

	constructor(
		private http: HttpClient,
		private apollo: Apollo,
		private authService: AuthenticationService
	) {}

	async getDetailLists() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerDetaillists {
							nodes {
								status
								id
								startMonth
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	getUserId() {
		// TO DO, CHANGE AFTER TOKEN ID
		var myRawToken = localStorage.getItem("anagraficaToken");
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(myRawToken);
		return decodedToken.data.id;
	}

	async createDetailList(startMonth) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        createPartnerDetaillist(input:{
          clientMutationId: "${this.getUserId()}",
          partnerDetaillist:{
            startMonth: "${startMonth}"
            createdAt: "${new Date().toISOString().split("T")[0]}"
            deleted: false
            status: "open"
            updatedAt: "${new Date().toISOString().split("T")[0]}"
          }
        }){
          clientMutationId
          partnerDetaillist{
            id
          }
        }
      }
      `,
			})
			.toPromise();
	}
}
