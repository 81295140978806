/*
  MA BO HALLALL QE KE KAQ SHUM KOD NE NJI KOMPONNENT TE VETEM PO KSHU DUHEJ PER KOHEN QE ESHT BO 
*/

import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DetailsService } from "../../../services/details.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";

import * as $ from "jquery";
import * as Handsontable from "handsontable";
import "select2";
import "datatables.net";
import "datatables.net-se";
import * as XLSX from "xlsx";
import { isNumber } from "util";
import { HelpersService } from "../../../../app/services/helpers.service";
import { SettingsService } from "../../../../app/services/settings.service";
import { DetailsListService } from "../../../services/details-list.service";
import { AccountPartnersService } from "../../../services/account-partners.service";
import { bindAll, identity } from "lodash";

@Component({
	selector: "app-details",
	templateUrl: "./details.component.html",
	styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit {
	hot: Handsontable.default;
	showNotes = true;
	totalAmount = 0;
	partnerForm: FormGroup;
	startMonth: string;
	detailListId: any;
	detailListStatus = "";
	partners;
	preBill = [];
	idPrebill = null;
	filtro = "";
	generateIdPrebill = true;
	duplicateBill = false;
	emailsPartner = [];
	partnersForSelect = [];
	detailForm: FormGroup;
	nomePartner;
	nomeCampagna;
	partnerSelectedId: number;
	partnersReady = false;
	selectedPartnerName = "";
	deals = [];
	dealTemp;
	note;
	subject;
	toInvioMassivo = 0;
	plural;
	userTemp;
	nomeMainContractor;
	idMainContractor = null;
	dataLoading = false;
	dateForm: FormGroup;
	details = [];
	mydetails = [];
	resTemp;
	allDetailsTemp; // DETAILS FOR MAIN PAGE AFTER DETAILLIST
	allDetails = [];
	detailsByBrand = [];
	detailsByPartner = [];
	groupByBrand = [];
	groupByPartner = [];
	allDetailsPartner = [];
	allDetailsBrand = [];
	allDetailsPartnerDatatable = [];
	allDetailsBrandDatatable = [];
	whichGroup = "a";
	emailToSend = [];
	sent = false;
	isAnticipo = false;
	isRetifica = false;
	anticipoFormVal = "0.0";
	retificaFormVal = "0.0";
	retificaFormLink = "";
	campaignNameEdit = "";
	editDetail = false;
	detailIdToBeUpdated = -1;
	brandForBread = "";
	partnerForBread = "";
	totalAnticipo = 0;
	anticipTemp;
	totalePayIn = 0;
	totalePayOut = 0;
	saldoAsInOverall = 0;
	sommaAnticipoPre: number = 0;
	sommaAnticipoPost: number = 0;

	//EMAIL
	/// e - mail ////
	sendTo;
	ccTo;
	oneEmailWrong = false;
	eMailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	accountName = "";
	accountEmail = "";

	log: string = "";

	emailForm: FormGroup;

	noEmail = true;
	sendEmail = false;
	allDetailsDataTable = [];
	detailIdForMail: any;
	////////////////////BRANDS AND CAMPAIGNS CODE////////////////////////
	brands = [];
	campaigns = [];
	campaignsForBrand = [];
	partnersWithCampaignListino = [];
	partnersResTemp;
	brandsRes;
	brandSelectedId: number;
	selectedCampaignId = 0;
	campaignsReady = false;
	partnersForCampaign = [];
	detailForm2: FormGroup;
	dateForm2;
	addFromCampaign = false;
	isTableValid = true;

	floatPattern = /^[+-]?\d+(\.\d+)?$/;
	anticipoFloatPattern = /^[-+]?\d+(\.\d+)?$/;

	status = "all";
	fileAsMatrix = [];
	fileName = "";
	isFileValid = false;

	working = false;
	columnsDontMatch = false;
	mandatariaSelected = null;
	mandatariaToSend = null;
	validDetailsFromFile = false;
	validDetailsAfterDealFromFile = false;
	validDetailsForUpload = [];
	activeDeals = [];
	dealsResp;

	partnerNameForMail = "";
	partnerDetailsForMail = [];
	partnerTotalAnticipoForMail = 0;
	duplicatesInFile = false;

	fileErrors = [];

	mandatarie = [];
	chartsReady = false;
	charts = {
		total: 0,
		totalPartners: 0,
		amountIn: 0,
		amountOut: 0,
		open: 0,
		inviato: 0,
		pagato: 0,
		inviato_parziale: 0,
		pagato_parziale: 0,
		posAnticipo: 0,
		negAnticipo: 0,
		totalAnticipo: 0,
		volume: 0,
	};

	showDashboard: boolean = this.detailsListService.showDashboard;
	showTable: boolean = this.detailsListService.showTable;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private chRef: ChangeDetectorRef,
		private modalService: NgbModal,
		private data2: DetailsService,
		private helpers: HelpersService,
		private settingService: SettingsService,
		private accountPartnerService: AccountPartnersService,
		private detailsListService: DetailsListService,
		private detailsService: DetailsService
	) {
		this.detailListId = this.route.snapshot.params.id;

		var start = localStorage.getItem("startMonth").split("-");
		this.startMonth = `${start[1]}-${start[0]}`;
		this.detailListStatus = localStorage.getItem("status");

		this.emailForm = this.fb.group({
			emailSubject: ["", [Validators.required]],
			notes: ["", []],
		});
	}

	async ngOnInit() {
		//GET CHARTS FIRST SO THAT USER DOESNT STAY HANGING WAITING FOR ALL DETAILS
		await this.data2
			.getDetailsCharts(localStorage.getItem("startMonth"))
			.then((res) => {
				this.resTemp = res;

				for (const partner of this.resTemp.data
					.allPartnerReportDettaglios.nodes) {
					this.charts.total += +partner.cstatus;
					this.charts.open += +partner.copen;
					this.charts.inviato += +partner.cinviato;
					this.charts.pagato += +partner.cpagato;
					this.charts.volume += +partner.volume;
					this.charts.amountIn += +partner.amountIn;
					this.charts.amountOut += +partner.amountOut;
					this.charts.totalPartners += 1;

					if (partner.anticipo > 0) {
						this.charts.posAnticipo += +partner.anticipo;
					} else {
						this.charts.negAnticipo += +partner.anticipo;
					}

					if (partner.status === "inviato_parziale") {
						this.charts.inviato_parziale += 1;
					} else if (partner.status === "pagato_parziale") {
						this.charts.pagato_parziale += 1;
					}
				}
				this.charts.negAnticipo = Math.abs(this.charts.negAnticipo);
			})
			.catch((e) => {
				console.error(e);
			});

		await this.data2.getTotalAnticipos().then((res) => {
			this.resTemp = res;

			for (const ant of this.resTemp.data.allPartnerTotaleAnticipos
				.nodes) {
				this.charts.totalAnticipo += +ant.anticipo;
			}
		});
		this.chartsReady = true;
		this.chRef.detectChanges();

		// IF WE HAVE THE DETAILS ON OUR LOCAL STORAGE DONT GET FROM DB
		if (this.data2.globalPartners.length > 0) {
			// console.log('Getting partners locally')
			this.partnersWithCampaignListino = this.data2.globalPartners;
		} else {
			// console.log('Getting partners from DB')
			await this.data2
				.getPartnerCampaignsAndListinos()
				.then((res) => {
					this.partnersResTemp = res.data;
					this.partnersResTemp =
						this.partnersResTemp.allPartnerPartners.nodes;

					for (const partner of this.partnersResTemp) {
						if (
							!!partner.partnerContractByPartnerId &&
							!!partner.partnerContractByPartnerId
								.partnerDealsByContractId
						) {
							for (const deal of partner
								.partnerContractByPartnerId
								.partnerDealsByContractId.nodes) {
								this.partnersWithCampaignListino.push({
									id: deal.id,
									partnerName:
										partner.nomeCognomeLegale.toUpperCase(),
									partnerId: partner.id,
									campaignName:
										deal.partnerCampaignByCampaignId.name,
									campaignId:
										deal.partnerCampaignByCampaignId.id,
									listingName:
										deal.partnerListingByListingId.name,
									listingId:
										deal.partnerListingByListingId.id,
								});
							}
						}
					}

					this.data2.globalPartners =
						this.partnersWithCampaignListino;
				})
				.catch((err) => {
					console.log(err);
				});
		}

		if (this.data2.globalBrands.length > 0) {
			// console.log('Getting brands locally')
			this.brands = this.data2.globalBrands;
			this.campaigns = this.data2.globalCampaigns;
			this.campaignsReady = true;
		} else {
			// console.log('Getting brands from DB')
			await this.data2
				.getBrandsAndCampaigns()
				.then((res) => {
					this.brandsRes = res.data;
					this.brandsRes = this.brandsRes.allPartnerBrands.nodes;
					for (const brand of this.brandsRes) {
						let campaigns = brand.partnerCampaignsByBrandId.nodes;
						for (const campaign of campaigns) {
							this.campaigns.push({
								brandId: brand.id,
								brandName: brand.name,
								campaignId: campaign.id,
								campaignName: campaign.name,
							});
						}
						this.brands.push({
							id: brand.id,
							name: brand.name,
						});
					}
					//console.log(this.campaigns, this.brands)
					this.data2.globalBrands = this.brands;
					this.data2.globalCampaigns = this.campaigns;
					this.campaignsReady = true;
				})
				.catch((err) => {
					console.log(err);
				});
		}

		if (!!this.data2.globalDetails[`${this.detailListId}`]) {
			console.log("Got details from service");
			// setTimeout( () => {
			this.regroup();
			$("#a").addClass("active");
			$("#a").addClass("focus");
			// },400)
		} else {
			await this.data2
				.getDetailsByDetailList(this.detailListId)
				.then((res) => {
					this.allDetails = [];
					this.allDetailsTemp = res.data;
					this.allDetailsTemp =
						this.allDetailsTemp.allPartnerDetails.nodes;
					//GET ALL DETAILS
					for (const detail of this.allDetailsTemp) {
						var amIn = parseFloat(detail.amountIn)
							.toFixed(2)
							.replace(".", ",");
						var amOut = parseFloat(detail.amountOut)
							.toFixed(2)
							.replace(".", ",");
						var ant = parseFloat(detail.anticipo)
							.toFixed(2)
							.replace(".", ",");
						var vol = !!detail.volume
							? parseFloat(detail.volume)
									.toFixed(2)
									.replace(".", ",")
							: "0.00";
						var date = detail.meseDiCompetenza.split("-");
						this.allDetails.push({
							id: detail.id,
							date: detail.meseDiCompetenza,
							createdAt: detail.createdAt,
							updatedAt: detail.updatedAt,
							deleted: detail.deleted,
							googleSheetLink: detail.googleSheetLink,
							status: detail.status,
							amountIn: amIn,
							amountOut: amOut,
							anticipo: ant,
							volume: vol,
							emailStatus: detail.emailStatus,
							meseDiCompetenza: `${date[1]}-${date[0]}`,
							dealId: detail.dealId,
							idPrebill: detail.idPrebill,
							show: true,
							nomeMainContractor: detail.nomeMainContractor,
							mandataria:
								detail.partnerDealByDealId
									.partnerCampaignByCampaignId.mandataria,
							campaingName:
								detail.partnerDealByDealId
									.partnerCampaignByCampaignId.name,
							campaingId:
								detail.partnerDealByDealId
									.partnerCampaignByCampaignId.id,
							listingName:
								detail.partnerDealByDealId
									.partnerListingByListingId.name,
							listingId:
								detail.partnerDealByDealId
									.partnerListingByListingId.id,
							partnerId: detail.partnerId,
							partnerName:
								detail.partnerPartnerByPartnerId.nomeCognomeLegale.toUpperCase(),
							ragioneSociale:
								detail.partnerPartnerByPartnerId
									.ragioneSociale || "",
							emGen: !!detail.partnerPartnerByPartnerId
								.emailGenerale
								? detail.partnerPartnerByPartnerId.emailGenerale
								: "ska",
							emAmm: !!detail.partnerPartnerByPartnerId.emailAmm
								? detail.partnerPartnerByPartnerId.emailAmm
								: "ska",
							emBo: !!detail.partnerPartnerByPartnerId.emailBo
								? detail.partnerPartnerByPartnerId.emailBo
								: "ska",
							accountName: !!detail.partnerPartnerByPartnerId
								.agentUserByAccountId
								? detail.partnerPartnerByPartnerId
										.agentUserByAccountId.username
								: "",
							accountEmail: !!detail.partnerPartnerByPartnerId
								.agentUserByAccountId
								? detail.partnerPartnerByPartnerId
										.agentUserByAccountId.email
								: "",
						});
					}

					this.data2.globalDetails[`${this.detailListId}`] =
						this.allDetails;

					this.regroup();

					$("#a").addClass("active");
					$("#a").addClass("focus");
				})
				.catch((err) => {
					console.log(err);
				});
		}

		if (this.data2.activeDeals.length > 0) {
			console.log("Getting active deals from service");
			this.activeDeals = this.data2.activeDeals;
		} else {
			await this.data2
				.getActiveDeals()
				.then((res) => {
					this.dealsResp = res.data;
					this.activeDeals = this.dealsResp.allPartnerDeals.nodes;
					this.data2.activeDeals = this.activeDeals;

					//console.log(this.activeDeals)
				})
				.catch((err) => {
					console.log(err);
				});
		}

		await this.data2
			.getPartners()
			.then((res) => {
				this.partners = res.data;
				this.partners = this.partners.allPartnerPartners.nodes;
				this.partnersReady = true;
			})
			.catch((err) => {
				console.log(err);
			});

		for (const partner of this.partners) {
			this.partnersForSelect.push({
				id: partner.id,
				text: `${
					!!partner.nomeCognomeLegale
						? partner.nomeCognomeLegale.toUpperCase()
						: "-"
				} - ${
					!!partner.ragioneSociale
						? partner.ragioneSociale.toUpperCase()
						: ""
				}`,
			});
		}
	}

	openCampaignTableModal(campaignTableContent) {
		this.modalService.open(campaignTableContent, {
			centered: true,
			backdrop: "static",
			keyboard: false,
			size: "lg",
			windowClass: "myLargeModal",
		});
		this.initHOTtable();
	}

	initHOTtable() {
		this.isTableValid = true;
		this.fileErrors = [];

		var hotElement = document.querySelector("#hot");
		var hotSettings: Handsontable.default.GridSettings =
			this.helpers.getHOTsettings();
		this.hot = new Handsontable.default(hotElement, hotSettings);
	}

	submitHOTtable() {
		let tableData = this.hot.getData();
		this.isTableValid = true;
		this.fileErrors = [];

		this.hot.validateCells((isPass) => {
			if (isPass) {
				console.log("Saving data");
				this.validDetailsForUpload = [];
				this.fileAsMatrix = tableData;

				var resp = this.findValidDetails();

				if (!this.validDetailsAfterDealFromFile) {
					console.log("DEAL ID CHECK STOPPED");
					this.isTableValid = false;
				}

				if (!this.validDetailsFromFile) {
					console.log("DIDNT FIND VALID DETAILS FROM FILE");
					this.isTableValid = false;
				}

				if (this.duplicatesInFile) {
					console.log("DUPLICATE DETAILS FROM FILE");
					this.isTableValid = false;
				}

				if (this.isTableValid) {
					this.validDetailsForUpload = resp.details;

					this.uploadValidDetailsFromFile();
				} else {
					console.log("Cells valid, details invalid");
				}

				console.log(resp);
			} else {
				this.isTableValid = false;
				console.log("Not procceding because erros exist");
			}
		});
		this.hot.render();
	}

	openCampaignFileModal(campaignFileContent) {
		this.fileName = "";
		this.fileAsMatrix = [];
		this.validDetailsForUpload = [];
		// this.validDetailsAfterDealFromFile = false;
		this.isFileValid = false;
		// this.validDetailsFromFile = false;
		this.modalService.open(campaignFileContent, {
			size: "lg",
			centered: true,
			backdrop: "static",
			keyboard: false,
		});
	}

	onFileSelected(event) {
		// IF CSV FILE
		console.log("New file selected");
		this.fileAsMatrix = [];
		this.duplicatesInFile = false;
		this.columnsDontMatch = false;
		this.validDetailsAfterDealFromFile = true;
		if (!!event.target.files[0]) {
			const extension = event.target.files[0].name.split(".").pop();
			this.fileName = event.target.files[0].name;

			// If file exitsts and the extension is allowed
			if (
				extension === "csv" ||
				extension === "xls" ||
				extension === "xlsx"
			) {
				/* wire up file reader */
				const target: DataTransfer = <DataTransfer>event.target;
				if (target.files.length !== 1)
					throw new Error("Cannot use multiple files");
				const reader: FileReader = new FileReader();
				reader.onload = (e: any) => {
					/* read workbook */
					const bstr: string = e.target.result;
					const wb: XLSX.WorkBook = XLSX.read(bstr, {
						type: "binary",
					});

					/* grab first sheet */
					const wsname: string = wb.SheetNames[0];
					const ws: XLSX.WorkSheet = wb.Sheets[wsname];

					/* save data */
					this.fileAsMatrix = XLSX.utils.sheet_to_json(ws, {
						header: 1,
						blankrows: false,
					});
					// console.log(this.fileAsMatrix[0].length)

					if (this.fileAsMatrix[0].length === 12) {
						this.isFileValid = true;
						this.columnsDontMatch = false;

						for (let i = 0; i < this.fileAsMatrix.length; i++) {
							if (
								!!this.fileAsMatrix[i][0] &&
								isNumber(this.fileAsMatrix[i][0])
							) {
								console.log("Valid row ", i);
							} else {
								console.log("Invalid row ", i);
								this.fileAsMatrix.splice(i, 1);
							}
						}

						var resp = this.findValidDetails();

						this.validDetailsForUpload = [];

						if (resp.valid) {
							//DETAILS ARE VALID SO PROCEED
							this.validDetailsForUpload = resp.details;

							setTimeout(() => {
								this.helpers.initPrevTable();
							}, 500);
						} else {
							//DETAILS ARE NOT VALID, STOP HERE
							// console.log('Details are not valid')
						}
					} else {
						this.isFileValid = false;
						this.columnsDontMatch = true;
					}
					// <AOA>
				};
				reader.readAsBinaryString(target.files[0]);
			}
			// INVALID FILE
			else {
				this.isFileValid = false;
				console.log("Invalid file format");
			}
		} else {
			this.isFileValid = false;
			this.fileAsMatrix = [];
			this.fileName = "";
		}

		// console.log('Is file valid: ', this.isFileValid, ' Valid details from file', this.validDetailsFromFile, ' Valid after deal check ', this.validDetailsAfterDealFromFile, ' Duplicates ', this.duplicatesInFile)
	}

	changeStatus(stat) {
		this.status = stat.value;

		this.regroup();
	}

	regroup() {
		this.allDetails = [];
		if (this.status === "all") {
			this.allDetails = this.data2.globalDetails[`${this.detailListId}`];
		} else if (
			this.status === "pagato" ||
			this.status === "inviato" ||
			this.status === "open"
		) {
			for (const detail of this.data2.globalDetails[
				`${this.detailListId}`
			]) {
				//console.log(detail.status)
				if (detail.status === this.status) {
					this.allDetails.push(detail);
				}
			}
		}

		this.allDetailsDataTable = [];
		this.detailsByBrand = []; // TOTAL
		this.detailsByPartner = []; // TOTAL
		this.groupByBrand = []; // GROUPED BY KEY BRAND
		this.groupByPartner = []; //GROUPED BY KEY PARTNER
		this.allDetailsPartner = []; // All DETAILS FOR A SPECIFIC PARTNER
		this.allDetailsBrand = []; // ALL DETAILS FOR A SPECIFIC BRAND
		this.allDetailsPartnerDatatable = []; // ADD DETAILS FOR A SPECIFIC PARTNER datatable
		this.allDetailsBrandDatatable = []; // ALL DETAILS FOR A SPECIFIC BRAND datatable
		this.groupByPartner = this.helpers.groupBy(
			this.allDetails,
			"partnerName"
		);
		this.groupByBrand = this.helpers.groupBy(
			this.allDetails,
			"campaingName"
		);
		this.calcolaSaldoAnticipo();
		this.setByBrand();
		this.setByPartner();
		this.toInvioMassivo = 0;
		this.allDetailsDataTable = JSON.parse(JSON.stringify(this.allDetails));
		this.allDetailsDataTable.forEach((data) => {
			if (data.status === "open") {
				this.toInvioMassivo++;
			}
		});
		this.mydetails = JSON.parse(JSON.stringify(this.details));

		if (this.whichGroup === "ba") {
			if (!!this.groupByBrand[this.brandForBread]) {
				for (const detail of this.groupByBrand[this.brandForBread]) {
					this.allDetailsBrand.push(detail);
				}
			}
		}
		if (this.whichGroup === "pa") {
			if (!!this.groupByPartner[this.partnerForBread]) {
				for (const detail of this.groupByPartner[
					this.partnerForBread
				]) {
					this.allDetailsPartner.push(detail);
				}
			}
		}

		this.allDetailsPartnerDatatable = JSON.parse(
			JSON.stringify(this.allDetailsPartner)
		);
		this.allDetailsBrandDatatable = JSON.parse(
			JSON.stringify(this.allDetailsBrand)
		);

		// CLEAR ALL DATATABLES
		this.helpers.destroyDatatables();

		this.chRef.detectChanges();

		// INITIALIZE TABLE
		this.helpers.reinitTables(this.whichGroup);
	}

	openByBrand(brandName) {
		this.allDetailsBrand = [];
		this.allDetailsBrandDatatable = [];
		this.brandForBread = brandName;
		for (const detail of this.groupByBrand[brandName]) {
			this.allDetailsBrand.push(detail);
		}

		this.allDetailsBrandDatatable = JSON.parse(
			JSON.stringify(this.allDetailsBrand)
		);

		this.check(null, "ba");
	}

	openByPartner(partnerName, dettaglioModal) {
		this.allDetailsPartner = [];
		this.allDetailsPartnerDatatable = [];
		this.partnerForBread = partnerName;
		for (const detail of this.groupByPartner[partnerName]) {
			this.allDetailsPartner.push(detail);
		}

		this.allDetailsPartnerDatatable = JSON.parse(
			JSON.stringify(this.allDetailsPartner)
		);
		if (!dettaglioModal) {
			this.check(null, "pa");
		}
	}

	openPartnerModal(partnerContent) {
		this.totalAnticipo = 0;
		this.modalService.open(partnerContent, {
			size: "lg",
			centered: true,
			backdrop: "static",
			keyboard: false,
		});
		this.partnerSelectedId = 0;
		this.selectedPartnerName = "New Partner";
		$("#partnersSelect").select2({
			tags: false,
			allowClear: false,
			theme: "classic",
			data: this.partnersForSelect,
			multiple: false,
		});

		$("#partnersSelect").on("select2:select", () => {
			// Set partner details
			this.partnerSelectedId = +$("#partnersSelect").val();
			this.setPartnerDetails(this.partnerSelectedId); //TRIGGERS CHANGE IN DATATABLE AND MODALS
			//console.log(this.partnerSelectedId ,' is the id of selected partner ')
		});

		this.setPartnerDetails(0);
	}

	openCampaignModal(campaignContent) {
		this.modalService.open(campaignContent, {
			size: "lg",
			centered: true,
			backdrop: "static",
			keyboard: false,
		});
		this.brandSelectedId = 0;
		this.details = [];
		this.campaignsForBrand = [];
		this.selectedCampaignId = 0;

		this.mydetails = [];

		this.helpers.reinitDetailsTable("#details2");
	}

	getNewCampaigns(brand) {
		this.selectedCampaignId = 0;
		var newBrand = Number(brand.value);
		this.campaignsForBrand = this.getCampaignsForBrand(newBrand);
	}

	getCampaignsForBrand(brandId) {
		var camps = [];
		for (const campaign of this.campaigns) {
			if (campaign.brandId === brandId) {
				camps.push(campaign);
			}
		}

		return camps;
	}

	updateDetailsForCampaign(camp) {
		this.isAnticipo = false;
		var newCamp = Number(camp.value);
		this.selectedCampaignId = newCamp;

		var campName = "";
		for (const camp of this.campaignsForBrand) {
			if (newCamp === camp.campaignId) {
				campName = camp.campaignName;
			}
		}

		this.details = [];
		for (const detail of this.allDetails) {
			if (detail.campaingId === newCamp) {
				this.details.push(detail);
			}
		}

		this.mydetails = JSON.parse(JSON.stringify(this.details));

		if (newCamp !== 24 && newCamp !== 32) {
			this.getPartnersAvailable();
			this.isAnticipo = false;
			this.isRetifica = false;
		} else {
			this.partnersForCampaign = this.partnersForSelect;
			if (newCamp === 24) {
				this.isAnticipo = true;
				this.isRetifica = false;
			} else {
				this.isRetifica = true;
				this.isAnticipo = false;
			}
		}

		this.helpers.reinitDetailsTable("#details2");
	}

	getPartnersAvailable() {
		this.partnersForCampaign = [];
		for (const deal of this.partnersWithCampaignListino) {
			if (deal.campaignId === this.selectedCampaignId) {
				this.partnersForCampaign.push({
					id: deal.partnerId,
					text: deal.partnerName,
				});
			}
		}
	}

	openCampaignDetailModal(campaignDetailContent) {
		this.editDetail = false;
		this.addFromCampaign = true;
		this.anticipoFormVal = "0.0";
		this.retificaFormVal = "0.0";
		this.retificaFormLink = "";
		this.partnerSelectedId = 0;
		//console.log("To create a new detail");
		this.detailForm2 = this.fb.group({
			listino: [{ value: "", disabled: true }, [Validators.required]],
			import_in: ["", [Validators.pattern(this.floatPattern)]],
			import_out: [
				"",
				[Validators.required, Validators.pattern(this.floatPattern)],
			],
			url: ["", [Validators.required, Validators.minLength(10)]],
			volume: [
				"",
				[Validators.pattern(this.floatPattern), Validators.min(0)],
			],
		});

		var date = this.startMonth.split("-");
		this.dateForm = this.fb.group({
			date: { year: +date[1], month: +date[0] },
		});

		this.modalService.open(campaignDetailContent, {
			centered: true,
			backdrop: "static",
			keyboard: false,
		});

		$("#partnerSelect2").select2({
			tags: false,
			allowClear: false,
			theme: "classic",
			data: this.partnersForCampaign,
			multiple: false,
		});

		$("#partnerSelect2").on("select2:select", () => {
			// Set partner details
			this.partnerSelectedId = +$("#partnerSelect2").val();
			for (const deal of this.partnersWithCampaignListino) {
				if (
					this.partnerSelectedId === deal.partnerId &&
					this.selectedCampaignId === deal.campaignId
				) {
					this.detailForm2.get("listino").setValue(deal.listingName);
				}
			}

			//console.log(this.partnerSelectedId);
		});
	}

	async payDetails() {
		for (const detail of this.partnerDetailsForMail) {
			try {
				await this.data2
					.changeStatus(detail.id, "pagato")
					.then((res) => {
						console.log(res);

						for (const mydetail of this.data2.globalDetails[
							`${this.detailListId}`
						]) {
							//console.log(detail.id, detailId)
							if (mydetail.id === detail.id) {
								mydetail.status = "pagato";
							}
						}

						this.regroup();

						this.helpers.reinitDetailsTable("#details");
						this.helpers.reinitDetailsTable("#details2");
					})
					.catch((err) => {
						console.log(err);
					});
			} catch (e) {
				console.error(e);
			}
		}

		// @ts-ignore
		Swal.fire({
			icon: "success",
			title: "Updated...",
			text: "Status changed to pagato!",
		});
	}

	async payDetail(detail, plural, status, emailContent) {
		this.totalAmount = 0;
		if (!plural) {
			if (status !== "inviato") {
				// @ts-ignore
				Swal.fire({
					icon: "info",
					title: "Oops...",
					text: "Send the email first!",
				});
				return;
			}
			this.totalAmount = detail.amountOut;
			this.openEmailModal(
				emailContent,
				detail,
				false,
				"Custom subject",
				false
			);
		} else {
			console.log("PLURAL EMAIL FOR PAGATO ", detail);
			let dets = this.getDetailsByPartnerId(detail);

			for (const detail of dets) {
				if (detail.status === "open") {
					// @ts-ignore
					Swal.fire({
						icon: "info",
						title: "Oops...",
						text: "Send the email first for all the details!",
					});
					return;
				} else if (detail.status === "pagato") {
					// @ts-ignore
					Swal.fire({
						icon: "info",
						title: "Oops...",
						text: "Cannot pay a detail twice, please open and pay manually!",
					});
					return;
				}

				this.totalAmount += parseFloat(detail.amountOut);
			}

			this.openEmailModal(
				emailContent,
				detail,
				true,
				"Custom Subject",
				false
			);
		}
	}

	async markDetailInviato(detailId) {
		await this.data2
			.changeStatus(detailId, "inviato")
			.then((res) => {
				for (const detail of this.data2.globalDetails[
					`${this.detailListId}`
				]) {
					//console.log(detail.id, detailId)
					if (detail.id === detailId) {
						detail.status = "inviato";
					}
				}

				this.regroup();

				this.helpers.reinitDetailsTable("#details");
				this.helpers.reinitDetailsTable("#details2");
			})
			.catch((err) => {
				console.log(err);
			});
	}

	getNewListing(sel) {
		var campId = +sel.value;

		if (campId === -2) {
			this.isAnticipo = true;
			this.isRetifica = false;
			return;
		}
		if (campId === -3) {
			this.isRetifica = true;
			this.isAnticipo = false;
			return;
		}
		this.isAnticipo = false;
		this.isRetifica = false;

		for (const deal of this.deals) {
			// console.log(deal)
			if (deal.campaingId === campId) {
				this.detailForm.get("listino").setValue(deal.listingName);
				// console.log(deal.listingName)
			}
		}
	}

	async openPartnerDetailModal(partnerDetailContent, action, detail) {
		this.isAnticipo = false;
		this.addFromCampaign = false;
		this.idPrebill = detail.idPrebill;
		// console.log("action ", action, " id ", detailId);
		if (action === "create" && !detail) {
			this.editDetail = false;
			this.anticipoFormVal = "0.0";
			this.retificaFormVal = "0.0";
			this.retificaFormLink = "";
			// console.log("To create a new detail");
			this.detailForm = this.fb.group({
				brand_camp: ["", [Validators.required]],
				listino: [{ value: "", disabled: true }, [Validators.required]],
				import_in: ["", [Validators.pattern(this.floatPattern)]],
				import_out: [
					"",
					[
						Validators.required,
						Validators.pattern(this.floatPattern),
					],
				],
				url: ["", [Validators.required, Validators.minLength(10)]],
				volume: [
					"",
					[Validators.pattern(this.floatPattern), Validators.min(0)],
				],
			});
			this.detailForm.controls["brand_camp"].enable();
			var date = this.startMonth.split("-");
			this.dateForm = this.fb.group({
				date: { year: +date[1], month: +date[0] },
			});
		} else if (action === "edit" && detail.id > 0) {
			this.editDetail = true;
			let preBillTemp = {
				partnerId: detail.partnerId,
				campaingId: detail.campaingId,
				googleSheet: detail.googleSheetLink,
				idDetail: detail.id,
				idPreBill: detail.partnerId + detail.campaingId + detail.id,
			};

			this.detailIdToBeUpdated = detail.id;
			console.log("To edit this detail");
			this.campaignNameEdit = detail.campaingName;
			this.detailForm = this.fb.group({
				brand_camp: ["-1", [Validators.required]],
				listino: [
					{ value: `${detail.listingName}`, disabled: true },
					[Validators.required],
				],
				import_in: [
					`${detail.amountIn.replace(",", ".")}`,
					[Validators.pattern(this.floatPattern)],
				],
				import_out: [
					`${detail.amountOut.replace(",", ".")}`,
					[
						Validators.required,
						Validators.pattern(this.floatPattern),
					],
				],
				url: [
					`${detail.googleSheetLink}`,
					[Validators.required, Validators.minLength(10)],
				],
				volume: [
					detail.volume.replace(",", "."),
					[Validators.pattern(this.floatPattern), Validators.min(0)],
				],
			});
			if (this.idPrebill) {
				this.detailForm.disable();
			} else {
				this.detailForm.controls["brand_camp"].disable();
			}

			if (detail.dealId === 1254) {
				this.isAnticipo = true;
				this.isRetifica = false;
				this.anticipoFormVal = detail.anticipo.replace(",", ".");
			}

			if (detail.dealId === 4021) {
				this.isRetifica = true;
				this.isAnticipo = false;
				this.retificaFormVal = detail.amountOut.replace(",", ".");
				this.retificaFormLink = detail.googleSheetLink;
			}

			var dateForEdit = detail.meseDiCompetenza.split("-");
			this.dateForm = this.fb.group({
				date: { year: +dateForEdit[1], month: +dateForEdit[0] },
			});
		}

		this.modalService.open(partnerDetailContent, {
			centered: true,
			backdrop: "static",
			keyboard: false,
		});
	}

	// PARTNER DETAIL AND DEALS
	async setPartnerDetails(id) {
		if (id > 0) {
			this.dataLoading = true;
			this.totalAnticipo = 0;
			this.deals = [];
			this.details = [];
			await this.data2
				.getPartnersCampaignsAndListing(
					this.partnerSelectedId,
					this.detailListId
				)
				.then((res) => {
					this.userTemp = res.data;
					this.selectedPartnerName =
						this.userTemp.allPartnerPartners.nodes[0].nomeCognomeLegale.toUpperCase();
					this.dealTemp =
						this.userTemp.allPartnerPartners.nodes[0].partnerContractByPartnerId.partnerDealsByContractId.nodes;

					// console.log(this.dealTemp)
					this.deals.push({
						id: 1254,
						campaingId: -2,
						campaingName: "Anticipo",
						listingId: -2,
						listingName: "Diretto",
					});

					this.deals.push({
						id: 4021,
						campaingId: -3,
						campaingName: "Retifica",
						listingId: -3,
						listingName: "Diretto",
					});

					for (const deal of this.dealTemp) {
						this.deals.push({
							id: deal.id,
							idPrebill: deal.idPrebill,
							campaingId: deal.partnerCampaignByCampaignId.id,
							campaingName: deal.partnerCampaignByCampaignId.name,
							listingId: deal.partnerListingByListingId.id,
							listingName: deal.partnerListingByListingId.name,
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});

			for (const detail of this.allDetails) {
				if (+detail.partnerId === this.partnerSelectedId) {
					this.details.push(detail);
				}
			}

			this.dataLoading = false;

			this.mydetails = JSON.parse(JSON.stringify(this.details));
			this.helpers.reinitDetailsTable("#details");

			if (!!this.data2.anticipos[`${this.partnerSelectedId}`]) {
				this.totalAnticipo =
					this.data2.anticipos[`${this.partnerSelectedId}`];
			} else {
				await this.data2
					.getAnticipoForPartner(this.partnerSelectedId)
					.then((res) => {
						this.anticipTemp = res.data;
						this.anticipTemp = !!this.anticipTemp
							.allPartnerTotaleAnticipos.nodes[0]
							? this.anticipTemp.allPartnerTotaleAnticipos
									.nodes[0]
							: "ska";
						this.totalAnticipo =
							this.anticipTemp === "ska"
								? 0
								: +parseFloat(
										this.anticipTemp.anticipo
								  ).toFixed(2);
						this.data2.anticipos[`${this.partnerSelectedId}`] =
							this.totalAnticipo;
						//console.log(this.totalAnticipo)
					})
					.catch((err) => {
						console.log(err);
					});
			}
		} else {
			this.selectedPartnerName = "New Partner";
			this.mydetails = [];

			this.helpers.reinitDetailsTable("#details");
		}
	}

	backToDetails() {
		this.router.navigateByUrl(`details`);
	}

	openErrors(errorsContent) {
		this.modalService.open(errorsContent, {
			centered: true,
			backdrop: "static",
			keyboard: false,
		});
	}

	findValidDetails() {
		this.validDetailsFromFile = true;
		this.validDetailsAfterDealFromFile = true;
		this.duplicatesInFile = false;
		let validDetails = [];
		this.fileErrors = [];

		if (this.fileAsMatrix.length > 0) {
			let i = 0;
			for (const row of this.fileAsMatrix) {
				if (i >= 0) {
					const partnerId = row[0];
					const dealId = row[2];
					const amountOut = row[4];
					const amountIn = row[9];
					const googleSheetLink = row[10];
					const volume = row[11];

					const detail = this.helpers.generateDetailForRow(
						partnerId,
						dealId,
						amountOut,
						amountIn,
						googleSheetLink,
						volume
					);

					// console.log(detail)

					if (!detail.valid) {
						this.validDetailsFromFile = false;
						this.fileErrors.push({
							row: i + 1,
							msg: "Invalid row data for detail",
						});
						// console.log('Invalid detail found')
					} else {
						console.log(
							"Detail is valid before check for Campaign"
						);
						var valid = false;
						var exists = false;
						for (const deal of this.activeDeals) {
							var partnerDealId =
								deal.partnerContractByContractId.partnerId;
							if (
								deal.id === detail.dealId &&
								this.selectedCampaignId === deal.campaignId &&
								partnerId ===
									partnerDealId /* && deal.startMonth === localStorage.getItem('startMonth') */
							) {
								valid = true;
								// console.log('Deal found so detail is valid ')
							}
						}

						for (const mydetail of this.allDetailsDataTable) {
							//console.log(mydetail, detail)
							if (
								+mydetail.partnerId === +partnerId &&
								+mydetail.dealId === +dealId &&
								mydetail.date ===
									localStorage.getItem("startMonth")
							) {
								exists = true;
								this.duplicatesInFile = true;
								this.fileErrors.push({
									row: i + 1,
									msg: "Duplicate detail for this month",
								});
							}
						}

						if (valid) {
							// console.log('Detail is valid after deal check');
							validDetails.push(detail);
						} else {
							this.validDetailsAfterDealFromFile = false;
							// console.log('Not valid after deal check')
							this.fileErrors.push({
								row: i + 1,
								msg: "Invalid deal id",
							});
						}
					}
				}
				i++;
			}

			if (this.validDetailsFromFile) {
				return { valid: true, details: validDetails };
			}
		}

		this.validDetailsAfterDealFromFile = false;
		this.validDetailsFromFile = false;
		return { valid: false, details: [] };
	}

	async uploadValidDetailsFromFile() {
		// UPLOAD ALL DETAILS
		for (const detail of this.validDetailsForUpload) {
			await this.data2
				.createDetail(
					detail.partnerId,
					detail.googleSheetLink,
					detail.amountIn,
					detail.amountOut,
					detail.volume,
					detail.dealId,
					this.detailListId,
					localStorage.getItem("startMonth"),
					detail.anticipo
				)
				.then((res) => {})
				.catch((err) => {
					console.log(err);
				});
		}

		window.location.reload();
		// @ts-ignore
		Swal.fire({
			icon: "success",
			title: "Done",
			text: "Details Imported!",
			showConfirmButton: false,
		});
	}

	async upsertDetail(modal, form1) {
		this.working = true;

		if (form1) {
			if (
				!this.detailForm.valid &&
				!this.isAnticipo &&
				!this.isRetifica
			) {
				console.log("Form1 is invalid");
				this.working = false;
				return;
			}
		} else {
			if (
				!this.detailForm2.valid &&
				!this.isAnticipo &&
				!this.isRetifica
			) {
				console.log("Form2 is invalid");
				this.working = false;
				return;
			}
		}

		if (this.isAnticipo) {
			if (!this.anticipoFloatPattern.test(this.anticipoFormVal)) {
				console.log("Anticipo not fine");
				this.working = false;
				return;
			}
		}

		if (this.isRetifica) {
			if (
				!this.anticipoFloatPattern.test(this.retificaFormVal) ||
				this.retificaFormLink.length < 5
			) {
				console.log("Retifica not fine");
				this.working = false;
				return;
			}
		}

		let mystartMonth = `${this.dateForm.value.date.year}-${
			this.dateForm.value.date.month < 10
				? "0" + this.dateForm.value.date.month
				: this.dateForm.value.date.month
		}-01`;
		var url = form1
			? this.detailForm.get("url").value
			: this.detailForm2.get("url").value;
		var amountIn = form1
			? this.detailForm.get("import_in").value
			: this.detailForm2.get("import_in").value;
		var amountOut = form1
			? this.detailForm.get("import_out").value
			: this.detailForm2.get("import_out").value;
		var volume = form1
			? this.detailForm.get("volume").value
			: this.detailForm2.get("volume").value;
		var anticipo = this.anticipoFormVal;
		var campId = form1
			? this.detailForm.get("brand_camp").value
			: this.selectedCampaignId;
		var date = mystartMonth.split("-");

		if (amountIn === "") {
			amountIn = "0.0";
		}

		if (volume === "") {
			volume = "0.0";
		}

		if (this.isAnticipo) {
			url = "";
			amountOut = "0.0";
			amountIn = "0.0";
			volume = "0.0";
		} else if (this.isRetifica) {
			anticipo = "0.0";
			amountOut = this.retificaFormVal;
			amountIn = "0.0";
			volume = "0.0";
			url = this.retificaFormLink;
		} else {
			anticipo = "0.0";
		}

		if (!this.editDetail) {
			//// CHECK IF EXISTS WHILE ADDING////
			for (const detail of this.allDetailsDataTable) {
				// console.log(
				//   +detail.partnerId,
				//   this.partnerSelectedId,
				//   detail.campaingId,
				//   +campId,
				//   detail.dealId,
				//   this.isAnticipo,
				//   detail.meseDiCompetenza,
				//   `${date[1]}-${date[0]}`
				// );
				if (
					+detail.partnerId === this.partnerSelectedId &&
					((detail.campaingId === +campId &&
						this.editDetail === false) ||
						(detail.dealId === 1254 && this.isAnticipo) ||
						(detail.dealId === 4021 && this.isRetifica)) &&
					detail.meseDiCompetenza === `${date[1]}-${date[0]}`
				) {
					// @ts-ignore
					Swal.fire({
						icon: "info",
						title: "Oops...",
						text: "Detail already exists",
					});
					this.working = false;
					return;
				}
			}

			console.log("Creating detail");

			var dealId = -1;

			if (form1) {
				for (const deal of this.deals) {
					if (deal.campaingId === +campId) {
						dealId = deal.id;
					}
				}
			} else {
				for (const deal of this.partnersWithCampaignListino) {
					if (
						this.partnerSelectedId === deal.partnerId &&
						this.selectedCampaignId === deal.campaignId
					) {
						dealId = deal.id;
					}
				}
			}

			if (this.addFromCampaign) {
				for (const deal of this.partnersWithCampaignListino) {
					if (
						this.partnerSelectedId === deal.partnerId &&
						this.selectedCampaignId === deal.campaignId
					) {
						dealId = deal.id;
					}
				}
			}

			if (this.isRetifica) {
				dealId = 4021;
			} else if (this.isAnticipo) {
				dealId = 1254;
			}

			await this.data2
				.createDetail(
					this.partnerSelectedId,
					url,
					amountIn,
					amountOut,
					volume,
					dealId,
					this.detailListId,
					mystartMonth,
					anticipo
				)
				.then((res) => {
					this.resTemp = res;
					this.resTemp =
						this.resTemp.data.createPartnerDetail.partnerDetail;
					var amIn = parseFloat(this.resTemp.amountIn)
						.toFixed(2)
						.replace(".", ",");
					var amOut = parseFloat(this.resTemp.amountOut)
						.toFixed(2)
						.replace(".", ",");
					var ant = parseFloat(this.resTemp.anticipo)
						.toFixed(2)
						.replace(".", ",");
					var date = this.resTemp.meseDiCompetenza.split("-");

					var detailCreated = {
						id: this.resTemp.id,
						date: this.resTemp.meseDiCompetenza,
						createdAt: this.resTemp.createdAt,
						updatedAt: this.resTemp.updatedAt,
						deleted: this.resTemp.deleted,
						googleSheetLink: this.resTemp.googleSheetLink,
						status: this.resTemp.status,
						dealId: this.resTemp.dealId,
						amountIn: amIn,
						amountOut: amOut,
						volume: this.resTemp.volume
							.toFixed(2)
							.replace(".", ","),
						anticipo: ant,
						meseDiCompetenza: `${date[1]}-${date[0]}`,
						partnerId: this.resTemp.partnerId,
						campaingName:
							this.resTemp.partnerDealByDealId
								.partnerCampaignByCampaignId.name,
						campaingId:
							this.resTemp.partnerDealByDealId
								.partnerCampaignByCampaignId.id,
						listingName:
							this.resTemp.partnerDealByDealId
								.partnerListingByListingId.name,
						listingId:
							this.resTemp.partnerDealByDealId
								.partnerListingByListingId.id,
						partnerName:
							this.resTemp.partnerPartnerByPartnerId.nomeCognomeLegale.toUpperCase(),
						ragioneSociale:
							this.resTemp.partnerPartnerByPartnerId
								.ragioneSociale || "",
						emGen: !!this.resTemp.partnerPartnerByPartnerId
							.emailGenerale
							? this.resTemp.partnerPartnerByPartnerId
									.emailGenerale
							: "ska",
						emAmm: !!this.resTemp.partnerPartnerByPartnerId.emailAmm
							? this.resTemp.partnerPartnerByPartnerId.emailAmm
							: "ska",
						emBo: !!this.resTemp.partnerPartnerByPartnerId.emailBo
							? this.resTemp.partnerPartnerByPartnerId.emailBo
							: "ska",
						accountName: !!this.resTemp.partnerPartnerByPartnerId
							.agentUserByAccountId
							? this.resTemp.partnerPartnerByPartnerId
									.agentUserByAccountId.username
							: "",
						accountEmail: !!this.resTemp.partnerPartnerByPartnerId
							.agentUserByAccountId
							? this.resTemp.partnerPartnerByPartnerId
									.agentUserByAccountId.email
							: "",
					};

					this.details.push(detailCreated);
					this.data2.anticipos[`${this.partnerSelectedId}`] +=
						parseFloat(detailCreated.anticipo);
					this.totalAnticipo =
						this.data2.anticipos[`${this.partnerSelectedId}`];
					this.data2.globalDetails[`${this.detailListId}`].push(
						detailCreated
					);

					this.regroup();

					if (form1) {
						this.helpers.reinitDetailsTable("#details");
					} else {
						this.helpers.reinitDetailsTable("#details2");
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			// UPDATE DETAIL
			var det = this.getDetail(this.detailIdToBeUpdated);

			//// CHECK IF EXISTS ////
			for (const detail of this.allDetailsDataTable) {
				// console.log(
				//   +detail.partnerId,
				//   +det.partnerId,
				//   detail.campaingId,
				//   +campId,
				//   detail.dealId,
				//   this.isAnticipo,
				//   detail.meseDiCompetenza,
				//   `${date[1]}-${date[0]}`
				// );
				if (
					detail.id !== this.detailIdToBeUpdated &&
					+detail.partnerId === +det.partnerId &&
					((detail.campaingId === +det.campaingId &&
						this.editDetail === true) ||
						(detail.dealId === 1254 && this.isAnticipo)) &&
					detail.meseDiCompetenza === `${date[1]}-${date[0]}`
				) {
					// @ts-ignore
					Swal.fire({
						icon: "info",
						title: "Oops...",
						text: "Detail already exists",
					});
					this.working = false;
					return;
				}
			}

			await this.data2
				.updateDetail(
					this.detailIdToBeUpdated,
					url,
					amountIn,
					amountOut,
					volume,
					anticipo,
					mystartMonth
				)
				.then((res) => {
					this.resTemp = res;
					this.resTemp =
						this.resTemp.data.updatePartnerDetailById.partnerDetail;
					var amIn = parseFloat(this.resTemp.amountIn)
						.toFixed(2)
						.replace(".", ",");
					var amOut = parseFloat(this.resTemp.amountOut)
						.toFixed(2)
						.replace(".", ",");
					var ant = parseFloat(this.resTemp.anticipo)
						.toFixed(2)
						.replace(".", ",");
					var date = this.resTemp.meseDiCompetenza.split("-");

					var detailEdited = {
						id: this.resTemp.id,
						createdAt: this.resTemp.createdAt,
						updatedAt: this.resTemp.updatedAt,
						deleted: this.resTemp.deleted,
						date: this.resTemp.meseDiCompetenza,
						googleSheetLink: this.resTemp.googleSheetLink,
						status: this.resTemp.status,
						dealId: this.resTemp.dealId,
						amountIn: amIn,
						amountOut: amOut,
						volume: this.resTemp.volume
							.toFixed(2)
							.replace(".", ","),
						anticipo: ant,
						meseDiCompetenza: `${date[1]}-${date[0]}`,
						partnerId: this.resTemp.partnerId,
						campaingName:
							this.resTemp.partnerDealByDealId
								.partnerCampaignByCampaignId.name,
						campaingId:
							this.resTemp.partnerDealByDealId
								.partnerCampaignByCampaignId.id,
						listingName:
							this.resTemp.partnerDealByDealId
								.partnerListingByListingId.name,
						listingId:
							this.resTemp.partnerDealByDealId
								.partnerListingByListingId.id,
						partnerName:
							this.resTemp.partnerPartnerByPartnerId.nomeCognomeLegale.toUpperCase(),
						ragioneSociale:
							this.resTemp.partnerPartnerByPartnerId
								.ragioneSociale || "",
						emGen: !!this.resTemp.partnerPartnerByPartnerId
							.emailGenerale
							? this.resTemp.partnerPartnerByPartnerId
									.emailGenerale
							: "ska",
						emAmm: !!this.resTemp.partnerPartnerByPartnerId.emailAmm
							? this.resTemp.partnerPartnerByPartnerId.emailAmm
							: "ska",
						emBo: !!this.resTemp.partnerPartnerByPartnerId.emailBo
							? this.resTemp.partnerPartnerByPartnerId.emailBo
							: "ska",
						accountName: !!this.resTemp.partnerPartnerByPartnerId
							.agentUserByAccountId
							? this.resTemp.partnerPartnerByPartnerId
									.agentUserByAccountId.username
							: "",
						accountEmail: !!this.resTemp.partnerPartnerByPartnerId
							.agentUserByAccountId
							? this.resTemp.partnerPartnerByPartnerId
									.agentUserByAccountId.email
							: "",
					};

					for (
						let i = 0;
						i <
						this.data2.globalDetails[`${this.detailListId}`].length;
						i++
					) {
						if (
							this.data2.globalDetails[`${this.detailListId}`][i]
								.id === this.detailIdToBeUpdated
						) {
							this.data2.globalDetails[`${this.detailListId}`][
								i
							] = detailEdited;
						}
					}

					if (this.isAnticipo) {
						for (const detail of this.allDetailsDataTable) {
							if (this.detailIdToBeUpdated === detail.id) {
								this.data2.anticipos[`${detail.partnerId}`] +=
									parseFloat(anticipo) -
									parseFloat(detail.anticipo);
								this.totalAnticipo =
									this.data2.anticipos[
										`${this.partnerSelectedId}`
									];
							}
						}
					}

					if (this.modalService.hasOpenModals()) {
						for (let i = 0; i < this.details.length; i++) {
							this.details[i].id, this.detailIdToBeUpdated;

							if (
								this.details[i].id === this.detailIdToBeUpdated
							) {
								// this.details.splice(i,1)
								this.details[i] = null;
								this.details[i] = detailEdited;
							}
						}
						// console.log(detailEdited)
						// this.details.push(detailEdited)
					}

					this.regroup();
					this.helpers.reinitDetailsTable("#details");
					this.helpers.reinitDetailsTable("#details2");
				})
				.catch((err) => {
					console.log(err);
				});
		}

		this.working = false;
		modal.dismiss("Kot m");
	}

	async deleteDetail(detailId, inModal) {
		// @ts-ignore
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.value) {
				await this.data2
					.deleteDetail(detailId)
					.then((res) => {
						this.resTemp = res.data;
						var prevAnticipo = parseFloat(
							this.resTemp.deletePartnerDetailById.partnerDetail
								.anticipo
						);
						var partnerId =
							this.resTemp.deletePartnerDetailById.partnerDetail
								.partnerId;
						this.resTemp =
							+this.resTemp.deletePartnerDetailById.partnerDetail
								.id;

						if (inModal) {
							for (let i = 0; i < this.details.length; i++) {
								if (this.details[i].id === this.resTemp) {
									this.details.splice(i, 1);
								}
							}
						}

						for (let i = 0; i < this.allDetails.length; i++) {
							if (this.allDetails[i].id === detailId) {
								//console.log("Removing from all details");
								this.allDetails.splice(i, 1);
							}
						}

						this.data2.globalDetails[`${this.detailListId}`] =
							this.allDetails;
						console.log(
							this.data2.anticipos[`${partnerId}`],
							partnerId,
							prevAnticipo
						);
						this.data2.anticipos[`${partnerId}`] -= prevAnticipo;
						this.totalAnticipo =
							this.data2.anticipos[`${partnerId}`];

						this.regroup();
						this.helpers.reinitDetailsTable("#details2");
						this.helpers.reinitDetailsTable("#details");

						// @ts-ignore
						Swal.fire(
							"Deleted!",
							"Detail has been deleted.",
							"success"
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		});
	}

	// Change between All, Campaign and Partner
	check(event, pOrB) {
		if (!!event) {
			event.preventDefault();
		}
		if (this.whichGroup === pOrB) {
			return;
		}
		this.helpers.destroyDatatables();

		this.whichGroup = pOrB;
		this.helpers.removeClasses();
		this.filtro = this.whichGroup;
		if (
			this.whichGroup === "p" ||
			this.whichGroup === "b" ||
			this.whichGroup === "a"
		) {
			this.addClasses(`#${this.whichGroup}`);
		}

		this.chRef.detectChanges();
		this.helpers.reinitTables(this.whichGroup);
	}

	addClasses(which) {
		$(which).addClass("active");
		$(which).addClass("focus");
	}

	setByBrand() {
		this.detailsByBrand = this.helpers.setByBrand(this.groupByBrand);
	}

	setByPartner() {
		this.detailsByPartner = this.helpers.setByPartner(this.groupByPartner);
	}

	async openEmailModal(emailContent, detail, plural, subj, notes) {
		this.showNotes = notes;
		this.partnerNameForMail = "";
		this.duplicateBill = false;
		this.nomeMainContractor = null;
		this.partnerDetailsForMail = [];
		this.partnerTotalAnticipoForMail = 0;
		this.idPrebill = detail.idPrebill;
		this.plural = plural;
		let mainContractor: any = await this.detailsService.getMainContractor(
			+detail.partnerId
		);
		this.idMainContractor = mainContractor.idMainContractor;
		let nomeSubject = mainContractor.nomeMainContractor;
		this.nomeMainContractor = nomeSubject;
		let nomeSubjectPartner = detail.partnerName
			? detail.partnerName
			: detail.ragioneSociale;
		nomeSubject += ": " + nomeSubjectPartner;
		if (!this.nomeMainContractor) {
			nomeSubject = detail.partnerName
				? detail.partnerName
				: detail.ragioneSociale;
		}
		this.setSubjectEmail(nomeSubject);
		this.generateIdPrebill = false;
		if (!this.idPrebill) {
			this.generateIdPrebill = true;
			this.idPrebill = (await this.detailsService.getLastIdPrebill()) + 1;
		}
		this.mandatarie = await this.settingService.getAllMandatarieFromDB();
		this.emailsPartner =
			await this.accountPartnerService.getEmailPartnerFromDB(
				detail.partnerId
			);
		this.sendEmail = false;
		var emails = [];
		let ccMails = this.helpers.getCCmails();

		this.emailsPartner.push(this.createObjForSelect(detail.emAmm));
		this.mandatariaSelected = detail.mandataria;
		this.mandatariaToSend = this.mandatarie.filter(
			(mandataria) => mandataria.name === detail.mandataria
		)[0];

		if (
			this.mandatariaSelected === undefined ||
			this.mandatariaSelected === null
		) {
			this.mandatariaSelected = this.mandatarie[0];
		}
		emails = this.emailsPartner;

		if (!this.plural) {
			this.preBill = [
				this.setIdPreBill(
					detail,
					detail.idPrebill ? detail.idPrebill : this.idPrebill
				),
			];
			// this.accountEmail = detail.accountEmail;
			// this.accountName = detail.accountName;
			this.detailIdForMail = detail.id;
			// emails = this.helpers.getAllEmails(
			// 	detail.emGen,
			// 	detail.emAmm,
			// 	detail.emBo
			// );
			this.partnerDetailsForMail.push(detail);
			this.partnerSelectedId = detail.partnerId;
			this.partnerNameForMail = detail.partnerName;
		} else {
			this.partnerDetailsForMail = this.getDetailsByPartnerId(
				detail.partnerId
			);

			this.partnerNameForMail = this.partnerDetailsForMail[0].partnerName;
			this.accountEmail = this.partnerDetailsForMail[0].accountEmail;
			this.accountName = this.partnerDetailsForMail[0].accountName;
			this.partnerSelectedId = this.partnerDetailsForMail[0].partnerId;

			if (this.partnerDetailsForMail[0].emAmm !== "ska") {
				emails.push({
					id: this.partnerDetailsForMail[0].emAmm,
					text: this.partnerDetailsForMail[0].emAmm,
					selected: true,
				});
			}
		}
		if (this.idMainContractor) {
			emails = await this.accountPartnerService.getEmailPartnerFromDB(
				this.idMainContractor
			);
		}
		// ccMails.push({
		// 	id: this.accountEmail,
		// 	text: this.accountEmail,
		// 	selected: true,
		// });
		this.emailForm.get("notes").setValue("");
		this.modalService.open(emailContent, {
			centered: true,
			size: "lg",
			keyboard: false,
			backdrop: "static",
		});
		// Initialize sendTo select2
		$(".sendTo").select2({
			tags: true, // Allow custom values
			allowClear: true,
			theme: "classic",
			multiple: true,
			data: emails,
			createTag: function (params) {
				const re =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var term = $.trim(params.term);

				if (!re.test(String(term).toLowerCase())) {
					return null;
				}

				return {
					id: term,
					text: term,
					newTag: true, // add additional parameters
				};
			},
		});

		$(".cc").select2({
			tags: true, // Allow custom values
			allowClear: true,
			theme: "classic",
			multiple: true,
			data: ccMails,
			createTag: function (params) {
				const re =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var term = $.trim(params.term);

				if (!re.test(String(term).toLowerCase())) {
					return null;
				}

				return {
					id: term,
					text: term,
					newTag: true, // add additional parameters
				};
			},
		});
	}

	getDetailsByPartnerId(partnerId) {
		let res = [];

		for (const detail of this.allDetails) {
			if (+detail.partnerId === partnerId) {
				res.push(detail);
			}
		}

		return res;
	}

	// Send Email
	async sendEmailTo() {
		this.createEmailToSend();
		console.log(this.emailToSend);
		this.note = this.emailForm.get("notes").value;
		this.subject = this.emailForm.get("emailSubject").value;
		var totAnticipo = 0;
		this.noEmail = false;
		await this.data2
			.getAnticipoForPartner(this.partnerSelectedId)
			.then((res) => {
				this.anticipTemp = res.data;
				this.anticipTemp = !!this.anticipTemp.allPartnerTotaleAnticipos
					.nodes[0]
					? this.anticipTemp.allPartnerTotaleAnticipos.nodes[0]
					: "ska";
				totAnticipo =
					this.anticipTemp === "ska"
						? 0
						: +parseFloat(this.anticipTemp.anticipo).toFixed(2);
			})
			.catch((err) => {
				console.log(err);
			});

		this.working = true;
		this.sendEmail = true;

		this.sendTo = $("#sendTo").val();
		this.ccTo = $("#cc").val();

		if (
			(this.sendTo.length === 0 && this.ccTo.length === 0) ||
			this.emailForm.invalid
		) {
			this.noEmail = this.sendTo.length === 0 && this.ccTo.length === 0;
			return;
		}

		this.modalService.dismissAll("Se ashtu mdo trapi mu");

		// START SENDING EMAIL //
		this.emailToSend.forEach((email) => {
			this.sendEmailHttp(email, totAnticipo);
		});
		this.showTable = true;
	}

	async markAsSend() {
		if (this.sent && this.showNotes) {
			for (const detail of this.partnerDetailsForMail) {
				if (detail.status === "open") {
					await this.markDetailInviato(detail.id);
				}
			}
		} else if (this.sent && !this.showNotes) {
			this.payDetails();
		} else {
			console.log("Email might not have been sent");
		}
	}

	getDetail(detailId) {
		for (const detail of this.allDetails) {
			if (detail.id === detailId) {
				return detail;
			}
		}
	}

	removeDetailForEmail(i) {
		this.totalAmount =
			this.totalAmount -
			parseFloat(this.partnerDetailsForMail[i].amountOut);
		this.partnerDetailsForMail.splice(i, 1);
		this.chRef.detectChanges();
	}

	get e() {
		return this.emailForm.controls;
	}

	printStatus(status) {
		return this.helpers.printStatus(status);
	}

	myFloat(nr) {
		return parseFloat(nr.replace(",", "."));
	}

	goToLink(link) {
		window.open(link, "_blank");
	}

	ngOnDestroy() {
		this.chRef.detach(); // do this
	}

	setMandataria(newMandataria) {
		this.mandatariaSelected = newMandataria;
		this.mandatariaToSend = this.mandatarie.filter(
			(mandataria) => mandataria.name === newMandataria
		)[0];
		console.log(this.mandatariaSelected, this.mandatariaToSend);
	}

	createObjForSelect(option) {
		return {
			id: option,
			text: option,
			selected: true,
		};
	}

	/**
	 * Chiamata che effettua la creazione e l'associazione dell'di prebill.
	 * Aggiorna i record locali assegnando l' id prebill appena creato
	 */
	async creaIdPreBill() {
		console.log("all: ", this.allDetailsDataTable);
		await this.preBill.forEach((bill) => {
			this.detailsService.createIdPreBill(bill);
			this.detailsService.updateIdPrebillDetails(
				bill.idPreBill,
				bill.idDetail
			);
			// console.log("by partner: ", this.detailsByPartner);
			// console.log("by brand: ", this.detailsByBrand);

			let a = this.allDetailsDataTable.find(
				(a) =>
					a.campaingId === bill.campaignId &&
					+a.partnerId === +bill.partnerId
			);
			a.idPrebill = bill.idPreBill;
			a = this.detailsByPartner.find((a) => {
				return +a.partnerId === +bill.partnerId;
			});
			a.idPrebill = bill.idPreBill;

			a = this.detailsByBrand.find(
				(a) => a.campaignId === bill.campaignId
			);
			a.idPrebill = bill.idPreBill;
			this.showTable = true;
		});
		this.allDetailsPartnerDatatable = JSON.parse(
			JSON.stringify(this.allDetailsPartner)
		);
		this.allDetailsBrandDatatable = JSON.parse(
			JSON.stringify(this.allDetailsBrand)
		);
	}

	/**
	 * Crea l'oggetto per la creazione dell'id prebill
	 * @param detail dettaglio dell'email che si sta vedendo
	 * @param idPrebill l'id prebill da associare a quella email
	 * @returns
	 */
	setIdPreBill(detail, idPrebill) {
		return {
			partnerId: detail.partnerId,
			campaignId: detail.campaingId,
			googleSheet: detail.googleSheetLink,
			idDetail: detail.id,
			idPreBill: idPrebill,
		};
	}

	/**
	 * Filtro della tabella per id prebill
	 * @param event
	 */
	filterForIdPrebill(event) {
		let idPrebill = event.target.value;
		if (idPrebill > 0) {
			this.allDetailsDataTable.forEach((element) => {
				if (element.idPrebill != idPrebill) {
					element.show = false;
				} else {
					element.show = true;
				}
			});
		} else {
			this.allDetailsDataTable.forEach((element) => {
				element.show = true;
			});
		}
	}

	/**
	 * Creazione automatica dell'oggetto dell'email
	 * @param nome Il nome del partner o del main contractor
	 */
	setSubjectEmail(nome) {
		let subject = "Dettaglio - " + this.startMonth + " - " + nome;
		let form = this.emailForm.get("emailSubject");
		form.setValue(subject);
		form.disable();
		return subject;
	}

	/**
	 * Crea un oggetto per l'invio dell'email con diversi template
	 */
	createEmailToSend() {
		let emailToSend: any[] = [];
		let mandatarieEmail = [];
		this.showTable = false;

		this.partnerDetailsForMail.forEach((d) => {
			if (!d.idPrebill) {
				this.preBill.push(this.setIdPreBill(d, this.idPrebill));
				this.idPrebill += 1;
			}
		});

		this.partnerDetailsForMail.forEach((detail) =>
			mandatarieEmail.push(detail.mandataria)
		);
		// Delete duplicate
		mandatarieEmail = mandatarieEmail.filter(
			(value, index) => mandatarieEmail.indexOf(value) === index
		);

		mandatarieEmail.forEach((manDetail) => {
			let mandataria = this.mandatarie.filter(
				(mandataria) =>
					mandataria.name.toLowerCase() === manDetail.toLowerCase()
			)[0];
			emailToSend.push({
				mandataria: this.mandatariaSelected
					? this.mandatariaToSend
					: mandataria,
				dettaglio: [],
				idPrebill: this.idPrebill,
			});
			this.idPrebill += 1;
		});
		emailToSend.forEach((dettaglioEmail) => {
			let dettaglio = this.partnerDetailsForMail.filter(
				(partnerDetail) =>
					partnerDetail.mandataria === dettaglioEmail.mandataria.name
			);
			dettaglioEmail.dettaglio = dettaglio;
		});
		this.emailToSend = emailToSend;
	}

	/**
	 * Chiamata che effettua l'invio dell'email
	 * @param email l'oggetto che contiene il dettaglio dell'email e il template da applicare
	 * @param totAnticipo l'anticipo
	 */
	async sendEmailHttp(email, totAnticipo) {
		await this.data2
			.sendEmail(
				this.sendTo,
				this.ccTo,
				this.subject,
				this.partnerNameForMail,
				email.dettaglio,
				totAnticipo,
				this.showNotes ? "fatturazione" : "bonifico",
				this.accountName,
				this.accountEmail,
				this.note,
				email.mandataria.templateMail,
				email.idPrebill
			) //TO CHANGE DETAIL ID
			.then((res) => {
				console.log(res);
				this.sent = true;
				//this.showTable = true;
				if (this.generateIdPrebill) {
					this.creaIdPreBill();
				} // @ts-ignore
				this.markAsSend();
				Swal.fire({
					position: "center",
					icon: "success",
					title: "Email has been sent",
					showConfirmButton: false,
					timer: 2200,
				});
				this.showTable = true;
				document.getElementById("all").click();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	/**
	 * Apre la modale di invio massivo
	 * @param invioMassivo
	 */
	async openInvioMassivoModal(invioMassivo) {
		this.sommaAnticipoPre = 0;
		this.sommaAnticipoPost = 0;
		this.mandatariaSelected = null;
		// Apre la modale
		this.modalService.open(invioMassivo, {
			centered: true,
			size: "xl",
			keyboard: false,
			backdrop: "static",
		});
		this.mandatarie = await this.settingService.getAllMandatarieFromDB();
		this.calcolaSaldoAnticipo();

		let groupPartner = this.groupByPartnerFunction();
		console.log(groupPartner);
		Object.keys(groupPartner).forEach((partnerId) => {
			if (
				groupPartner[partnerId][0].campaingName.toLowerCase() !==
				"anticipo"
			) {
				this.sommaAnticipoPre +=
					groupPartner[partnerId][0].amountPreInvio;
				this.sommaAnticipoPost +=
					groupPartner[partnerId][0].amountPostInvio;
			} else {
				this.sommaAnticipoPre +=
					groupPartner[partnerId][1].amountPreInvio;
				this.sommaAnticipoPost +=
					groupPartner[partnerId][1].amountPostInvio;
			}
		});
	}
	// const removeDuplicates = (array, key) => {
	// 	return array.reduce((arr, item) => {
	// 	   const removed = arr.filter(i => i[key] !== item[key]);
	// 	   return [...removed, item];
	// 	}, []);
	//  };
	/**
	 * Applica i filtri alla tabella di invio massivo
	 * @param nomePartner nome del partner da ricercare
	 * @param nomeCampagna il nome della campagna da ricercare
	 */
	filtraEmailMassiveTable(nomePartner, nomeCampagna) {
		if (nomePartner) {
			this.nomePartner = nomePartner;
		}
		if (nomeCampagna) {
			this.nomeCampagna = nomeCampagna;
		}

		this.allDetailsDataTable.forEach((element) => {
			element.show = false;
		});

		if (this.nomeCampagna && this.nomePartner) {
			this.allDetailsDataTable.forEach((detail) => {
				if (
					this.nomeCampagna.toLowerCase() ===
						detail.campaingName.toLowerCase() &&
					this.nomePartner.toLowerCase() ===
						detail.partnerName.toLowerCase()
				) {
					detail.show = true;
				}
			});
			return;
		}
		this.allDetailsDataTable.forEach((detail) => {
			if (
				this.nomePartner &&
				this.nomePartner.toLowerCase() ===
					detail.partnerName.toLowerCase()
			) {
				detail.show = true;
			}
			if (
				this.nomeCampagna &&
				this.nomeCampagna.toLowerCase() ===
					detail.campaingName.toLowerCase()
			) {
				detail.show = true;
			}
		});
	}

	/**
	 * Resetta i filtri applicati
	 */
	resetFiltriEmailMassive() {
		this.nomePartner = null;
		this.nomeCampagna = null;
		$("#nomePartner").prop("selectedIndex", 0);
		$("#nomeCampagna").prop("selectedIndex", 0);
		this.allDetailsDataTable.forEach((element) => {
			element.show = true;
		});
	}
	isOpenStatus(status) {
		return status.toLowerCase() === "open";
	}
	isCampagnaAnticipo(campagna) {
		return campagna.toLowerCase() === "anticipo";
	}

	async invioMassivoSendEmail() {
		let partners = this.groupByPartnerFunction();
		let arrayIdPartner = Object.keys(partners);
		let mainContractorArray: {
			idPartner: number;
			emailMainContractor: string;
			nomeMainContractor: string;
		}[] = [];

		for (let idPartner of arrayIdPartner) {
			let mainContractor: any = await this.getMainContractor(idPartner);
			if (mainContractor.idMainContractor) {
				let emailMainContractor: any =
					await this.accountPartnerService.getEmailPartnerFromDB(
						mainContractor.idMainContractor
					);

				mainContractorArray.push({
					idPartner: +idPartner,
					emailMainContractor: emailMainContractor,
					nomeMainContractor: mainContractor.nomeMainContractor,
				});
			}
		}
		mainContractorArray.forEach((mainContractor) => {
			partners[mainContractor.idPartner].mainContractor = mainContractor;
		});
		console.log(partners);
		this.creaOggettoPerInvioMassivo(partners);
		this.modalService.dismissAll();
	}

	creaOggettoPerInvioMassivo(partners) {
		let sendTo = [];
		let subject;
		let cc = [];
		Object.keys(partners).forEach((key) => {
			sendTo = [];
			subject = "";
			let mainContractor = partners[key].mainContractor;
			if (mainContractor) {
				mainContractor.emailMainContractor.forEach(
					(emailMainContractor) => {
						sendTo.push(emailMainContractor.id);
					}
				);

				subject = this.setSubjectEmail(
					(mainContractor.nomeMainContractor +=
						": " + partners[key][0].partnerName)
				);
			} else {
				console.log(partners[key]);
				sendTo.push(partners[key][0].emGen);
				subject = this.setSubjectEmail(partners[key][0].partnerName);
			}
			this.partnerDetailsForMail = this.allDetailsDataTable.filter(
				(partner) => partner.partnerId === partners[key][0].partnerId
			);
			this.subject = subject;
			this.sendTo = sendTo;
			this.ccTo = [];

			this.createEmailToSend();
			// START SENDING EMAIL //
			this.emailToSend.forEach((email) => {
				this.sendEmailHttp(email, 0);
			});
			console.log(subject, sendTo);
			console.log(this.partnerDetailsForMail);
		});
	}

	groupByPartnerFunction() {
		return this.allDetailsDataTable.reduce((results, org) => {
			(results[org.partnerId] = results[org.partnerId] || []).push(org);
			return results;
		}, {});
	}

	async getMainContractor(idPartner) {
		return await this.detailsService.getMainContractor(idPartner);
	}

	groupByAccountManagerFunction() {
		return this.allDetailsDataTable.reduce((results, org) => {
			(results[org.accountEmail] = results[org.accountEmail] || []).push(
				org
			);
			return results;
		}, {});
	}

	inviaBozza() {
		let accounts = this.groupByAccountManagerFunction();
		Object.keys(accounts).forEach((account) => {
			this.partnerDetailsForMail = accounts[account];
			this.emailToSend = [];
			accounts[account].forEach((partner) => {
				this.emailToSend.push({
					dettaglio: partner,
					idPrebill: 0,
					mandataria: { templateMail: 0 },
				});
			});
			this.sendTo = account;
			this.subject = "email per i partner di " + account;
			this.emailToSend.forEach((email) => {
				this.sendEmailHttp(email, 0);
			});
			this.markAsSend();
		});
		this.modalService.dismissAll();
	}

	calcolaSaldoAnticipo() {
		let campagneAnticipo = this.allDetailsDataTable.filter(
			(element) => element.campaingName.toLowerCase() === "anticipo"
		);
		let campagneAnticipoOpen = campagneAnticipo.filter((elemente) =>
			this.isOpenStatus(elemente.status)
		);
		let campagneAnticipoSend = campagneAnticipo.filter(
			(elemente) => !this.isOpenStatus(elemente.status)
		);

		campagneAnticipoSend.forEach((campagna) => {
			this.allDetailsDataTable.forEach((partner) => {
				partner.amountPreInvio = 0;
				partner.amountPostInvio = 0;
				if (
					partner.partnerId === campagna.partnerId &&
					partner.campaingName.toLowerCase() !== "anticipo"
				) {
					partner.amountPreInvio += parseInt(campagna.anticipo);
					partner.amountPostInvio = partner.amountPreInvio;
				}
			});
		});

		campagneAnticipoOpen.forEach((campagna) => {
			this.allDetailsDataTable.forEach((partner) => {
				if (
					partner.partnerId === campagna.partnerId &&
					partner.campaingName.toLowerCase() !== "anticipo"
				) {
					partner.amountPostInvio += parseInt(campagna.anticipo);
				}
			});
		});
	}

	openDettaglioPartner(dettaglioPartner, partnerName) {
		this.openByPartner(partnerName, true);
		console.log(this.allDetailsPartnerDatatable);
		let anticipo = 0;
		this.allDetailsPartnerDatatable.forEach((partner) => {
			this.totalePayIn += parseInt(partner.amountIn);
			this.totalePayOut += parseInt(partner.amountOut);
			anticipo += parseInt(partner.anticipo);
		});
		this.saldoAsInOverall =
			this.totalePayIn - (this.totalePayOut + anticipo);
		this.modalService.open(dettaglioPartner, {
			centered: true,
			size: "xl",
			keyboard: false,
			backdrop: "static",
		});
	}
}
