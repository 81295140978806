import { Injectable } from "@angular/core";
import {
	HttpClient,
	HttpHeaders,
	HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";

import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class AuthenticationService {
	endpoint: string = "";
	headers = new HttpHeaders().set("Content-Type", "application/json");
	currentUser = {};
	data;
	results;
	tokenResults;

	jwtHelper = new JwtHelperService();

	constructor(private http: HttpClient, public router: Router) {}

	// GET LOGIN TOKEN
	async getToken(user) {
		return await this.http
			.post(`https://api.anagraficatest.wearefiber.com/login`, {
				email: `${user.email}`,
				password: `${user.password}`,
			})
			.toPromise();
	}

	// GET TOKEN FROM SIGN-IN WITH GOOGLE
	async getGoogleToken(user) {
		// console.log('GOT USER ', user)

		delete user.idToken;
		delete user.authToken;

		let body = {
			email: `${user.email}`,
			uid: `${user.id}`,
			data: user,
		};

		// console.log(body)

		return await this.http
			.post(`${environment.API_URL}googlelogin`, body)
			.toPromise();
	}

	// GET ROLE FROM TOKEN
	getUserRole() {
		const rawToken = localStorage.getItem("anagraficaToken");
		const decodedToken = this.jwtHelper.decodeToken(rawToken);
		var user = decodedToken.data;
		return user.user_type;
	}

	// GET IS SUPERUSER FROM TOKEN
	isSuperUser() {
		const rawToken = localStorage.getItem("anagraficaToken");
		const decodedToken = this.jwtHelper.decodeToken(rawToken);
		var user = decodedToken.data;
		return user.is_superuser;
	}

	getUserId() {
		const rawToken = localStorage.getItem("anagraficaToken");
		const decodedToken = this.jwtHelper.decodeToken(rawToken);
		var user = decodedToken.data;
		return user.id;
	}

	canViewAllPartners() {
		const rawToken = localStorage.getItem("anagraficaToken");
		const decodedToken = this.jwtHelper.decodeToken(rawToken);
		var user = decodedToken.data;
		return user.view_all_partners;
	}

	// IF TOKEN EXPIRED REFRESH IT WHILE USER IS WORKING
	async verifyToken() {
		// console.log('Verifying token')
		if (this.hasTokenExpired()) {
			await this.http
				.post(`https://api.anagraficatest.wearefiber.com/refresh`, {
					token: localStorage.getItem("anagraficaToken"),
				})
				.toPromise()
				.then((res: any) => {
					console.log(res);
					localStorage.removeItem("anagraficaToken");
					localStorage.setItem("anagraficaToken", res.token);
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			// console.log('Token hasnt expired yet')
		}
		// console.log('Token verified')
	}

	hasTokenExpired() {
		var myRawToken = localStorage.getItem("anagraficaToken");

		if (!myRawToken) {
			return true; // BECAUSE THERE IS NO TOKEN AT ALL
		}

		return this.jwtHelper.isTokenExpired(myRawToken);
	}

	isEnabled() {
		var myRawToken = localStorage.getItem("anagraficaToken");

		if (!!myRawToken) {
			const decodedToken = this.jwtHelper.decodeToken(myRawToken);
			// console.log(decodedToken)
			return decodedToken.data.is_active;
		} else {
			return false;
		}
	}
}
