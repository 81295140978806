import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Apollo } from "apollo-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthenticationService } from "./authentication.service";
import { type } from "os";

@Injectable({
	providedIn: "root",
})
export class DocumentsService {
	docTemp;

	constructor(
		private apollo: Apollo,
		private http: HttpClient,
		private authService: AuthenticationService
	) {}

	async addDocument(
		docName,
		rocId,
		visuraCameraleId,
		documentoRiconoscimentoId
	) {
		await this.authService.verifyToken();

		var mut = `
    mutation{
      createPartnerDocument(input:{
        clientMutationId:"${this.getUserId()}"
        partnerDocument:{
          createdAt:"${new Date().toISOString().split("T")[0]}"
          updatedAt:"${new Date().toISOString().split("T")[0]}"
          doc:"${docName}"
          rocId:${rocId}
          visuraCameraleId:${visuraCameraleId}
          documentoRiconoscimentoId:${documentoRiconoscimentoId}
          deleted:false
        }
      }){
        partnerDocument{
          id
          doc
          rocId
          visuraCameraleId
          documentoRiconoscimentoId
        }
      }
    }`;
		// console.log(mut)

		return await this.apollo
			.mutate({
				mutation: gql`
					${mut}
				`,
			})
			.toPromise();
	}

	async addDocumentToDB(
		docName,
		rocId,
		visuraCameraleId,
		documentoRiconoscimentoId
	) {
		let uploaded = -1;

		await this.addDocument(
			docName,
			rocId,
			visuraCameraleId,
			documentoRiconoscimentoId
		)
			.then((res) => {
				// console.log(res)
				this.docTemp = res.data;
				this.docTemp =
					this.docTemp.createPartnerDocument.partnerDocument.id;
				uploaded = this.docTemp;
			})
			.catch((err) => {
				console.log(err);
			});

		return uploaded;
	}

	async updateContract(contractId, docId) {
		await this.authService.verifyToken();

		var mut = `
    mutation{
      updatePartnerContractById(input:{
        clientMutationId:"1"
        id:${contractId}
        partnerContractPatch:{
          documentId:${docId}
        }
      }){
        partnerContract{
          partnerDocumentByDocumentId{
            id
            doc
          }
        }
      }
    }`;

		// console.log(mut)

		return await this.apollo
			.mutate({
				mutation: gql`
					${mut}
				`,
			})
			.toPromise();
	}

	async updateContractInDB(contractId, docId) {
		let updated = false;

		await this.updateContract(contractId, docId)
			.then((res) => {
				console.log(res);
				updated = true;
			})
			.catch((err) => {
				console.log(err);
			});
		return updated;
	}

	async updateDeal(dealId, fileName) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerDealById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${dealId}
          partnerDealPatch:{
            document:"${fileName}"
          }
        }){
          partnerDeal{
            document
            id
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async updateDealInDB(dealId, fileName) {
		let updated = false;

		await this.updateDeal(dealId, fileName)
			.then((res) => {
				// console.log(res)
				updated = true;
			})
			.catch((err) => {
				console.log(err);
			});
		return updated;
	}
	//RETURN DOCUMENT NAME
	async uploadDocument(document) {
		var data = new FormData();
		data.append("file", document);

		return await this.http
			.post(`${environment.API_URL}/upload`, data)
			.toPromise();
	}

	async createTeamwork(name, partnerId, externalAppId, externalApp, typeApp) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        createPartnerTeamwork(input:{
          clientMutationId:"${this.getUserId()}"
          partnerTeamwork:{
            createdAt:"${new Date().toISOString().split("T")[0]}"
            updatedAt:"${new Date().toISOString().split("T")[0]}"
            deleted:false
            name:"${name}"
            type:""
            partnerId:${partnerId}
            email:""
            app:"${externalApp}"
            externalApp:"${externalAppId}"
            typeApp:"${typeApp}"
          }
        }){
          partnerTeamwork{
            name
            type
            partnerId
            email
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async createTeamworkInDB(
		name,
		partnerId,
		externalAppId?,
		externalApp?,
		typeApp?
	) {
		let created = false;

		await this.createTeamwork(
			name,
			partnerId,
			externalAppId,
			externalApp,
			typeApp
		)
			.then((res) => {
				// console.log(res)
				created = true;
			})
			.catch((err) => {
				console.log(err);
			});
		return created;
	}

	getUserId() {
		// TO DO, CHANGE AFTER TOKEN ID
		var myRawToken = localStorage.getItem("anagraficaToken");
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(myRawToken);
		return decodedToken.data.id;
	}

	async getTeamworkPartner(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerTeamworks(condition: {partnerId: ${partnerId}}) {
          nodes {
            externalApp
            typeApp
            app
          }
        }
      }      
    `,
			})
			.toPromise();
	}
}
