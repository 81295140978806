import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	AfterViewInit,
	ChangeDetectorRef,
	ɵConsole,
	TemplateRef,
} from "@angular/core";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import * as $ from "jquery";

export interface DealData {
	nr: string;
	campaign: string;
	brand: string;
	listing: string;
	startMonth: string;
	date: string;
	isActive: boolean;
}

export interface DealDataU {
	nr: string;
	campaign: string;
	brand: string;
	listing: string;
	startMonth: string;
	date: string;
	isActive: boolean;
	uploaded: boolean;
}

import { ActivatedRoute, Router } from "@angular/router";
import { PartnersService } from "../../../../../app/services/partners.service";
import { MatDialog } from "@angular/material/dialog";
import {
	FormControl,
	Validators,
	FormGroup,
	FormBuilder,
} from "@angular/forms";
import { AccountPartnersService } from "../../../../../app/services/account-partners.service";
import { DocumentsService } from "../../../../../app/services/documents.service";
import { AuthenticationService } from "../../../../../app/services/authentication.service";
import { type } from "os";
import { async } from "@angular/core/testing";
import { Key } from "selenium-webdriver";
import { SettingsService } from "../../../../services/settings.service";
import { now, update } from "lodash";

@Component({
	selector: "kt-docs",
	templateUrl: "./docs.component.html",
	styleUrls: ["./docs.component.scss"],
})
export class DocsComponent implements OnInit {
	@ViewChild("wizard", { static: true }) el: ElementRef;
	@ViewChild("errorContent", { static: false }) error: TemplateRef<any>;

	// NORMAL DEALS INFO
	displayedColumns: string[] = [
		"nr",
		"campaign",
		"brand",
		"listing",
		"mandataria",
		"startMonth",
		"isActive",
	];
	dataSource: MatTableDataSource<DealData>;

	@ViewChild("dealPaginator", { static: true }) paginator: MatPaginator;
	@ViewChild("sortDeal", { static: true }) sortDeal: MatSort;

	// UPLOADED FILES DEAL INFO
	displayedColumnsU: string[] = [
		"nr",
		"campaign",
		"brand",
		"listing",
		"mandataria",
		"startMonth",
		"uploaded",
		"action",
	];
	dataSourceU: MatTableDataSource<DealDataU>;

	@ViewChild("dealPaginatorU", { static: true }) paginatorU: MatPaginator;
	@ViewChild("sortDealU", { static: true }) sortDealU: MatSort;

	partnerForm: FormGroup;
	hideNotActive: boolean = false;

	partnerId: number; // Partner Id
	partner; // Object with partner info
	partnerTemp;
	statusReady = false;
	deals = [];
	dealsWithoutAnticipo = [];
	dealIdForFileUpload = 0;
	businessUnit = [];
	wizard: any; // Wizard steps
	step = 3;
	from = "wip";

	externalAppId = new FormGroup({
		0: new FormControl("", [Validators.required]),
	});
	type = new FormGroup({
		0: new FormControl("", [Validators.required]),
	});
	externalApp = new FormGroup({
		0: new FormControl("", [Validators.required]),
	});

	apps: any[] = [
		{ value: "Teamwork", viewValue: "Teamwork" },
		{ value: "Coraly", viewValue: "Coraly" },
	];
	types: any[] = [
		{ value: "Runner", viewValue: "Runner" },
		{ value: "Operatore", viewValue: "Operatore" },
		{ value: "Company name", viewValue: "Company name" },
	];
	externalAppNumber = [0];

	currentMonth = new Date().toLocaleString("default", { month: "long" });
	nextMonth: any = new Date(1999, new Date().getMonth() + 1, 1);
	editDate;

	nextStep = 4;
	changedFromButton = true;

	//////////////
	// FILES /////
	valid = ["png", "jpeg", "jpg", "pdf", "doc", "webp", "odt"];
	docRoc;
	docRocValid = false;
	docRocUpload = false;
	visCam;
	visCamValid = false;
	visCamUpload = false;
	general;
	generalValid = false;
	generalUpload;
	contract;
	contractValid = false;
	contractUpload = false;
	dealFile;
	dealFileValid = false;
	dealFileUpload = false;
	docTemp;
	loading = false;
	allPadri;

	isAnalyst = false;
	agents = [];
	selectedAgent = 0;

	emails = [];
	submitted;
	index;
	disabledEmail = true;
	enableAccountManager = false;

	constructor(
		private authService: AuthenticationService,
		private docService: DocumentsService,
		private fb: FormBuilder,
		public dialog: MatDialog,
		private accountPartnersService: AccountPartnersService,
		private route: ActivatedRoute,
		private router: Router,
		private partnersService: PartnersService,
		private chRef: ChangeDetectorRef,
		private formGroup: FormBuilder,
		private settingService: SettingsService
	) {
		this.nextMonth = this.nextMonth.toLocaleString("default", {
			month: "long",
		});
		this.partnerId = this.route.snapshot.params.id;
		this.from = this.route.snapshot.queryParams.from;
		// console.log(this.from, ' from')
		this.partner = {};

		if (!(this.partnerId > 0)) {
			console.log("Wrong id");
			this.router.navigateByUrl("/error/error-v2");
		}

		this.initNewPartnerStep();
	}

	async assignAccount() {
		this.enableAccountManager = false;
		// console.log(this.partnerId, this.selectedAgent)
		if (!(this.partnerId > 0) || !(this.selectedAgent > 0)) {
			console.log("Invalid inputs for assigning");
			return;
		}

		let assigned = false;
		await this.accountPartnersService
			.creaAssociazioneAccountManager(
				this.partnerId,
				this.selectedAgent,
				this.editDate == "current"
			)
			.then((res) => {
				// console.log(res)
				assigned = true;
			})
			.catch((err) => {
				console.log(err);
			});

		if (assigned) {
			window.location.reload();
		} else {
			console.log("Could not assign account to partner");
		}
	}

	initWizard(): void {
		// Initialize form wizard
		this.wizard = new KTWizard(this.el.nativeElement, {
			startStep: this.step,
		});
		// Change event
		KTUtil.scrollTop();

		this.fileNameAppear();
	}

	async wizardFunction() {
		// VALIDATE
		// IF MOVING FROM TOP, DONT ALLOW IF MORE THAN MAX
		console.log("before switch", this.wizard.getStep());
		if (!this.changedFromButton) {
			if (this.nextStep > this.step) {
				this.wizard.stop();
				console.log("STOPPED BECAUSE UNCOMPLETE STEP");
			}
		} else {
			switch (this.wizard.getStep()) {
				case 2:
					const updated =
						await this.partnersService.updateToSendingDocsInDB(
							this.partnerId
						);

					if (!updated) {
						this.dialog.open(this.error, {
							data: "Could not update to sending documentation... aborting... Please try again later",
						});
						this.wizard.stop();
					} else {
						this.step = 3;
						console.log("Updated to sending docs");
					}
					break;
				case 3:
					// VALIDATE FROM SENDING_DOCUMENTS TO DOCS_CONFIRMED
					const updated1 =
						await this.partnersService.updateToDocsConfirmedInDB(
							this.partnerId
						);

					if (!updated1) {
						// console.log('Could not update, aborting')
						this.wizard.stop();
					} else {
						// console.log('UPDATED STATUS TO DOCS CONFIRMED')
						this.step = 4;
						this.partner.status = "docs_confirmed";
						this.partnersService.allPartners[
							this.partnerId
						].status = "docs_confirmed";
					}
					break;
				case 4:
					if (this.areAllDocsUploaded()) {
						this.step = 5;
						this.partner.status = "teamwork";
						this.partnersService.allPartners[
							this.partnerId
						].status = "teamwork";
						await this.partnersService.updateToTeamworkStatus(
							this.partnerId
						);
					} else {
						this.wizard.stop();
						this.dialog.open(this.error, {
							data: "Make sure to have uploaded all required documents.",
						});
						// console.log('Docs are not uploaded fully')
					}
					break;
				case 5:
					await this.partnersService.updateToConfirmedInDB(
						this.partnerId
					);
					break;
			}
			this.wizard.goNext();
			console.log("actualStep", this.wizard.getStep());
		}
		// wizardObj.stop();
	}

	areAllDocsUploaded() {
		if (
			!this.partner.doc_ric ||
			!this.partner.vis_cam ||
			!this.partner.contract
		) {
			console.log("One or more missing docs found");
			return false;
		}

		for (const deal of this.deals) {
			if (!deal.uploaded) {
				console.log("Deal withput file uploaded found");
				return false;
			}
		}

		return true;
	}

	fileNameAppear() {
		// Add the following code if you want the name of the file appear on select
		$(".custom-file-input").on("change", function () {
			var fileName = $(this).val().split("\\").pop();
			$(this)
				.siblings(".custom-file-label")
				.addClass("selected")
				.html(fileName);
		});
	}

	onSubmit() {
		window.location.replace("/partners");
	}

	getStepNr() {
		if (this.partner.status === "new") {
			return 1;
		} else if (this.partner.status === "listino") {
			return 2;
		} else if (this.partner.status === "sending_documentation") {
			return 3;
		} else if (this.partner.status === "docs_confirmed") {
			return 4;
		} else if (this.partner.status === "teamwork") {
			return 5;
		} else if (this.partner.status === "confirmed") {
			return 6;
		} else {
			return 3;
		}
	}

	async ngOnInit() {
		this.isAnalyst =
			this.authService.getUserRole() === 1 ||
			this.authService.getUserRole() === 2;
		// console.log('Is analyst ', this.isAnalyst)
		// If already here dont call from DB
		if (!!this.partnersService.allPartners[this.partnerId]) {
			console.log("Got from service");

			this.partner = this.partnersService.allPartners[this.partnerId];
		} else {
			this.partner = await this.partnersService.getPartnerInfoFromDB(
				this.partnerId
			);
			// console.log('Got from DB', this.partner)
		}

		if (this.isAnalyst) {
			this.agents =
				await this.accountPartnersService.getAllAgentsFromDB();
		}

		// INIT WIZARD, GET NR OF STEP
		if (this.from !== "super") {
			this.step = this.getStepNr();
		} else {
			this.step = 4;
		}

		this.initWizard();
		let accountManager =
			await this.settingService.getLastAssociazioneAccountManagerFromDB(
				this.partnerId
			);
		if (
			accountManager &&
			new Date(accountManager.createdAt).getTime() <=
				new Date().getTime() &&
			this.partner.accountId != accountManager.accountId
		) {
			this.selectedAgent = +accountManager.accountId;
			await this.modificaAccountManagerPartner();
		} else {
			this.selectedAgent = this.partner.accountId;
		}

		if (this.step === 6) {
			this.setExternalApp();
		}

		// STEP 3 and 4
		if (!!this.partner.partnerContractByPartnerId) {
			this.deals = this.partnersService.getDeals(
				this.partner.partnerContractByPartnerId
			);
			this.dealsWithoutAnticipo = this.deals.filter((val) => {
				return val.id !== 1254;
			});
		} else {
			// console.log('CONTRACT UNDEFINED')
		}

		this.chRef.detectChanges();
		this.initDealTable();
		this.initDealUploadTable();
		this.setNewPartnerValues();

		// END STEP 3 and 4
	}

	async initNewPartnerStep() {
		this.partnerForm = this.fb.group({
			nomeCognomeLegale: ["", [Validators.required]],
			telefonoGenerale: ["", [Validators.required]],
			pivaCodiceFiscale: ["", [Validators.required]],
			ragioneSociale: ["", [Validators.required]],
			numeroUscita: ["", [Validators.required]],
			emailAmm: [""],
			nomeCognomeAmm: [""],
			telefonoAmm: [""],
			nomeCognomeBo: [""],
			emailBo: [""],
			refEmail: [""],
			roc: [""],
			notes: [""],
			mainContractor: [""],
			isMainContractor: [""],
			padre: [null],
			isPadre: [false],
			type: ["", [Validators.required]],
			businessUnit: ["", [Validators.required]],
		});
		this.emails = await this.accountPartnersService.getEmailPartnerFromDB(
			this.partnerId
		);
		console.warn(this.emails);
		this.businessUnit =
			await this.settingService.getAllBusinessUnitFromDB();

		this.setSelectEmail();
	}

	// Get control to Form easily
	get p() {
		return this.partnerForm.controls;
	}

	async setNewPartnerValues() {
		let associazione = await this.settingService.getLastAssocizioneFromDB(
			this.partnerId
		);
		console.log(associazione);
		if (
			associazione &&
			new Date(associazione.createdAt).getTime() <=
				new Date().getTime() &&
			this.partner.bu != associazione.bu
		) {
			this.modificaBuPartner(associazione.bu);
			this.partner.bu = associazione.bu;
		}
		this.partnerForm.setValue({
			nomeCognomeLegale: this.partner.nomeCognomeLegale,
			telefonoGenerale: this.partner.telefonoGenerale,
			pivaCodiceFiscale: this.partner.pivaCodiceFiscale,
			ragioneSociale: this.partner.ragioneSociale,
			numeroUscita: this.partner.numeroUscita,
			emailAmm: this.partner.emailAmm,
			nomeCognomeAmm: this.partner.nomeCognomeAmm,
			telefonoAmm: this.partner.telefonoAmm,
			nomeCognomeBo: this.partner.nomeCognomeBo,
			emailBo: this.partner.emailBo,
			refEmail: this.partner.refEmail,
			roc: this.partner.roc,
			notes: this.partner.notes,
			type: this.partner.type,
			businessUnit: this.partner.bu,
			mainContractor: this.partner.nomeMainContractor,
			isMainContractor: this.partner.isMainContractor,
			padre: this.partner.nomePadre,
			isPadre: this.partner.isPadre,
		});

		this.partnerForm.disable();
	}

	async modificaBuPartner(bu) {
		await this.settingService.updateBu(this.partnerId, bu).then();
	}

	async modificaAccountManagerPartner() {
		await this.settingService.updateAccountManager(
			this.partnerId,
			this.selectedAgent
		);
	}

	initDealTable() {
		this.dataSource = new MatTableDataSource(this.deals);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sortDeal;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {
				case "startMonth":
					return new Date(item.date);
				default:
					return item[property];
			}
		};
	}

	initDealUploadTable() {
		this.dataSourceU = new MatTableDataSource(this.deals);
		this.dataSourceU.paginator = this.paginatorU;
		this.dataSourceU.sort = this.sortDealU;
		this.dataSourceU.sortingDataAccessor = (item, property) => {
			switch (property) {
				case "startMonth":
					return new Date(item.date);
				default:
					return item[property];
			}
		};
	}

	openDealUploadModal(dealUploadContent, dealId) {
		this.dealIdForFileUpload = dealId;
		// console.log('To update deal id ', this.dealIdForFileUpload)
		this.dialog.open(dealUploadContent);
		this.fileNameAppear();
	}

	//////////////////////////////////////////////////////
	//// file uploads related code ///////////////////////
	//////////////////////////////////////////////////////
	onVisCamChanged(event) {
		console.log("File changed vis cam");
		this.visCamUpload = false;
		if (!!event.target.files[0]) {
			this.visCam = event.target.files[0];
			const ext = this.visCam.name.split(".").pop();

			if (this.valid.includes(ext)) {
				this.visCamValid = true;
			} else {
				this.visCamValid = false;
			}
		} else {
			this.visCamValid = false;
		}
	}

	onDocRocChanged(event) {
		console.log("File changed doc roc");
		this.docRocUpload = false;
		if (!!event.target.files[0]) {
			this.docRoc = event.target.files[0];
			const ext = this.docRoc.name.split(".").pop();

			if (this.valid.includes(ext)) {
				this.docRocValid = true;
			} else {
				this.docRocValid = false;
			}
		} else {
			this.docRocValid = false;
		}
	}

	onGeneralChanged(event) {
		console.log("File changed general");
		this.generalUpload = false;
		if (!!event.target.files[0]) {
			this.general = event.target.files[0];
			const ext = this.general.name.split(".").pop();

			if (this.valid.includes(ext)) {
				this.generalValid = true;
			} else {
				this.generalValid = false;
			}
		} else {
			this.generalValid = false;
		}
	}

	onContractChanged(event) {
		console.log("File changed general");
		this.contractUpload = false;
		if (!!event.target.files[0]) {
			this.contract = event.target.files[0];
			const ext = this.contract.name.split(".").pop();

			if (this.valid.includes(ext)) {
				this.contractValid = true;
			} else {
				this.contractValid = false;
			}
		} else {
			this.contractValid = false;
		}
	}

	onDealFileChanged(event) {
		console.log("File changed deal file");
		this.dealFileUpload = false;
		if (!!event.target.files[0]) {
			this.dealFile = event.target.files[0];
			const ext = this.dealFile.name.split(".").pop();

			if (this.valid.includes(ext)) {
				this.dealFileValid = true;
			} else {
				this.dealFileValid = false;
			}
		} else {
			this.dealFileValid = false;
		}
	}

	async uploadVisCam() {
		this.visCamUpload = true;
		// console.log(this.visCam, this.visCamValid)
		if (!this.visCamValid) {
			return;
		}

		this.loading = true;
		const fileName = await this.uploadFileToDB(1);
		if (fileName === "") {
			console.log("Could not upload file to DB");
		} else {
			// CREATE DOCUMENT AND CHANGE PARTNER DOCUMENTS ACCORDINGLY
			const uploaded = await this.docService.addDocumentToDB(
				fileName,
				null,
				this.partnerId,
				null
			);
			if (uploaded !== -1) {
				console.log("Document created successfully");
				this.partnersService.allPartners[this.partnerId].vis_cam = true;
				this.partnersService.allPartners[
					this.partnerId
				].document_vis_cam.push({
					doc: fileName,
					createdAt: new Date().toISOString(),
				});
				this.partner.vis_cam = true;
			} else {
				console.log("Could not create document");
			}
		}

		this.loading = false;
	}

	async uploadDocRoc() {
		this.docRocUpload = true;
		// console.log(this.docRoc, this.docRocValid)
		if (!this.docRocValid) {
			return;
		}
		this.loading = true;
		const fileName = await this.uploadFileToDB(2);
		if (fileName === "") {
			console.log("Could not upload file to DB");
		} else {
			// CREATE DOCUMENT AND CHANGE PARTNER DOCUMENTS ACCORDINGLY
			const uploaded = await this.docService.addDocumentToDB(
				fileName,
				null,
				null,
				this.partnerId
			);
			if (uploaded !== -1) {
				console.log("Document created successfully");
				this.partnersService.allPartners[this.partnerId].doc_ric = true;
				this.partnersService.allPartners[
					this.partnerId
				].document_doc_ric.push({
					doc: fileName,
					createdAt: new Date().toISOString(),
				});
				this.partner.doc_ric = true;
			} else {
				console.log("Could not create document");
			}
		}

		this.loading = false;
	}

	async uploadGeneral() {
		this.generalUpload = true;
		// console.log(this.general, this.generalValid)
		if (!this.generalValid) {
			return;
		}

		this.loading = true;
		const fileName = await this.uploadFileToDB(3);
		if (fileName === "") {
			console.log("Could not upload file to DB");
		} else {
			// CREATE DOCUMENT AND CHANGE PARTNER DOCUMENTS ACCORDINGLY
			const uploaded = await this.docService.addDocumentToDB(
				fileName,
				this.partnerId,
				null,
				null
			);
			if (uploaded !== -1) {
				console.log("Document created successfully");
				this.partnersService.allPartners[this.partnerId].general = true;
				this.partnersService.allPartners[
					this.partnerId
				].document_general.push({
					doc: fileName,
					createdAt: new Date().toISOString(),
				});
				this.partner.general = true;
			} else {
				console.log("Could not create document");
			}
		}

		this.loading = false;
	}

	async uploadContract() {
		this.contractUpload = true;
		// console.log(this.contract, this.contractValid)
		if (!this.contractValid || this.partner.contracId === -1) {
			return;
		}

		this.loading = true;
		const fileName = await this.uploadFileToDB(4);
		if (fileName === "") {
			console.log("Could not upload file to DB");
		} else {
			// CREATE DOCUMENT AND CHANGE PARTNER DOCUMENTS ACCORDINGLY
			const docId = await this.docService.addDocumentToDB(
				fileName,
				null,
				null,
				null
			);
			if (docId !== -1) {
				// console.log('Doc uploaded')
				const uploaded = await this.docService.updateContractInDB(
					this.partner.contractId,
					docId
				);
				if (uploaded) {
					// console.log('Contract updated successfully')
					this.partnersService.allPartners[this.partnerId].contract =
						true;
					this.partner.contract = true;
				} else {
					// console.log('Could not update contract')
				}
			} else {
				// console.log('Could not upload doc')
			}
		}

		this.loading = false;
	}

	async uploadDealFile() {
		this.dealFileUpload = true;
		if (!this.dealFileValid) {
			return;
		}

		this.loading = true;
		const fileName = await this.uploadFileToDB(5);
		if (fileName === "") {
			console.log("Could not upload file to DB");
		} else {
			// CREATE DOCUMENT AND CHANGE PARTNER DOCUMENTS ACCORDINGLY
			const uploaded = await this.docService.addDocumentToDB(
				fileName,
				null,
				null,
				null
			);
			if (uploaded !== -1) {
				// console.log('Document created successfully')
				const updated = await this.docService.updateDealInDB(
					this.dealIdForFileUpload,
					fileName
				);

				if (updated) {
					// console.log('Deal updated successfully')
					for (const deal of this.deals) {
						if (deal.id === this.dealIdForFileUpload) {
							deal.uploaded = true;
							break;
						}
					}

					for (const deal of this.partnersService.allPartners[
						this.partnerId
					].partnerContractByPartnerId.partnerDealsByContractId
						.nodes) {
						if (deal.id === this.dealIdForFileUpload) {
							deal.uploaded = true;
						}
					}
				} else {
					// console.log('Could not update deal')
				}
			} else {
				// console.log('Could not create document')
			}
		}

		this.dialog.closeAll();
		this.loading = false;
	}

	// UPLOAD FILE AND RETURN NAME TO LATER ON CREATE A DOCUMENT
	async uploadFileToDB(which) {
		let uploaded = "";

		if (which === 1) {
			await this.docService
				.uploadDocument(this.visCam)
				.then((res) => {
					this.docTemp = res;
					this.docTemp = this.docTemp.data.name;
					uploaded = this.docTemp;
					// console.log(res, this.docTemp)
				})
				.catch((err) => {
					console.log(err);
				});
		}
		if (which === 2) {
			await this.docService
				.uploadDocument(this.docRoc)
				.then((res) => {
					this.docTemp = res;
					this.docTemp = this.docTemp.data.name;
					uploaded = this.docTemp;
					// console.log(res, this.docTemp)
				})
				.catch((err) => {
					console.log(err);
				});
		}
		if (which === 3) {
			await this.docService
				.uploadDocument(this.general)
				.then((res) => {
					this.docTemp = res;
					this.docTemp = this.docTemp.data.name;
					uploaded = this.docTemp;
					// console.log(res, this.docTemp)
				})
				.catch((err) => {
					console.log(err);
				});
		}
		if (which === 4) {
			await this.docService
				.uploadDocument(this.contract)
				.then((res) => {
					this.docTemp = res;
					this.docTemp = this.docTemp.data.name;
					uploaded = this.docTemp;
					// console.log(res, this.docTemp)
				})
				.catch((err) => {
					console.log(err);
				});
		}
		if (which === 5) {
			await this.docService
				.uploadDocument(this.dealFile)
				.then((res) => {
					this.docTemp = res;
					this.docTemp = this.docTemp.data.name;
					uploaded = this.docTemp;
					// console.log(res, this.docTemp)
				})
				.catch((err) => {
					console.log(err);
				});
		}

		return uploaded;
	}

	///////////////////////////////////////////////////////
	//// file uploads related code end ////////////////////
	///////////////////////////////////////////////////////

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	applyFilterU(event: Event) {
		const filterValueU = (event.target as HTMLInputElement).value;
		this.dataSourceU.filter = filterValueU.trim().toLowerCase();

		if (this.dataSourceU.paginator) {
			this.dataSourceU.paginator.firstPage();
		}
	}

	changeStep(nr) {
		this.changedFromButton = false;
		this.nextStep = nr;
	}

	setChangedFromButton(num) {
		this.changedFromButton = true;
		console.log("changedFromButton");
		this.wizardFunction();
		// 	this.step = this.step + num;
		// 	this.nextStep = this.step + num;
		// 	console.log(this.nextStep);
		// 	console.log(this.step);
		// 	this.wizard.goTo(this.step);
	}

	ngOnDestroy() {
		this.chRef.detach(); // do this
	}
	isExternalAppValid() {
		this.externalApp.enable();
		this.externalAppId.enable();
		this.type.enable();
		this.externalApp.markAllAsTouched();
		this.externalAppId.markAllAsTouched();
		this.type.markAllAsTouched();
		let controls = Object.keys(this.externalApp.controls);
		let valid = true;
		controls.forEach((control) => {
			if (this.externalApp.get(control).value === "Teamwork") {
				if (
					!this.externalAppId.get(control).valid ||
					!this.type.get(control).valid
				) {
					console.log(this.externalAppId.get(control).value);
					valid = false;
				}
			} else if (!this.externalAppId.get(control).value) {
				valid = false;
			}
		});
		return valid;
	}

	async setExternalApp() {
		this.externalAppId = this.formGroup.group({});
		this.type = this.formGroup.group({});
		this.externalApp = this.formGroup.group({});
		this.externalAppNumber = [];
		let index = 0;
		let nameControl = 0;
		await this.docService
			.getTeamworkPartner(this.partnerId)
			.then((res: any) => {
				let externalApps = res.data.allPartnerTeamworks.nodes;
				externalApps.forEach((app) => {
					this.externalAppId.addControl(
						nameControl.toString(),
						new FormControl(app.externalApp, [Validators.required])
					);

					this.type.addControl(
						nameControl.toString(),
						new FormControl(app.typeApp, [Validators.required])
					);

					this.externalApp.addControl(
						nameControl.toString(),
						new FormControl(app.app, [Validators.required])
					);
					this.externalAppNumber.push(nameControl);
					index += 1;
					nameControl += 1;
				});
			});

		this.externalAppId.disable();
		this.type.disable();
		this.externalApp.disable();
	}

	addFormApp() {
		let index = this.externalAppNumber.length - 1;
		let nameControl = this.externalAppNumber[index] + 1;

		this.externalApp.addControl(
			nameControl.toString(),
			new FormControl("", [Validators.required])
		);
		this.externalAppId.addControl(
			nameControl.toString(),
			new FormControl("", [Validators.required])
		);
		this.type.addControl(
			nameControl.toString(),
			new FormControl("", [Validators.required])
		);
		this.externalAppNumber.push(nameControl);
	}

	saveExternalApp() {
		if (this.isExternalAppValid()) {
			this.externalAppNumber.forEach((nome) => {
				let externalAppId = this.externalAppId.get(nome.toString());
				let externalApp = this.externalApp.get(nome.toString());
				let type = this.type.get(nome.toString());
				if (!externalApp.disabled) {
					this.linkExternalApp(
						externalAppId.value.toString(),
						externalApp.value.toString(),
						type.value.toString()
					);
				}
				externalAppId.disable();
				externalApp.disable();
				type.disable();
			});
		}
	}

	linkExternalApp(externalAppId, externalApp, type) {
		this.docService
			.createTeamworkInDB(
				externalAppId,
				this.partnerId,
				externalAppId,
				externalApp,
				type
			)
			.then(() => {
				console.log("res");
			});
	}

	hideNotActiveDeals() {
		this.hideNotActive = !this.hideNotActive;
		let deals = this.deals;
		if (this.hideNotActive) {
			deals = deals.filter((deal) => deal.isActive);
		}
		this.dataSource = new MatTableDataSource(deals);
	}

	salvaEdit() {
		let buForm = this.partnerForm.get("businessUnit");
		if (buForm.valid) {
			if (buForm.value != this.partner.bu) {
				this.settingService.createAssociazioneBuPartnerSetDate(
					this.partner.id,
					buForm.value,
					this.editDate == "current"
				);
				buForm.disable();
			}
		}
	}

	async saveEmail() {
		this.disabledEmail = true;
		this.index = this.emails.length;

		if (this.emails.length == 0) {
			let emailValue = $("#sendTo").val();
			console.log("email in select", emailValue);
			emailValue = emailValue.filter(
				(a, index) => emailValue.indexOf(a) === index
			);
			this.saveEmailInDB(emailValue);
		} else {
			this.emails.forEach((email) => {
				this.deleteEmail(email).then(() => {
					this.index -= 1;
					if (this.index == 0) {
						let emailValue = $("#sendTo").val();
						console.log("email in select", emailValue);
						emailValue = emailValue.filter(
							(a, index) => emailValue.indexOf(a) === index
						);
						this.saveEmailInDB(emailValue);
					}
				});
			});
		}
	}

	async deleteEmail(email) {
		return await this.accountPartnersService.deleteEmails(
			email.id,
			this.partnerId
		);
	}

	saveEmailInDB(emailValue) {
		this.emails = [];
		emailValue.forEach((email) => {
			console.log("Aggiunta email", email);
			this.accountPartnersService
				.saveEmails(this.partnerId, email)
				.then(() => {
					this.emails.push({
						id: email,
						text: email,
						selected: true,
					});
				});
		});
	}

	setSelectEmail() {
		$(".sendTo").select2({
			tags: true, // Allow custom values
			allowClear: true,
			theme: "classic",
			multiple: true,
			createTag: function (params) {
				const re =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var term = $.trim(params.term);

				if (!re.test(String(term).toLowerCase())) {
					return null;
				}

				return {
					id: term,
					text: term,
					newTag: true, // add additional parameters
				};
			},
		});
	}
}
