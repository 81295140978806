// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Components
import { BaseComponent } from "./views/theme/base/base.component";
import { ErrorPageComponent } from "./views/theme/content/error-page/error-page.component";
import { AnalysisComponent } from "./views/pages/analysis/analysis.component";
import { AuthGuard } from "./guards/auth.guard";
import { HomeComponent } from "./views/pages/home/home.component";
import { DetailsComponent } from "./views/pages/details/details.component";
import { FeedbackComponent } from "./views/pages/feedback/feedback.component";
import { PartnersComponent } from "./views/pages/partners/partners.component";
import { DocsComponent } from "./views/pages/partners/docs/docs.component";
import { DealsComponent } from "./views/pages/partners/deals/deals.component";
import { NewPartnersComponent } from "./views/pages/partners/new-partners/new-partners.component";
import { AccountDocsComponent } from "./views/pages/partners/new-partners/account-docs/account-docs.component";
import { AccountPartnerComponent } from "./views/pages/account-partner/account-partner.component";
import { CreateComponent } from "./views/pages/partners/create/create.component";
import { UnconfirmedComponent } from "./views/pages/unconfirmed/unconfirmed.component";

const routes: Routes = [
	{
		path: "auth",
		data: { breadcrumb: "Authentication" },
		loadChildren: () =>
			import("../app/views/pages/auth/auth.module").then(
				(m) => m.AuthModule
			),
	},
	{ path: "error/:type", component: ErrorPageComponent },
	{
		path: "",
		component: BaseComponent,
		canActivateChild: [AuthGuard],
		data: { breadcrumb: "Home", roles: [1, 2, 3, 4] },
		children: [
			{
				path: "settings",
				data: { breadcrumb: "Settings", roles: [1, 2] },
				loadChildren: () =>
					import("../app/views/settings/settings.module").then(
						(m) => m.SettingsModule
					),
			},
			{
				path: "new-partners",
				data: { breadcrumb: "New Partners", roles: [4] },
				component: NewPartnersComponent,
			},
			{
				path: "unconfirmed",
				data: { breadcrumb: "Unconfirmed", roles: [4] },
				component: UnconfirmedComponent,
			},
			{
				path: "partners",
				component: PartnersComponent,
				data: { breadcrumb: "Partners", roles: [1, 2] },
			},
			{
				path: "create",
				component: CreateComponent,
				data: { breadcrumb: "Create partner", roles: [3, 4] },
			},
			{
				path: "account-partner",
				component: AccountPartnerComponent,
				data: { breadcrumb: "Partners", roles: [3, 4] },
			},
			{
				path: "account-docs/:id",
				component: AccountDocsComponent,
				data: { breadcrumb: "Documents", roles: [3, 4] },
			},
			{
				path: "docs/:id",
				component: DocsComponent,
				data: { breadcrumb: "Documents", roles: [1, 2, 3, 4] },
			},
			{
				path: "deals/:id",
				component: DealsComponent,
				data: { breadcrumb: "Deals", roles: [1, 2] },
			},
			{
				path: "analysis",
				component: AnalysisComponent,
				data: { breadcrumb: "Analysis", roles: [2] },
			},
			{
				path: "details",
				component: HomeComponent,
				data: { breadcrumb: "Details", roles: [2] },
			},
			{
				path: "details/:id",
				component: DetailsComponent,
				data: { breadcrumb: "Details", roles: [2] },
			},
			{
				path: "feedback",
				component: FeedbackComponent,
				data: { breadcrumb: "Feedback", roles: [1, 2, 3, 4] },
			},
			{
				path: "error/403",
				component: ErrorPageComponent,
				data: {
					type: "error-v6",
					code: 403,
					title: "403... Access forbidden",
					desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
				},
			},
			{ path: "error/:type", component: ErrorPageComponent },
			// {path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
		],
	},

	// {path: '**', redirectTo: 'error/error-v2', pathMatch: 'full'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
