import { Component, Input, OnInit } from "@angular/core";
import { CountUp } from "countup.js";

@Component({
	selector: "kt-detail-charts",
	templateUrl: "./detail-charts.component.html",
	styleUrls: ["./detail-charts.component.scss"],
})
export class DetailChartsComponent implements OnInit {
	@Input() total = 0;
	@Input() totalPartners = 0;
	@Input() amountIn = 0;
	@Input() amountOut = 0;
	@Input() open = 0;
	@Input() inviato = 0;
	@Input() pagato = 0;
	@Input() inviato_parziale = 0;
	@Input() pagato_parziale = 0;
	@Input() posAnticipo = 0;
	@Input() negAnticipo = 0;
	@Input() totAnticipo = 0;
	@Input() volume = 0;
	margin;

	constructor() {}

	ngOnInit() {
		this.margin = this.amountIn - this.amountOut;

		//@ts-ignore
		var total = new JustGage({
			id: "total", // the id of the html element
			value: this.total,
			label: "Details",
			min: 0,
			max: this.total,
			decimals: 0,
			labelFontColor: "#000000",
			labelMinFontSize: "16",
			hideMinMax: true,
			gaugeWidthScale: 0.6,
			levelColors: ["#d31010d1", "#f5ee0ae3", "#097609d9"],
		});

		//@ts-ignore
		var open = new JustGage({
			id: "open", // the id of the html element
			value: this.open,
			label: "Open",
			min: 0,
			max: this.total,
			decimals: 0,
			labelFontColor: "#000000",
			labelMinFontSize: "16",
			hideMinMax: false,
			gaugeWidthScale: 0.6,
			pointer: true,
			levelColors: ["#d31010d1", "#f5ee0ae3", "#097609d9"],
		});

		//@ts-ignore
		var inviato = new JustGage({
			id: "inviato", // the id of the html element
			value: this.inviato,
			label: "Inviato",
			min: 0,
			max: this.total,
			decimals: 0,
			labelFontColor: "#000000",
			labelMinFontSize: "16",
			hideMinMax: false,
			gaugeWidthScale: 0.6,
			pointer: true,
			levelColors: ["#d31010d1", "#f5ee0ae3", "#097609d9"],
		});

		//@ts-ignore
		var pagato = new JustGage({
			id: "pagato", // the id of the html element
			value: this.pagato,
			label: "Pagato",
			min: 0,
			max: this.total,
			decimals: 0,
			labelFontColor: "#000000",
			labelMinFontSize: "16",
			hideMinMax: false,
			gaugeWidthScale: 0.6,
			pointer: true,
			levelColors: ["#d31010d1", "#f5ee0ae3", "#097609d9"],
		});

		//@ts-ignore
		var inviato_parziale = new JustGage({
			id: "inviato_parziale", // the id of the html element
			value: this.inviato_parziale,
			label: "Inviato Parziale",
			min: 0,
			max: this.totalPartners,
			decimals: 0,
			labelFontColor: "#000000",
			labelMinFontSize: "16",
			hideMinMax: false,
			gaugeWidthScale: 0.6,
			pointer: true,
			levelColors: ["#d31010d1", "#f5ee0ae3", "#097609d9"],
		});

		//@ts-ignore
		var pagato_parziale = new JustGage({
			id: "pagato_parziale", // the id of the html element
			value: this.pagato_parziale,
			label: "Pagato Parziale",
			min: 0,
			max: this.totalPartners,
			decimals: 0,
			labelFontColor: "#000000",
			labelMinFontSize: "16",
			hideMinMax: false,
			gaugeWidthScale: 0.6,
			pointer: true,
			levelColors: ["#d31010d1", "#f5ee0ae3", "#097609d9"],
		});

		var totAnticipo = new CountUp("totAnticipo", this.totAnticipo).start();
		var negAnticipo = new CountUp("negAnticipo", this.negAnticipo).start();
		var posAnticipo = new CountUp("posAnticipo", this.posAnticipo).start();
		var amountIn = new CountUp("amountIn", this.amountIn).start();
		var amountOut = new CountUp("amountOut", this.amountOut).start();
		var margin = new CountUp("margin", this.margin).start();
	}
}
