import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import * as $ from "jquery";
import "datatables.net-se";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DetailsListService } from "../../../services/details-list.service";
import { Router } from "@angular/router";

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
	dateForm: FormGroup;
	tableWidget: any;
	month = -1;
	year = -1;
	details = [];
	tempDetails;
	latestDate = { month: 6, year: 2020 };
	dates = [];
	tempResp;

	constructor(
		private router: Router,
		private fb: FormBuilder,
		private chRef: ChangeDetectorRef,
		private modalService: NgbModal,
		private detailsListsService: DetailsListService
	) {}

	updateIdAndStartMonth(
		startMonth,
		detailListId,
		status,
		showDashboard,
		showTable
	) {
		var startMonthDB = `${startMonth.split("-")[1]}-${
			startMonth.split("-")[0]
		}-01`;
		this.detailsListsService.showDashboard = showDashboard;
		this.detailsListsService.showTable = showTable;
		localStorage.removeItem("startMonth");
		localStorage.setItem("startMonth", startMonthDB);

		localStorage.removeItem("status");
		localStorage.setItem("status", status);
	}

	async closeAndCreateDetail() {
		this.modalService.dismissAll();
		let startMonth = `${this.dateForm.value.date.year}-${
			this.dateForm.value.date.month < 10
				? "0" + this.dateForm.value.date.month
				: this.dateForm.value.date.month
		}-01`;
		// console.log('Generated month ', startMonth)

		await this.detailsListsService
			.createDetailList(startMonth)
			.then((res) => {
				this.tempResp = res.data;
				this.tempResp =
					this.tempResp.createPartnerDetaillist.partnerDetaillist.id;
				localStorage.removeItem("startMonth");
				localStorage.setItem("startMonth", startMonth);
				localStorage.setItem("status", "open");

				window.location.replace(`details/${+this.tempResp}`);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	openDate(dateContent) {
		if (this.dates.length > 0) {
			this.latestDate.year = +this.dates[0].split("-")[0];
			this.latestDate.month = +this.dates[0].split("-")[1];
			this.dateForm = this.fb.group({
				date: {
					year:
						this.latestDate.month === 12
							? this.latestDate.year + 1
							: this.latestDate.year,
					month:
						this.latestDate.month === 12
							? 1
							: this.latestDate.month + 1,
				},
			});
		} else {
			var d = new Date();
			this.latestDate.year = d.getFullYear();
			this.latestDate.month = d.getMonth();
			this.dateForm = this.fb.group({
				date: {
					year: this.latestDate.year,
					month: this.latestDate.month,
				},
			});
		}

		this.modalService.open(dateContent, { centered: true });
	}

	async ngOnInit() {
		await this.detailsListsService
			.getDetailLists()
			.then((res) => {
				this.tempDetails = res;
				this.tempDetails =
					this.tempDetails.data.allPartnerDetaillists.nodes;

				for (const detail of this.tempDetails) {
					var date = detail.startMonth.split("-");

					this.dates.push(detail.startMonth);

					this.details.push({
						id: detail.id,
						status: detail.status,
						startMonth: `${date[1]}-${date[0]}`,
						date: detail.startMonth,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});

		//this.dates.sort((a, b) => a - b)
		this.dates.sort(function (a, b) {
			// Turn your strings into dates, and then subtract them
			// to get a value that is either negative, positive, or zero.
			return +new Date(b) - +new Date(a);
		});

		this.chRef.detectChanges();
		let partnersTableId: any = $("#detailList");

		$(document).ready(function () {
			var tableWidget1 = partnersTableId.DataTable({
				order: [[1, "desc"]],
				lengthChange: false,
				columnDefs: [
					{ className: "text-md-center", width: "13%", targets: 3 },
				],
			});

			$(".dataTables_filter").hide();
			$("#searchbox").keyup(function () {
				// @ts-ignore
				tableWidget1.search(this.value).draw();
			});
		});
	}

	ngOnDestroy() {
		this.chRef.detach(); // do this
	}
}
