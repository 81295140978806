import {
	Component,
	OnInit,
	ChangeDetectorRef,
	ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { AccountPartnersService } from "../../../../app/services/account-partners.service";

@Component({
	selector: "kt-account-partner",
	templateUrl: "./account-partner.component.html",
	styleUrls: ["./account-partner.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AccountPartnerComponent implements OnInit {
	whichGroup = "";
	myPartners = [];
	wipPartners = [];
	partnerTemp;
	wipBadge = 0;
	myPartnersBadge = 0;
	isAccountManager = false; // SHOULD BE TRUE TO ENTER

	constructor(
		private router: Router,
		private accounPartnersService: AccountPartnersService,
		private chRef: ChangeDetectorRef
	) {
		this.whichGroup = this.router.url.split("/")[2];

		this.isAccountManager = false;
	}

	async ngOnInit() {
		this.wipBadge = this.accounPartnersService.wipLength;
		this.myPartnersBadge = this.accounPartnersService.partnersLength;

		this.chRef.detectChanges();
	}

	setWIPBadge(wipNr) {
		if (wipNr >= 0) {
			this.wipBadge = wipNr;
		} else {
			// console.log('No wip received')
		}
	}

	setMyPartnersBadge(myPartnersNr) {
		if (myPartnersNr >= 0) {
			this.myPartnersBadge = myPartnersNr;
		} else {
			// console.log('No my partners received')
		}
	}

	ngOnDestroy() {
		this.chRef.detach();
	}
}
