import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	AfterViewInit,
	ChangeDetectorRef,
} from "@angular/core";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-se";
import * as moment from "moment"; // add this 1 of 4
import Swal from "sweetalert2";

import { ActivatedRoute, Router } from "@angular/router";
import { PartnersService } from "../../../../../app/services/partners.service";

import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { SettingsService } from "../../../../../app/services/settings.service";
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from "@angular/forms";

export interface DealData {
	id: string;
	name: string;
	campaign: string;
	brand: string;
	listing: string;
	startMonth: string;
	isActive: boolean;
	date: string;
}

export interface DetailData {
	id: string;
	campaign: string;
	startMonth: string;
	amountOut: string;
	date: string;
}

@Component({
	selector: "kt-deals",
	templateUrl: "./deals.component.html",
	styleUrls: ["./deals.component.scss"],
})
export class DealsComponent implements OnInit {
	// DEALS INFO
	dealsColumns: string[] = [
		"nr",
		"campaign",
		"brand",
		"listing",
		"runner",
		"startMonth",
		"isActive",
		"actions",
	];
	dealsSource: MatTableDataSource<DealData>;

	@ViewChild("dealsPaginator", { static: true }) dealsPaginator: MatPaginator;
	@ViewChild("sortDeals", { static: true }) sortDeals: MatSort;

	// DETAILS INFO
	detailsColumns: string[] = [
		"nr",
		"campaign",
		"startMonth",
		"amountOut",
		"actions",
	];
	detailsSource: MatTableDataSource<DetailData>;

	@ViewChild("detailsPaginator", { static: true })
	detailsPaginator: MatPaginator;
	@ViewChild("sortDetails", { static: true }) sortDetails: MatSort;

	partnerId: number; // Partner Id
	from: string; // Where it came from, so where it should return on Back
	partner; // Object with partner info
	partnerTemp;
	deals = [];
	details = [];
	selectedBrand: number;
	selectedCampaign: number;
	selectedListing: number;
	dateForm: any;
	d = new Date();

	latestDate = {
		year: this.d.getFullYear(),
		month: this.d.getMonth() + 1,
	};
	brands = [];
	campaigns = [];
	campaignsForBrand = [];
	listings = [];

	lowestDate = "2300-12-12";
	runnerForm: FormGroup;
	reversedRunners = [];
	deal;

	constructor(
		private fb: FormBuilder,
		private settingsService: SettingsService,
		public dialog: MatDialog,
		private route: ActivatedRoute,
		private router: Router,
		private partnersService: PartnersService,
		private chRef: ChangeDetectorRef
	) {
		this.partnerId = this.route.snapshot.params.id;
		this.partner = {};

		if (!(this.partnerId > 0)) {
			console.log("Wrong id");
			this.router.navigateByUrl("/error/error-v2");
		}

		// CHECK IF PARTNER IS CONFIRMED OR NOT BEFORE PROCEEDING
		// let found = false;
		// for (const partner of this.partnersService.confirmedPartners) {
		//   if()
		// }
	}

	async ngOnInit() {
		// If already here dont call from DB
		if (!!this.partnersService.allPartners[this.partnerId]) {
			//console.log('Got from service')
			this.partner = this.partnersService.allPartners[this.partnerId];
		} else {
			this.partner = await this.partnersService.getPartnerInfoFromDB(
				this.partnerId
			);
			// console.log('Got from DB', this.partner)
		}

		// STEP 3 and 4
		if (!!this.partner.partnerContractByPartnerId) {
			this.deals = this.partnersService.getDeals(
				this.partner.partnerContractByPartnerId
			);
		} else {
			console.log("CONTRACT UNDEFINED");
		}

		for (const deal of this.deals) {
			let amOut = 0.0;
			if (
				!!deal.partnerDetailsByDealId &&
				deal.partnerDetailsByDealId.nodes.length > 0
			) {
				for (const detail of deal.partnerDetailsByDealId.nodes) {
					amOut += parseFloat(detail.amountOut);
				}
				// console.log(amOut)
				this.details.push({
					id: deal.id,
					campaign: deal.campaign,
					startMonth: deal.startMonth,
					date: this.partnersService.parseItalianDate(
						deal.startMonth
					),
					amountOut: amOut.toFixed(2).replace(".", ","),
				});
			}
		}

		this.brands = await this.settingsService.getAllBrandsFromDB();
		this.campaigns = await this.settingsService.getAllCampaignsFromDB();
		this.listings = await this.settingsService.getAllListinosFromDB();

		let filteredListings = [];

		for (const listing of this.listings) {
			if (listing.deleted === false) {
				filteredListings.push(listing);
			}
		}

		this.listings = filteredListings;

		this.initDealTable();
		this.initDetailsTable();
	}

	openRunnerContent(runnersContent, deal) {
		this.deal = deal;
		this.reversedRunners = this.deal.runnersByDealId.reverse();
		this.runnerForm = this.fb.group({
			amount: [
				"",
				[
					Validators.required,
					Validators.min(1),
					Validators.pattern(/^[0-9]*$/),
				],
			],
			from: [null, [Validators.required]],
			to: [null, [Validators.required]],
		});

		this.dialog.open(runnersContent, { minWidth: "450px" });

		$(document).ready(() => {
			let runnersTable = $("#oldRunners");
			//@ts-ignore
			var tableWidget1 = runnersTable.DataTable({
				lengthChange: false,
				order: [[2, "desc"]],
			});
		});
	}

	async addRunner() {
		if (this.runnerForm.invalid) {
			console.log("Fomr invalid");
			return;
		}

		if (
			this.reversedRunners.length > 0 &&
			moment(this.runnerForm.value.from).isBefore(
				moment(this.reversedRunners[0].endDate)
			)
		) {
			Swal.fire(
				"Oops...",
				"Start date is before the ending date of last runner!",
				"error"
			);
			return;
		}

		if (
			moment(this.runnerForm.value.to).isBefore(
				moment(this.runnerForm.value.from)
			)
		) {
			Swal.fire("Oops...", "End date is before start date!", "error");
			return;
		}

		console.log(this.runnerForm.value);

		var start = moment(this.runnerForm.value.from).format("YYYY-MM-DD");
		var end = moment(this.runnerForm.value.to).format("YYYY-MM-DD");

		let deleted = false;

		// Deactivate last runner if exists
		if (this.deal.runnersLength > 0) {
			await this.partnersService
				.deactivateRunner(
					this.deal.runnersByDealId[this.deal.runnersLength - 1].id
				)
				.then((res) => {
					deleted = true;
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			deleted = true;
		}

		if (deleted) {
			await this.partnersService
				.createRunner(
					this.deal.id,
					this.runnerForm.value.amount,
					start,
					end
				)
				.then((res) => {
					window.location.reload();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	initDealTable() {
		this.dealsSource = new MatTableDataSource(this.deals);
		this.dealsSource.paginator = this.dealsPaginator;
		this.dealsSource.sort = this.sortDeals;
		this.dealsSource.sortingDataAccessor = (item, property) => {
			switch (property) {
				case "startMonth":
					return new Date(item.date);
				default:
					return item[property];
			}
		};
	}

	initDetailsTable() {
		this.detailsSource = new MatTableDataSource(this.details);
		this.detailsSource.paginator = this.detailsPaginator;
		this.detailsSource.sort = this.sortDetails;
		this.detailsSource.sortingDataAccessor = (item, property) => {
			switch (property) {
				case "startMonth":
					return new Date(item.date);
				default:
					return item[property];
			}
		};
	}

	async deactivateDealById(dealId) {
		var toggled = await this.toggleStatusInDB(dealId);

		if (!toggled) {
			console.log("Aborting, sth went wrong");
			return;
		}

		for (const deal of this.partnersService.allPartners[this.partnerId]
			.partnerContractByPartnerId.partnerDealsByContractId.nodes) {
			if (deal.id === dealId) {
				console.log("Deactivated deal");
				deal.isActive = false;
			}
		}

		for (const deal of this.deals) {
			if (deal.id === dealId) {
				deal.isActive = false;
			}
		}

		this.dealsSource.data = this.deals;
	}

	openNewDealModal(newContent) {
		this.dialog.open(newContent);
		this.selectedBrand = 0;
		this.selectedCampaign = 0;
		this.selectedListing = 0;
		this.dateForm = this.fb.group({
			date: { year: this.latestDate.year, month: this.latestDate.month },
		});
		console.log("Lattest date ", this.latestDate);
	}

	async addDeal() {
		console.log("Adding deal");
		// GET START MONTH AND VALIDATE
		const startMonth = `${this.dateForm.value.date.year}-${
			this.dateForm.value.date.month < 10
				? "0" + this.dateForm.value.date.month
				: this.dateForm.value.date.month
		}-01`;
		if (
			this.selectedCampaign === 0 ||
			this.selectedBrand === 0 ||
			this.selectedListing === 0 ||
			startMonth.length !== 10
		) {
			console.log("Invalid inputs");
			return;
		}

		// CHECK IF DEAL EXISTS
		for (const deal of this.deals) {
			if (
				deal.date === startMonth &&
				deal.campaignId === this.selectedCampaign &&
				deal.brandId === this.selectedBrand &&
				deal.listingId === this.selectedListing
			) {
				console.log("Deal already exists. Aborting");
				this.dialog.closeAll();
				return;
			}
		}

		let bName = "",
			cName = "",
			lName = "";

		//FIND BRAND NAME
		for (const brand of this.brands) {
			if (this.selectedBrand === brand.id) {
				bName = brand.name;
				break;
			}
		}

		//FIND CAMPAIGN NAME
		for (const campaign of this.campaigns) {
			if (this.selectedCampaign === campaign.id) {
				cName = campaign.name;
				break;
			}
		}

		//FIND LISTING NAME
		for (const listing of this.listings) {
			if (this.selectedListing === listing.id) {
				lName = listing.name;
				break;
			}
		}

		const newDeal = {
			brandId: this.selectedBrand,
			brand: bName,
			campaignId: this.selectedCampaign,
			campaign: cName,
			listingId: this.selectedListing,
			listing: lName,
			date: startMonth,
			startMonth: this.partnersService.parseItalianDate(startMonth),
			isActive: true,
			id: -1,
			uploaded: false,
			contractId: this.partner.contractId,
		};

		if (this.partner.contractId === -1) {
			console.log("PARTNER HAS NO CONTRACT, ABORTING");
			return;
		}

		const dealId = await this.partnersService.addDealToDB(newDeal);

		if (dealId === -1) {
			console.log("Couldnt add to db, aborting");
			this.dialog.closeAll();
			return;
		}

		newDeal.id = dealId;

		this.deals.push(newDeal);
		this.partnersService.allPartners[
			this.partnerId
		].partnerContractByPartnerId.partnerDealsByContractId.nodes.push(
			newDeal
		);

		this.dealsSource.data = this.deals;
		console.log("Deal added successfully");
		this.dialog.closeAll();
	}

	async toggleStatusInDB(dealId) {
		let deactivated = false;

		await this.partnersService
			.deactivateDeal(dealId)
			.then((res) => {
				deactivated = true;
			})
			.catch((err) => {
				console.log(err);
			});

		return deactivated;
	}

	changedBrand() {
		// console.log('Changed brand ', this.selectedBrand)
		this.campaignsForBrand = [];
		this.selectedCampaign = 0;
		for (const campaign of this.campaigns) {
			if (campaign.brandId === this.selectedBrand) {
				this.campaignsForBrand.push(campaign);
			}
		}
	}

	// HELPERS
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dealsSource.filter = filterValue.trim().toLowerCase();

		if (this.dealsSource.paginator) {
			this.dealsSource.paginator.firstPage();
		}
	}

	// HELPERS
	applyFilterDetails(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.detailsSource.filter = filterValue.trim().toLowerCase();

		if (this.detailsSource.paginator) {
			this.detailsSource.paginator.firstPage();
		}
	}

	refreshDealTable() {}

	ngOnDestroy() {
		this.chRef.detach(); // do this
	}
}
