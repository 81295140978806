export class PageConfig {
	public defaults: any = {
		dashboard: {
			page: {
				title: 'Dashboard',
				desc: 'Latest updates and statistic charts'
			},
		},
		'new-partners': {
			page: { title: 'New Partners', desc: 'New Partners'}
		},
		'partners': {
			page: { title: 'Partners', desc: 'Partners'}
		},
		'account-partners': {
			page: { title: 'Partners', desc: 'Partners'}
		},
		'analysis': {
			page: { title: 'Analysis', desc: 'Analysis'}
		},
		'details': {
			page: { title: 'Details', desc: 'Details'}
		},
		'feedback': {
			page: { title: 'Feedback', desc: 'Feedback'}
		},
		header: {
			actions: {
				page: {title: 'Actions', desc: 'Actions example page'}
			}
		},
		profile: {
			page: {title: 'User Profile', desc: ''}
		},
		error: {
			404: {
				page: {title: '404 Not Found', desc: '', subheader: false}
			},
			403: {
				page: {title: '403 Access Forbidden', desc: '', subheader: false}
			}
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
