// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
// Partials
import { PartialsModule } from "../partials/partials.module";
// Pages
import { CoreModule } from "../../core/core.module";
import { AnalysisComponent } from "./analysis/analysis.component";
import { MonthDatePickerComponent } from "./month-date-picker/month-date-picker.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HomeComponent } from "./home/home.component";
import { GraphQLModule } from "../../graphql.module";
import { RouterModule } from "@angular/router";
import { DetailsComponent } from "./details/details.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { PartnersComponent } from "./partners/partners.component";
import { WipComponent } from "./partners/wip/wip.component";
import {
	MatBadgeModule,
	MatSelectModule,
	MatFormFieldModule,
	MatExpansionModule,
	MatListModule,
	MatNativeDateModule,
	MatSlideToggleModule,
} from "@angular/material";
import { DocsComponent } from "./partners/docs/docs.component";

import {
	MatInputModule,
	MatCheckboxModule,
	MatIconModule,
	MatSortModule,
	MatPaginatorModule,
	MatButtonModule,
	MatTooltipModule,
	MatTableModule,
	MatDialogModule,
	MatTabsModule,
} from "@angular/material";
import { DealsComponent } from "./partners/deals/deals.component";
import { NewPartnersComponent } from "./partners/new-partners/new-partners.component";
import { AccountDocsComponent } from "./partners/new-partners/account-docs/account-docs.component";
import { AccountPartnerComponent } from "./account-partner/account-partner.component";
import { AccountWipComponent } from "./account-partner/account-wip/account-wip.component";
import { AccountMypartnersComponent } from "./account-partner/account-mypartners/account-mypartners.component";
import { CreateComponent } from "./partners/create/create.component";
import { MatDatepickerModule } from "@angular/material";
import { HotTableModule } from "@handsontable/angular";
import { DetailChartsComponent } from "./details/detail-charts/detail-charts.component";
import { UnconfirmedComponent } from "./unconfirmed/unconfirmed.component";
import { MatRadioModule } from "@angular/material/radio";

@NgModule({
	declarations: [
		AnalysisComponent,
		MonthDatePickerComponent,
		HomeComponent,
		DetailsComponent,
		FeedbackComponent,
		PartnersComponent,
		WipComponent,
		DocsComponent,
		DealsComponent,
		NewPartnersComponent,
		AccountDocsComponent,
		AccountPartnerComponent,
		AccountWipComponent,
		AccountMypartnersComponent,
		CreateComponent,
		DetailChartsComponent,
		UnconfirmedComponent,
	],
	exports: [],
	imports: [
		CommonModule,
		MatRadioModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		CoreModule,
		PartialsModule,
		NgbModule,
		RouterModule,
		GraphQLModule,
		MatBadgeModule,
		MatInputModule,
		MatButtonModule,
		MatTooltipModule,
		MatDialogModule,
		MatTabsModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatIconModule,
		MatSelectModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatExpansionModule,
		MatListModule,
		MatDatepickerModule,
		MatNativeDateModule,
		HotTableModule,
		MatSlideToggleModule,
	],
	providers: [],
})
export class PagesModule {}
