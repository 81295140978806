import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.open("https://obuclabs.canny.io/anagrafica", "_blank");
  }

}
