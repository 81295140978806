// Angular
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, TemplateRef } from '@angular/core';
import {
	NavigationCancel,
	NavigationEnd,
	NavigationStart,
	RouteConfigLoadEnd,
	RouteConfigLoadStart,
	Router
} from '@angular/router';
// Object-Path
import * as objectPath from 'object-path';
// Loading bar
import { LoadingBarService } from '@ngx-loading-bar/core';
// Layout
import { LayoutConfigService, LayoutRefService } from '../../../core/_base/layout';
// HTML Class Service
import { HtmlClassService } from '../html-class.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Component({
	selector: 'kt-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
	// Public properties
	menuHeaderDisplay: boolean;
	fluid: boolean;

	timeWhenExpires: any
	timer: any

	minutes: any
	seconds: any

	color = 'green'

	dialogConfig

	@ViewChild('ktHeader', {static: true}) ktHeader: ElementRef;
	@ViewChild('sessionExpired', {static: true}) sessionExpired: TemplateRef<any>;
	// @ViewChild('sessionReadyToExpire', {static: true}) sessionReadyToExpire: TemplateRef<any>;


	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param layoutRefService: LayoutRefService
	 * @param layoutConfigService: LayoutConfigService
	 * @param loader: LoadingBarService
	 * @param htmlClassService: HtmlClassService
	 */
	constructor(
		private router: Router,
		private layoutRefService: LayoutRefService,
		private layoutConfigService: LayoutConfigService,
		public loader: LoadingBarService,
		public htmlClassService: HtmlClassService,
		private chRef: ChangeDetectorRef,
		private dialog: MatDialog
	) {
		// page progress bar percentage
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				// set page progress bar loading to start on NavigationStart event router
				this.loader.start();
			}
			if (event instanceof RouteConfigLoadStart) {
				this.loader.increment(35);
			}
			if (event instanceof RouteConfigLoadEnd) {
				this.loader.increment(75);
			}
			if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
				// set page progress bar loading to end on NavigationEnd event router
				this.loader.complete();
			}
		});

		this.dialogConfig = new MatDialogConfig()
		this.dialogConfig.disableClose = true;
   		this.dialogConfig.autoFocus = true;
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();

		// get menu header display option
		this.menuHeaderDisplay = objectPath.get(config, 'header.menu.self.display');

		// header width fluid
		this.fluid = objectPath.get(config, 'header.self.width') === 'fluid';

		// animate the header minimize the height on scroll down
		if (objectPath.get(config, 'header.self.fixed.desktop.enabled') || objectPath.get(config, 'header.self.fixed.desktop')) {
			// header minimize on scroll down
			this.ktHeader.nativeElement.setAttribute('data-ktheader-minimize', '1');
		}

		// this.resetTimer()
	}

	// resetTimer(){
	// 	const rawToken = localStorage.getItem('anagraficaToken')
	// 	if(!rawToken){
	// 		this.router.navigateByUrl('/auth/login')
	// 		return
	// 	}
	// 	const helper = new JwtHelperService()
	// 	const decodedToken = helper.decodeToken(rawToken);
	// 	// console.log(decodedToken)

	// 	this.timeWhenExpires = new Date(decodedToken.exp * 1000) 
	// 	const timeNow = new Date()

	// 	let difference = this.timeWhenExpires.getTime() - timeNow.getTime()
	// 	this.timer = Math.round(difference / 1000); // TIME REMAINING IN SECONDS

	// 	let interval = setInterval( () => {
	// 		this.minutes = Math.floor(this.timer / 60)
	// 		this.seconds = this.timer % 60
	// 		if(this.minutes > 2 && this.minutes < 8){
	// 			this.color = 'blue'
	// 		}
	// 		if(this.minutes < 3){
	// 			this.color = 'red'
	// 		}

	// 		// if(this.timer === 180){
	// 		// 	this.dialog.open(this.sessionReadyToExpire)
	// 		// }

	// 		if(this.minutes <= 0 && this.seconds <= 0){
	// 			clearInterval(interval)
	// 			console.log('Interval cleared, redirecting to LOGIN PAGE')
	// 			this.dialog.open(this.sessionExpired, this.dialogConfig)				
	// 		}
	// 		if(this.minutes < 10){
	// 			this.minutes = '0'+this.minutes
	// 		}
	// 		if(this.seconds < 10){
	// 			this.seconds = '0'+this.seconds
	// 		}
	// 		this.timer--
	// 		this.chRef.markForCheck()
	// 	},1000)
	// }

	ngAfterViewInit(): void {
		// keep header element in the service
		this.layoutRefService.addElement('header', this.ktHeader.nativeElement);
	}

	redirect(){
		this.router.navigateByUrl('/auth/login')
		this.dialog.closeAll()
	}

	ngOnDestroy(){
		this.chRef.detach()
	}
}
