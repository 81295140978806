import { Injectable } from "@angular/core";
import { isNumber } from "util";
import * as Handsontable from "handsontable";

import * as $ from "jquery";
import "datatables.net";
import "datatables.net-se";

@Injectable({
	providedIn: "root",
})
export class HelpersService {
	constructor() {}

	// Group the array by key
	groupBy = (array, key) => {
		// Return the end result
		return array.reduce((result, currentValue) => {
			// If an array already present for key, push it to the array. Else create an array and push the object
			(result[currentValue[key]] = result[currentValue[key]] || []).push(
				currentValue
			);
			// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
			return result;
		}, {}); // empty object is the initial value for result object
	};

	destroyDatatables() {
		// ADD ALL DATATABLES HERE
		var tableNames = [
			"#alldetails",
			"#brands",
			"#partners",
			"#brandsall",
			"#partnersall",
		];

		for (const tableN of tableNames) {
			// @ts-ignore
			if ($.fn.DataTable.isDataTable(tableN)) {
				// @ts-ignore
				$(tableN).DataTable().clear().destroy();
			}
		}
	}

	printStatus(status) {
		if (status === "open") {
			return "Open";
		} else if (status === "pagato") {
			return "Pagato";
		} else if (status === "pagato_parziale") {
			return "Pagato Parziale";
		} else if (status === "inviato") {
			return "Inviato";
		} else if (status === "inviato_parziale") {
			return "Inviato Parziale";
		} else if (status === "open") {
			return "Open";
		} else {
			return status;
		}
	}

	generateDetailForRow(pId, dId, amOut, amIn, url, vol) {
		let valid = true;
		let partnerId = pId;
		let dealId = dId;
		let amountIn = amIn;
		let amountOut = amOut;
		let anticipo = "0.0";
		let googleSheetLink = url;
		let volume = vol;

		if (
			!isNumber(pId) ||
			!isNumber(dId) ||
			!isNumber(amOut) ||
			!!!url ||
			!isNumber(vol)
		) {
			valid = false;
		}

		if (
			/^-?\d+\.?\d*$/.test(partnerId) === false ||
			/^-?\d+\.?\d*$/.test(dealId) === false ||
			/^-?\d+\.?\d*$/.test(amountIn) === false ||
			/^-?\d+\.?\d*$/.test(amountOut) === false ||
			/^-?\d+\.?\d*$/.test(volume) === false
		) {
			console.log("Invalid regex");

			valid = false;
		}

		if (valid) {
			amountIn = amountIn.toFixed(2);
			amountOut = amountOut.toFixed(2);
			volume = volume.toFixed(2);
			return {
				valid: true,
				partnerId: partnerId,
				dealId: dealId,
				amountIn: amountIn,
				amountOut: amountOut,
				googleSheetLink: googleSheetLink,
				anticipo: anticipo,
				volume: volume,
			};
		} else {
			return {
				valid: false,
			};
		}
	}

	reinitDetailsTable(tableName) {
		// @ts-ignore
		if ($.fn.DataTable.isDataTable(tableName)) {
			// @ts-ignore
			$(tableName).DataTable().clear().destroy();
		}

		let md = $(tableName);

		$(document).ready(function () {
			// @ts-ignore
			var tableWidget1 = md.DataTable({
				dom: '<"top"f>rt<"bottom"lip><"clear">',
				order: [
					[2, "desc"],
					[7, "asc"],
				],
				language: {
					emptyTable: "No details",
					search: "Search:",
				},

				lengthChange: false,
				// @ts-ignore
				fnRowCallback: function (
					nRow,
					aData,
					iDisplayIndex,
					iDisplayIndexFull
				) {
					const anticipo = +parseFloat(
						aData[6].replace(/[^\d.-]/g, "")
					).toFixed(2);
					if (anticipo > 1) {
						$("td", nRow).css(
							"background-color",
							"rgba(255, 0, 0, 0.3)"
						);
					} else if (anticipo < 0) {
						$("td", nRow).css(
							"background-color",
							"rgba(0, 255, 0, 0.3)"
						);
					}
				},
			});
		});
	}

	reinitTables(whichGroup) {
		let detailsTab = null;
		let index = 0;
		let anticipoIndex = 0;
		let statusIndex = 0;
		let order = "desc";

		if (whichGroup === "a") {
			detailsTab = $("#alldetails");
			index = 4;
			anticipoIndex = 8;
			statusIndex = 9;
			order = "desc";
		} else if (whichGroup === "b") {
			detailsTab = $("#brands");
			index = 0;
			anticipoIndex = 5;
			statusIndex = 6;
			order = "asc";
		} else if (whichGroup === "p") {
			detailsTab = $("#partners");
			index = 0;
			anticipoIndex = 5;
			order = "asc";
		} else if (whichGroup === "ba") {
			detailsTab = $("#brandsall");
			index = 2;
			anticipoIndex = 6;
			statusIndex = 7;
			order = "desc";
		} else if (whichGroup === "pa") {
			detailsTab = $("#partnersall");
			index = 2;
			anticipoIndex = 6;
			statusIndex = 7;
			order = "desc";
		}

		var tableWidget1 = detailsTab.DataTable({
			dom: '<"top"f>rt<"bottom"lip><"clear">',
			order:
				statusIndex !== 0
					? [
							[statusIndex, "asc"],
							[index, order],
					  ]
					: [[index, order]],
			lengthChange: false,
			language: {
				search: "SEARCH:",
			},
			fnRowCallback: function (
				nRow,
				aData,
				iDisplayIndex,
				iDisplayIndexFull
			) {
				const anticipo = +parseFloat(
					aData[anticipoIndex].replace(/[^\d.-]/g, "")
				).toFixed(2);
				if (anticipo > 0) {
					$("td", nRow).css(
						"background-color",
						"rgba(255, 0, 0, 0.3)"
					);
				} else if (anticipo < 0) {
					$("td", nRow).css(
						"background-color",
						"rgba(0, 255, 0, 0.3)"
					);
				}
			},
		});
	}

	initPrevTable() {
		// @ts-ignore
		if ($.fn.DataTable.isDataTable("#prevtable")) {
			// @ts-ignore
			$("#prevtable").DataTable().clear().destroy();
		}

		let prevT = $("#prevtable");

		$(document).ready(() => {
			// @ts-ignore
			var tableWidget1 = prevT.DataTable({
				dom: '<"top">rt<"bottom"lip><"clear">',
				lengthChange: false,
			});
		});
	}

	// Get all distinct emails for partner to send email to
	getAllEmails(emGen, emAmm, emBo) {
		var emails = [];

		// if (emGen !== "ska") {
		//   emails.push({
		//     id: emGen,
		//     text: emGen,
		//     selected: true,
		//   });
		// }

		if (emAmm !== emGen && emAmm !== "ska") {
			emails.push({
				id: emAmm,
				text: emAmm,
				selected: true,
			});
		}

		// if (emBo !== emGen && emBo !== emAmm && emBo !== "ska") {
		//   emails.push({
		//     id: emBo,
		//     text: emBo,
		//     selected: true,
		//   });
		// }

		return emails;
	}

	generateStatus(statuses) {
		//console.log(statuses)
		var hasPagato = false;
		var hasInviato = false;
		var hasOpen = false;

		for (const status of statuses) {
			if (status === "pagato") {
				hasPagato = true;
			} else if (status === "inviato") {
				hasInviato = true;
			} else if (status === "open") {
				hasOpen = true;
			} else {
				alert("There is a wrong status while generating statuses");
			}
		}

		if (hasPagato && !hasInviato && !hasOpen) {
			return "pagato";
		} else if (hasPagato && (hasInviato || hasOpen)) {
			return "pagato_parziale";
		} else if (!hasPagato && hasInviato && !hasOpen) {
			return "inviato";
		} else if (!hasPagato && hasInviato && hasOpen) {
			return "inviato_parziale";
		} else if (!hasPagato && !hasInviato && hasOpen) {
			return "open";
		} else {
			alert("again error in statuses");
		}
	}

	setByBrand(groupByBrand) {
		let detailsByBrand = [];

		var totBamIn = 0;
		var totBamOut = 0;
		var totBant = 0;
		var totVol = 0;
		for (const brand of Object.keys(groupByBrand)) {
			var amInB = 0;
			var amOutB = 0;
			var antB = 0;
			var volB = 0;
			var statuses = [];
			for (let i = 0; i < groupByBrand[brand].length; i++) {
				var det = groupByBrand[brand][i];

				statuses.push(det.status);

				if (det.dealId === 1254) {
					// console.log('Anticipo')
					antB += parseFloat(det.anticipo.replace(",", "."));
					totBant += parseFloat(det.anticipo.replace(",", "."));
				} else {
					// console.log('Non anticipo')
					amInB += parseFloat(det.amountIn.replace(",", "."));
					totBamIn += parseFloat(det.amountIn.replace(",", "."));
					amOutB += parseFloat(det.amountOut.replace(",", "."));
					totBamOut += parseFloat(det.amountOut.replace(",", "."));
					totVol += parseFloat(det.volume.replace(",", "."));
					volB += parseFloat(det.volume.replace(",", "."));
				}
			}

			const status = this.generateStatus(statuses);

			// console.log('brand ', brand, 'Ant ', antB, ' amin ', amInB, ' amout ', amOutB)
			detailsByBrand.push({
				brand: brand,
				amIn: amInB.toFixed(2).replace(".", ","),
				amOut: amOutB.toFixed(2).replace(".", ","),
				ant: antB.toFixed(2).replace(".", ","),
				volume: volB.toFixed(2).replace(".", ","),
				status: status,
				campaignId: det.campaingId,
				idPreBill: det.idPrebill,
			});
		}
		detailsByBrand.push({
			brand: "Total",
			amIn: totBamIn.toFixed(2).replace(".", ","),
			amOut: totBamOut.toFixed(2).replace(".", ","),
			ant: totBant.toFixed(2).replace(".", ","),
			volume: totVol.toFixed(2).replace(".", ","),
		});

		return detailsByBrand;
	}

	getCCmails() {
		return [
			{
				id: "raimondo.davino@nsone.it",
				text: "raimondo.davino@nsone.it",
				selected: true,
			},
			{
				id: "analisi@nsone.it",
				text: "analisi@nsone.it",
				selected: true,
			},
			{
				id: "reporting@wearefiber.com",
				text: "reporting@wearefiber.com",
				selected: true,
			},
		];
	}

	setByPartner(groupByPartner) {
		let detailsByPartner = [];

		var totBamIn = 0;
		var totBamOut = 0;
		var totBant = 0;
		var totVol = 0;
		var id = -1;
		for (const partner of Object.keys(groupByPartner)) {
			var amInB = 0;
			var amOutB = 0;
			var antB = 0;
			var volB = 0;
			var statuses = [];
			var ragione = "";
			let partnerId = 0;
			let idPrebill = null;
			let idCampaign = null;
			let mandataria = null;
			for (let i = 0; i < groupByPartner[partner].length; i++) {
				var det = groupByPartner[partner][i];
				ragione = det.ragioneSociale;
				id = det.dealId;
				idPrebill = det.idPrebill;
				partnerId = det.partnerId;
				idCampaign = det.campaingId;
				mandataria = det.mandataria;
				statuses.push(det.status);

				// console.log(det.anticipo)
				if (det.dealId === 1254) {
					// console.log('Anticipo')
					antB += parseFloat(
						det.amountPreInvio.toString().replace(",", ".")
					);
					totBant += parseFloat(det.anticipo.replace(",", "."));
				} else {
					// console.log('Non anticipo')
					amInB += parseFloat(det.amountIn.replace(",", "."));
					totBamIn += parseFloat(det.amountIn.replace(",", "."));
					amOutB += parseFloat(det.amountOut.replace(",", "."));
					totBamOut += parseFloat(det.amountOut.replace(",", "."));
					totVol += parseFloat(det.volume.replace(",", "."));
					volB += parseFloat(det.volume.replace(",", "."));
				}
			}

			const status = this.generateStatus(statuses);

			// console.log('partner ', partner, 'Ant ', antB, ' amin ', amInB, ' amout ', amOutB)
			detailsByPartner.push({
				partnerName: partner,
				ragione: ragione,
				amIn: amInB.toFixed(2).replace(".", ","),
				amOut: amOutB.toFixed(2).replace(".", ","),
				ant: antB.toFixed(2).replace(".", ","),
				volume: volB.toFixed(2).replace(".", ","),
				status: status,
				id: id,
				campaingId: idCampaign,
				mandataria: mandataria,
				idPrebill: +idPrebill,
				partnerId: +partnerId,
			});
		}
		detailsByPartner.push({
			partner: "Total",
			amIn: totBamIn.toFixed(2).replace(".", ","),
			amOut: totBamOut.toFixed(2).replace(".", ","),
			ant: totBant.toFixed(2).replace(".", ","),
			volume: totVol.toFixed(2).replace(".", ","),
		});

		return detailsByPartner;
	}

	getHOTcolumns() {
		return [
			{
				data: "Partner Id",
				type: "numeric",
				placeholder: "Partner ID",
				allowEmpty: false,
				allowInvalid: false,
			},
			{
				data: "Partner Name",
				type: "text",
				placeholder: "Partner Name",
				allowEmpty: true,
				allowInvalid: false,
			},
			{
				data: "Deal Id",
				type: "numeric",
				placeholder: "Deal ID",
				allowEmpty: false,
				allowInvalid: false,
			},
			{
				data: "Tipo",
				type: "text",
				placeholder: "Tipo",
				allowEmpty: true,
				allowInvalid: true,
			},
			{
				data: "Importo",
				type: "numeric",
				placeholder: "Importo",
				allowEmpty: false,
				allowInvalid: false,
			},
			{
				data: "Eccezioni",
				type: "text",
				placeholder: "Eccezioni",
				allowEmpty: true,
				allowInvalid: true,
			},
			{
				data: "Fee",
				type: "text",
				placeholder: "Fee",
				allowEmpty: true,
				allowInvalid: true,
			},
			{
				data: "Runner",
				type: "text",
				placeholder: "Runner",
				allowEmpty: true,
				allowInvalid: true,
			},
			{
				data: "Totale partner",
				type: "text",
				placeholder: "Totale partner",
				allowEmpty: true,
				allowInvalid: true,
			},
			{
				data: "Totale payin",
				type: "numeric",
				placeholder: "Totale payin",
				allowEmpty: false,
				allowInvalid: false,
			},
			{
				data: "Link al file",
				type: "text",
				placeholder: "Link al file",
				validator: "emptyValidator",
				allowEmpty: false,
				allowInvalid: false,
			},
			{
				data: "Volume",
				type: "numeric",
				placeholder: "Volume",
				allowEmpty: false,
				allowInvalid: false,
			},
		];
	}

	getHOTheaders() {
		return [
			"Partner Id",
			"Partner Name",
			"Deal Id",
			"Tipo",
			"Importo",
			"Eccezioni",
			"Fee",
			"Runner",
			"Totale Partner",
			"Totale Payin",
			"Link al file",
			"Volume",
		];
	}

	getHOTsettings() {
		return {
			startRows: 1,
			columns: this.getHOTcolumns(),
			copyPaste: true,
			autoWrapRow: true,
			minRows: 1,
			height: 400,
			manualRowResize: false,
			maxCols: 12,
			manualColumnResize: false,
			autoColumnSize: true,
			rowHeaders: true,
			colHeaders: this.getHOTheaders(),
			contextMenu: true,
			allowInsertRow: true,
			filters: false,
			dropdownMenu: false,
			language: "en-US",
			placeholderCellClassName: "myPlaceHolder",
			invalidCellClassName: "invalidHotCell",
			licenseKey: "non-commercial-and-evaluation",
			placeholder: "PLACE",
			afterValidate: function (isValid, value, row, prop, source) {
				if (!isValid) {
					console.log("there exists an invalid cell, disable save");
				}
			},
			beforePaste: function (data, coords) {
				var cols = data[0].length - 1;
				var rows = data.length - 1;
				var startRow = coords[0].startRow;
				var startCol = coords[0].startCol;

				this.selectCell(
					startRow,
					startCol,
					startRow + rows,
					startCol + cols,
					false
				);
			},
		};
	}

	removeClasses() {
		$("#b").removeClass("active");
		$("#b").removeClass("focus");
		$("#a").removeClass("active");
		$("#a").removeClass("focus");
		$("#p").removeClass("active");
		$("#p").removeClass("focus");
	}
}

(function (Handsontable) {
	function customEmptyValidator(value, callback) {
		if (!!value && value.toString().length > 0) {
			callback(true);
		} else {
			callback(false);
		}
	}

	// Register an alias
	Handsontable.default.validators.registerValidator(
		"emptyValidator",
		customEmptyValidator
	);
})(Handsontable);
