import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { AuthenticationService } from "./authentication.service";

@Injectable({
	providedIn: "root",
})
export class SuperpowersService {
	temp;

	constructor(
		private http: HttpClient,
		private apollo: Apollo,
		private authService: AuthenticationService
	) {}

	async getPartners() {
		let partners = [];
		await this.authService.verifyToken();

		await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners {
							nodes {
								id
								status
								type
								deleted
								emailAmm
								emailGenerale
								nomeCognomeLegale
								ragioneSociale
								agentUserByAccountId {
									id
									username
									firstName
									lastName
								}
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise()
			.then((res) => {
				// console.log(res)
				this.temp = res;
				partners = this.temp.data.allPartnerPartners.nodes;
			})
			.catch((err) => {
				console.error(err);
			});

		return partners;
	}

	async changeStatus(partnerId, newStatus) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"3"
          id:${partnerId}
          partnerPartnerPatch:{
            status:"${newStatus}"
          }
        }){
          partnerPartner{
            status
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async getPartnerInfo(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(
          condition:{
            id: ${partnerId}
          }
        ){
          nodes{
            id
            nomeCognomeLegale
            ragioneSociale
            pivaCodiceFiscale
            telefonoGenerale
            emailGenerale
            emailAmm
            nomeCognomeAmm
            telefonoAmm
            nomeCognomeBo
            emailBo
            numeroUscita
            refEmail
            roc
            notes
            type
            status
            partnerTeamworksByPartnerId{
              nodes{
                name
              }
            }
            agentUserByAccountId{
              username
              email
            }
          }
        }
      }`,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}

	async updatePartner(partnerId, partnerData) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          id:${partnerId}
          clientMutationId:"4"
          partnerPartnerPatch:{
            nomeCognomeLegale:"${partnerData.nomeCognomeLegale}",
            telefonoGenerale:"${partnerData.telefonoGenerale}",
            emailGenerale:"${partnerData.emailGenerale}",
            pivaCodiceFiscale:"${partnerData.pivaCodiceFiscale}",
            ragioneSociale:"${partnerData.ragioneSociale}",
            numeroUscita:"${partnerData.numeroUscita}",
            emailAmm:"${partnerData.emailAmm}",
            nomeCognomeAmm:"${partnerData.nomeCognomeAmm}", 
            telefonoAmm:"${partnerData.telefonoAmm}",
            nomeCognomeBo:"${partnerData.nomeCognomeBo}",
            emailBo:"${partnerData.emailBo}",
            refEmail:"${partnerData.refEmail}",
            roc:"${partnerData.roc}",
            notes:"${partnerData.notes}",
            type:"${partnerData.type}",
          }
        }){
          partnerPartner{
            id
            nomeCognomeLegale
            ragioneSociale
            pivaCodiceFiscale
            telefonoGenerale
            emailGenerale
            emailAmm
            nomeCognomeAmm
            telefonoAmm
            nomeCognomeBo
            emailBo
            numeroUscita
            refEmail
            roc
            notes
            type
            status
            deleted
            agentUserByAccountId{
              id
              username
              firstName
              lastName
            }
          }
        }
      }
      `,
			})
			.toPromise();
	}
}
