import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(
    public router: Router,
    private authService: AuthenticationService
  ){ 

  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const hasToken = !!localStorage.getItem('anagraficaToken')

      // IF A TOKEN EXISTS
      if(hasToken){
        // console.log('Token exists, entering')
        const hasExpired = this.authService.hasTokenExpired() 
        if(!hasExpired){ // TOKEN HAS NOT EXPIRED
          // console.log('Token hasnt expired yet, check for isActive and permission')
          // IF IS ENABLED CHECK PERMISSIONS
          if(this.authService.isEnabled()){
            if(next.data.roles.includes(this.authService.getUserRole()) || (this.authService.isSuperUser() && next.data.breadcrumb === 'Settings') || (this.authService.isSuperUser() && next.data.breadcrumb === 'Superpowers') || (this.authService.isSuperUser() && next.data.breadcrumb === 'Create partner')){ // ACCESS TO VISIT
              // console.log('You have permission to access this page')
              return true
            } else { // NO ACCESS TO VISIT
              this.router.navigateByUrl('/error/error-v5')
              // console.log('You dont have permission to see this page')
              return false 
            }
          } else {
            // console.log('User is not enabled')
            this.router.navigateByUrl('/error/error-v6')
            return false
          }
          
        } else { // TOKEN EXPIRED
          // console.log('TOKEN EXPIRED, REDIRECTING TO AUTH/LOGIN')
          this.router.navigateByUrl('/auth/login')
          return false
        }
      } 
      // IF NO TOKEN AT ALL
      else {
        console.log('No token at all, redirecting to AUTH/LOGIN')
        this.router.navigateByUrl('/auth/login')
        return false
      }
    
  }
}
