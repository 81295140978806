import {
	Component,
	OnInit,
	EventEmitter,
	Output,
	ViewChild,
} from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import { AccountPartnersService } from "../../../../../app/services/account-partners.service";
import { AuthenticationService } from "../../../../../app/services/authentication.service";

export interface PartnerData {
	nr: string;
	status: string;
	type: string;
	emailAmm: string;
	ragioneSociale: string;
	account: string;
	accountConfirmationTimestamp: string;
	docs: any;
}

@Component({
	selector: "kt-account-mypartners",
	templateUrl: "./account-mypartners.component.html",
	styleUrls: ["./account-mypartners.component.scss"],
})
export class AccountMypartnersComponent implements OnInit {
	@Output() myPartnersNr = new EventEmitter<number>();

	partners = [];
	displayedColumns: string[] = [
		"nr",
		"status",
		"type",
		"emailAmm",
		"main_contractor",
		"nomeCognomeLegale",
		"ragioneSociale",
		"docs",
	];
	dataSource: MatTableDataSource<PartnerData>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private accountPartnerService: AccountPartnersService,
		private authService: AuthenticationService
	) {}

	isAccount = false;

	async ngOnInit() {
		this.isAccount = this.authService.getUserRole() === 3;
		this.partners = await this.accountPartnerService.getAllMyPartnersFromDB(
			true,
			true
		); // CHANGE

		this.myPartnersNr.emit(this.partners.length);
		this.initTable();
	}

	initTable() {
		this.dataSource = new MatTableDataSource(this.partners);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	// HELPERS
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	/**
	 * Returens item CSS Class Name by status
	 *
	 * @param status: string
	 */
	getStatus(status: string = "new"): string {
		switch (status) {
			case "new":
				return "danger";
			case "listino":
				return "brand";
			case "sending_documentation":
				return "info";
			case "docs_confirmed":
				return "primary";
			case "teamwork":
				return "warning";
			case "confirmed":
				return "success";
		}
		return "";
	}
}
