import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	AfterViewInit,
	ChangeDetectorRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { PartnersService } from "../../../../../../app/services/partners.service";
import { MatDialog } from "@angular/material/dialog";
import {
	FormControl,
	Validators,
	FormGroup,
	FormBuilder,
} from "@angular/forms";
import { AccountPartnersService } from "../../../../../../app/services/account-partners.service";
import { SettingsService } from "../../../../../../app/services/settings.service";
import Swal from "sweetalert2";
import { AuthenticationService } from "../../../../../../app/services/authentication.service";
import * as $ from "jquery";
import { filter } from "lodash";

export interface DealData {
	nr: string;
	campaign: string;
	brand: string;
	listing: string;
	startMonth: string;
	date: string;
	isActive: boolean;
}

@Component({
	selector: "kt-account-docs",
	templateUrl: "./account-docs.component.html",
	styleUrls: ["./account-docs.component.scss"],
})
export class AccountDocsComponent implements OnInit {
	@ViewChild("wizard", { static: true }) el: ElementRef;

	// NORMAL DEALS INFO
	displayedColumns: string[] = [
		"nr",
		"campaign",
		"brand",
		"listing",
		"mandataria",
		"startMonth",
		"isActive",
	];
	dataSource: MatTableDataSource<DealData>;

	@ViewChild("dealPaginator", { static: true }) paginator: MatPaginator;
	@ViewChild("sortDeal", { static: true }) sortDeal: MatSort;

	pattern =
		"^[a-zA-Z]{6}[0-9]{2}[a-zA-Z]{1}[0-9]{2}[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{1}$|^[0-9]{11}$|^[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}$";

	partnerStep = 1;
	googleChecked = false;
	domainFormControl: FormControl = new FormControl("", [Validators.required]);

	deals = [];
	step;
	nextStep;
	changedFromButton;
	partnerId;
	partner;
	partnerForm: FormGroup;
	wizard;
	businessUnit;
	emails = [];
	partnersMain;
	allPadri;
	submitted;

	brands = [];
	camapaigns = [];
	campaignsForBrand = [];
	listings = [];
	mandatarie = [];

	selectedBrand;
	selectedCampaign;
	selectedCampaigns;
	selectedListing;
	selectedMandataria;
	d = new Date();
	latestDate = {
		year: this.d.getFullYear(),
		month: this.d.getMonth() + 1,
	};
	dateForm: FormGroup;

	lowestDate = "2300-12-12";
	mailtoPartnerFormControl: FormControl; // Send email or not

	constructor(
		private authService: AuthenticationService,
		private settingsService: SettingsService,
		private fb: FormBuilder,
		public dialog: MatDialog,
		private accountPartnersService: AccountPartnersService,
		private route: ActivatedRoute,
		private router: Router,
		private partnersService: PartnersService,
		private chRef: ChangeDetectorRef,
		private settingService: SettingsService
	) {
		this.partnerId = +this.route.snapshot.params.id;
		// console.log(this.partnerId)
		this.partner = {};

		this.mailtoPartnerFormControl = new FormControl(false, []);

		if (!(this.partnerId > 0)) {
			console.log("Wrong id");
			this.router.navigateByUrl("/error/error-v2");
		}

		this.initNewPartnerStep();
	}

	async ngOnInit() {
		this.partner = await this.accountPartnersService.getPartnerInfoFromDB(
			this.partnerId
		);
		this.partnersMain =
			await this.accountPartnersService.getAllMainContractorFromDB();
		this.allPadri =
			await this.accountPartnersService.getPartnerPadreFromDB();
		this.setNewPartnerValues();
		this.initDealTable();

		if (this.partner.status !== "new") {
			console.log("Status not new, disabling form");
			this.partnerForm.disable();
		}

		this.partnerStep = this.getStepNr();
		this.initWizard();

		this.brands = await this.settingsService.getAllBrandsFromDB();
		this.businessUnit =
			await this.settingService.getAllBusinessUnitFromDB();
		this.camapaigns = await this.settingsService.getAllCampaignsFromDB();
		this.listings = await this.settingsService.getAllListinosFromDB();
		this.mandatarie = await this.settingsService.getAllMandatarieFromDB();
		this.emails = await this.accountPartnersService.getEmailPartnerFromDB(
			this.partnerId
		);
		$(".sendTo").select2({
			tags: true, // Allow custom values
			allowClear: true,
			theme: "classic",
			multiple: true,
			disabled: true,
			data: this.emails,
			createTag: function (params) {
				const re =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var term = $.trim(params.term);

				if (!re.test(String(term).toLowerCase())) {
					return null;
				}

				return {
					id: term,
					text: term,
					newTag: true, // add additional parameters
				};
			},
		});
	}

	getStepNr() {
		if (this.partner.status === "new") {
			return 1;
		} else if (this.partner.status === "listino") {
			return 2;
		} else if (this.partner.status === "sending_documentation") {
			return 3;
		} else if (this.partner.status === "docs_confirmed") {
			return 4;
		} else if (this.partner.status === "teamwork") {
			return 5;
		} else if (this.partner.status === "confirmed") {
			return 6;
		} else {
			return 3;
		}
	}

	initWizard(): void {
		// Initialize form wizard
		this.wizard = new KTWizard(this.el.nativeElement, {
			startStep: this.partnerStep,
		});

		const currentStep = this.wizard.getStep();
		// Validation before going to next page
		this.wizard.on("beforeNext", async (wizardObj) => {
			// IF ATTEMPTING TO CHANGE FROM WIZARD
			if (!this.changedFromButton) {
				if (this.nextStep === 2 && this.partnerForm.invalid) {
					console.log(
						"Prevented moving forward. Step uncomplete. Form invalid."
					);
					wizardObj.stop();
				}
				if (this.partner.status === "new") {
					wizardObj.stop();
				}
				// CHANGING FROM BUTTONS (SUBMITTING)
			} else {
				// VALIDATE ON BUTTON PRESS
				this.step = this.wizard.getStep();
				this.partnerForm.markAllAsTouched();
				if (
					this.step === 1 &&
					!this.partnerForm.valid &&
					this.partner.status === "new"
				) {
					console.log("Partner form is invalid");
					console.log(this.partnerForm);
					wizardObj.stop();
				} else if (this.step === 2) {
					console.log("Finishing account job");
				} else if (
					this.step === 1 &&
					this.partnerForm.valid &&
					this.partner.status === "new"
				) {
					console.log("Form is valid, proceeding");
					this.step = 2;
					const updated = await this.updatePartnerFromNew();
					if (!updated) {
						console.log("Could not update in DB, stopping wizard");
						wizardObj.stop();
					} else {
						console.log(
							"Updated in db, proceeding to update locally"
						);
						//DISABLE FORM
						if (this.authService.getUserRole() !== 4) {
							this.partnerForm.disable();
						}
					}
				} else {
					//
				}
			}
		});

		KTUtil.scrollTop();

		this.chRef.detectChanges();
	}

	async updatePartnerFromNew() {
		let partnerData = {
			nomeCognomeLegale: "",
			telefonoGenerale: "",
			emailGenerale: "",
			pivaCodiceFiscale: "",
			ragioneSociale: "",
			numeroUscita: "",
			emailAmm: "",
			nomeCognomeAmm: "",
			telefonoAmm: "",
			nomeCognomeBo: "",
			emailBo: "",
			refEmail: "",
			roc: "",
			notes: "",
			type: "",
		};

		partnerData.nomeCognomeLegale =
			this.partnerForm.get("nomeCognomeLegale").value;
		partnerData.telefonoGenerale =
			this.partnerForm.get("telefonoGenerale").value;
		partnerData.pivaCodiceFiscale =
			this.partnerForm.get("pivaCodiceFiscale").value;
		partnerData.ragioneSociale =
			this.partnerForm.get("ragioneSociale").value || "";
		partnerData.numeroUscita =
			this.partnerForm.get("numeroUscita").value || "";
		partnerData.emailAmm = this.partnerForm.get("emailAmm").value || "";
		partnerData.nomeCognomeAmm =
			this.partnerForm.get("nomeCognomeAmm").value || "";
		partnerData.telefonoAmm =
			this.partnerForm.get("telefonoAmm").value || "";
		partnerData.nomeCognomeBo =
			this.partnerForm.get("nomeCognomeBo").value || "";
		partnerData.emailBo = this.partnerForm.get("emailBo").value || "";
		partnerData.refEmail = this.partnerForm.get("refEmail").value || "";
		partnerData.roc = this.partnerForm.get("roc").value || "";
		partnerData.notes = this.partnerForm.get("notes").value || "";
		partnerData.type = this.partnerForm.get("type").value;

		this.partner.status = "listino";
		return await this.accountPartnersService.updatePartnerInDB(
			this.partnerId,
			partnerData
		);
	}

	initNewPartnerStep() {
		this.partnerForm = this.fb.group({
			nomeCognomeLegale: ["", [Validators.required]],
			telefonoGenerale: [
				"",
				[Validators.required, Validators.pattern("[- +()0-9]+")],
			],
			pivaCodiceFiscale: [
				"",
				[Validators.required, Validators.pattern(this.pattern)],
			],
			ragioneSociale: ["", []],
			numeroUscita: ["", []], // IS THIS REQUIRED?
			emailAmm: ["", []],
			nomeCognomeAmm: ["", []],
			telefonoAmm: ["", []],
			nomeCognomeBo: ["", []],
			emailBo: ["", []],
			refEmail: ["", []],
			roc: ["", []],
			notes: ["", []],
			padre: [null, []],
			isPadre: [false, []],
			businessUnit: ["", []],
			mainContractor: [null, []],
			isMainContractor: [false, []],
			type: ["", [Validators.required]],
		});
	}

	setNewPartnerValues() {
		console.log("SETTING VALUES ", this.partner);
		this.partnerForm.setValue({
			nomeCognomeLegale: this.partner.nomeCognomeLegale,
			telefonoGenerale: this.partner.telefonoGenerale,
			pivaCodiceFiscale: this.partner.pivaCodiceFiscale,
			ragioneSociale: this.partner.ragioneSociale,
			numeroUscita: this.partner.numeroUscita,
			emailAmm: this.partner.emailAmm,
			nomeCognomeAmm: this.partner.nomeCognomeAmm,
			telefonoAmm: this.partner.telefonoAmm,
			nomeCognomeBo: this.partner.nomeCognomeBo,
			emailBo: this.partner.emailBo,
			refEmail: this.partner.refEmail,
			roc: this.partner.roc,
			notes: this.partner.notes,
			businessUnit: this.partner.bu,
			type: this.partner.type,
			mainContractor: this.partner.idMainContractor
				? this.partnersMain.filter(
						(main) => main.id === this.partner.idMainContractor
				  )[0]
				: null,
			padre: this.partner.idPadre
				? this.allPadri.filter(
						(pa) => pa.id === this.partner.idPadre
				  )[0]
				: null,
			isPadre: this.partner.isPadre,
			isMainContractor: this.partner.isMainContractor,
		});
	}

	changeStep(nr) {
		this.changedFromButton = false;
		this.nextStep = nr;
	}

	setChangedFromButton() {
		this.changedFromButton = true;
	}

	initDealTable() {
		console.log("Deals before table ", this.partner.deals);
		this.deals = this.partner.deals;
		this.dataSource = new MatTableDataSource(this.partner.deals);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sortDeal;
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {
				case "startMonth":
					return new Date(item.date);
				default:
					return item[property];
			}
		};
	}

	checkboxChanged() {
		this.googleChecked = false;

		if (this.mailtoPartnerFormControl.value) {
			this.domainFormControl = new FormControl("", [Validators.required]);
		}
	}

	googleChanged() {
		this.googleChecked = !this.googleChecked;
	}

	changedBrand() {
		// console.log('Changed brand ', this.selectedBrand)
		this.campaignsForBrand = [];
		this.selectedCampaign = 0;
		this.selectedCampaigns = [];

		if (this.selectedBrand > 0) {
			for (const campaign of this.camapaigns) {
				if (campaign.brandId === this.selectedBrand) {
					this.campaignsForBrand.push(campaign);
				}
			}
		} else {
			for (const campaign of this.camapaigns) {
				this.campaignsForBrand.push(campaign);
			}
		}
	}

	openNewDealModal(newContent) {
		this.selectedBrand = 0;
		this.selectedCampaign = 0;
		this.selectedListing = 0;
		this.dateForm = this.fb.group({
			date: { year: this.latestDate.year, month: this.latestDate.month },
		});
		this.dialog.open(newContent);
	}

	openNewDealsModal(newsContent) {
		this.selectedBrand = 0;
		this.selectedCampaigns = [];
		this.selectedListing = 0;
		this.dateForm = this.fb.group({
			date: { year: this.latestDate.year, month: this.latestDate.month },
		});
		this.changedBrand();
		this.dialog.open(newsContent);
	}

	addDeal(closeModal) {
		console.log("Adding deal");
		if (this.selectedBrand === 0) {
			for (const campaign of this.camapaigns) {
				if (campaign.id === this.selectedCampaign) {
					this.selectedBrand = campaign.brandId;
				}
			}
		}

		// GET START MONTH AND VALIDATE
		const startMonth = `${this.dateForm.value.date.year}-${
			this.dateForm.value.date.month < 10
				? "0" + this.dateForm.value.date.month
				: this.dateForm.value.date.month
		}-01`;
		if (
			this.selectedCampaign === 0 ||
			this.selectedBrand === 0 ||
			this.selectedListing === 0 ||
			startMonth.length !== 10
		) {
			console.log("Invalid inputs");
			return;
		}

		// CHECK IF DEAL EXISTS
		for (const deal of this.partner.deals) {
			console.log("Looping");
			if (
				deal.date === startMonth &&
				deal.campaignId === this.selectedCampaign &&
				deal.brandId === this.selectedBrand &&
				deal.listingId === this.selectedListing
			) {
				console.log("Deal already exists. Aborting");
				this.dialog.closeAll();
				return;
			}
		}

		if (startMonth < this.lowestDate) {
			this.lowestDate = startMonth;
		}

		let bName = "";
		let cName = "";
		let lName = "";

		//FIND BRAND NAME
		for (const brand of this.brands) {
			if (this.selectedBrand === brand.id) {
				bName = brand.name;
				break;
			}
		}

		//FIND CAMPAIGN NAME
		for (const campaign of this.camapaigns) {
			if (this.selectedCampaign === campaign.id) {
				cName = campaign.name;
				break;
			}
		}

		//FIND LISTING NAME
		for (const listing of this.listings) {
			if (this.selectedListing === listing.id) {
				lName = listing.name;
				break;
			}
		}

		const newDeal = {
			brandId: this.selectedBrand,
			brand: bName,
			campaignId: this.selectedCampaign,
			campaign: cName,
			listingId: this.selectedListing,
			listing: lName,
			mandataria: this.selectedMandataria,
			date: startMonth,
			startMonth:
				this.accountPartnersService.parseItalianDate(startMonth),
			isActive: true,
			exitsInDb: false,
		};

		this.accountPartnersService.allPartners[this.partnerId].deals.push(
			newDeal
		);
		this.deals =
			this.accountPartnersService.allPartners[this.partnerId].deals;
		this.dataSource.data = this.deals;

		if (closeModal) {
			this.dialog.closeAll();
		}
	}

	addDeals() {
		if (this.selectedCampaigns.length === 0) {
			Swal.fire("No campaigns selected");
			return;
		}

		for (const campaign of this.selectedCampaigns) {
			this.selectedCampaign = campaign;
			this.selectedBrand = 0;
			this.addDeal(false);
		}

		this.dialog.closeAll();
	}

	async submitDeals() {
		if (this.deals.length === 0) {
			Swal.fire("No deals created, aborting");
			return;
		}

		if (
			this.mailtoPartnerFormControl.value &&
			this.domainFormControl.invalid
		) {
			Swal.fire("Domain not valid, aborting");
			return;
		}

		// console.log('Deals to be uploaded ', this.deals, ' Mail? ', this.mailtoPartnerFormControl.value, ' Type ', this.googleChecked, ' Domain ', this.domainFormControl.value)

		const contractId = await this.accountPartnersService.createContractInDB(
			this.partnerId,
			this.lowestDate,
			"2200-12-25"
		);

		const uploaded = await this.accountPartnersService.createDealsInDB(
			this.deals,
			contractId
		);

		if (!uploaded.includes(0)) {
			console.log("Deals uploaded successfully");

			const sendM = this.mailtoPartnerFormControl.value;
			let mailType = null;
			let domain = null;

			if (sendM) {
				mailType = this.googleChecked ? "gsuite" : "free";
				domain = this.domainFormControl.value;
			}

			// CHANGE STATUS AND REMOVE USER FROM W.I.P
			let statusChanged =
				await this.accountPartnersService.changeStatusToNotConfirmed(
					this.partnerId,
					mailType,
					domain
				);
			if (statusChanged) {
				this.router.navigateByUrl("/account-partner");
			} else {
				console.log("Could not update status");
			}
		} else {
			console.log("Could not upload deals");
		}
	}

	// HELPERS
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	// Get control to Form easily
	get p() {
		return this.partnerForm.controls;
	}

	refreshTable() {}

	setMandataria(selectedCampaign) {
		this.selectedCampaign = selectedCampaign;
		let campaign = this.camapaigns.filter(
			(c) => c.id === selectedCampaign
		)[0];
		this.selectedMandataria = this.mandatarie.filter(
			(mandataria) => campaign.mandataria === mandataria.name
		)[0].name;
	}

	setMandatariaMultiple(selectedCampaign) {
		this.selectedCampaigns = selectedCampaign;

		let mandataria = "";
		let diverso = false;
		this.selectedCampaigns.forEach((camp) => {
			let campaign = this.camapaigns.filter((c) => c.id === camp);
			if (campaign.length > 0) {
				if (mandataria === "") {
					mandataria = campaign[0].mandataria;
				}
				if (mandataria !== campaign[0].mandataria) {
					console.warn("diverso");
					diverso = true;
				}
				mandataria = campaign[0].mandataria;
			}
			if (!diverso) {
				let m = this.mandatarie.filter(
					(mandataria) => campaign[0].mandataria === mandataria.name
				);
				if (m && m.length > 0) {
					this.selectedMandataria = m[0].name;
				}
			} else {
				console.log("rest mandataria selected");
				this.selectedMandataria = null;
			}
		});
	}
}
