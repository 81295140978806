import {
	Component,
	OnInit,
	ViewChild,
	Output,
	EventEmitter,
} from "@angular/core";
import {
	MatTableDataSource,
	MatPaginator,
	MatSort,
	MatDialog,
} from "@angular/material";
import { PartnersService } from "../../../../../app/services/partners.service";
import { AccountPartnersService } from "../../../../../app/services/account-partners.service";
import { AuthenticationService } from "../../../../../app/services/authentication.service";

export interface PartnerData {
	nr: string;
	status: string;
	type: string;
	emailAmm: string;
	ragioneSociale: string;
	account: string;
	accountConfirmationTimestamp: string;
	docs: any;
}

@Component({
	selector: "kt-account-wip",
	templateUrl: "./account-wip.component.html",
	styleUrls: ["./account-wip.component.scss"],
})
export class AccountWipComponent implements OnInit {
	partners = [];
	partnerIdToBeDeleted = -1;
	isSuperuser = false;
	arrayStatus = [
		"new",
		"listino",
		"sending documentation",
		"docs confirmed",
		"external app",
		"confirmed",
	];
	filterStatus;
	filter: any = "";
	@Output() wipNr = new EventEmitter<number>();

	displayedColumns: string[] = [
		"nr",
		"status",
		"type",
		"ragioneSociale",
		"account",
		"business_unit",
		"main_contractor",
		"confTime",
		"docs",
	];
	dataSource: MatTableDataSource<PartnerData>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	isAccount = false;

	constructor(
		private authService: AuthenticationService,
		private dialog: MatDialog,
		private accountPartnerService: AccountPartnersService,
		private partnersService: PartnersService
	) {}

	async ngOnInit() {
		this.isAccount = this.authService.getUserRole() === 3;
		this.isSuperuser = this.authService.isSuperUser();

		await this.getPartnersFromDB();

		this.initTable();
		this.wipNr.emit(this.partners.length);
	}
	async getPartnersFromDB() {
		await this.partnersService
			.getPartnersAccount()
			.then((res: any) => {
				for (const partner of res.data.allPartnerPartners.nodes) {
					// console.log(partner)
					if (partner.status != "not_confirmed") {
						this.partners.push({
							id: partner.id,
							accountConfirmationTimestamp:
								!!partner.accountConfirmationTimestamp
									? new Date(
											partner.accountConfirmationTimestamp
									  ).toDateString()
									: "-",
							accountConfirmation: partner.accountConfirmation,
							isMainContractor: partner.mainContractor,
							analystConfirmation: partner.analystConfirmation,
							status: this.partnersService.getStatus(
								partner.status
							),
							realStatus: partner.status,
							bu: partner.bu,
							mainContractor: partner.nomeMainContractor,
							type:
								partner.type === "gy"
									? "Agency"
									: partner.type === "ge"
									? "Agente"
									: "-",
							emailAmm: !!partner.emailAmm
								? partner.emailAmm
								: partner.emailGenerale,
							nomeCognomeLegale: !!partner.nomeCognomeLegale
								? partner.nomeCognomeLegale
								: "-",
							ragioneSociale: !!partner.ragioneSociale
								? partner.ragioneSociale
								: "-",
							account: !!partner.agentUserByAccountId
								? partner.agentUserByAccountId.firstName +
								  " " +
								  partner.agentUserByAccountId.lastName
								: "-",
						});
					}
				}
				console.log("WIP FROM DB", this.partners);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	initTable() {
		this.dataSource = new MatTableDataSource(this.partners);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	promptDeactivate(deactivateContent, partnerId) {
		this.partnerIdToBeDeleted = partnerId;

		this.dialog.open(deactivateContent);
	}

	async deactivateAccount() {
		await this.accountPartnerService
			.deactivatePartner(this.partnerIdToBeDeleted)
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	// HELPERS
	applyFilter(event: Event) {
		if (event) {
			if (event.target) {
				this.filter = (event.target as HTMLInputElement).value;
				this.filterStatus = null;
			} else {
				this.filter = event;
			}
			this.dataSource.filter = this.filter.trim().toLowerCase();

			if (this.dataSource.paginator) {
				this.dataSource.paginator.firstPage();
			}
		} else {
			this.dataSource.filter = null;
		}
	}

	/**
	 * Returens item CSS Class Name by status
	 *
	 * @param status: string
	 */
	getStatus(status: string = "new"): string {
		switch (status) {
			case "new":
				return "danger";
			case "listino":
				return "brand";
			case "sending_documentation":
				return "info";
			case "docs_confirmed":
				return "primary";
			case "teamwork":
				return "warning";
			case "confirmed":
				return "success";
		}
		return "";
	}
}
