import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PartnersService } from '../../../../app/services/partners.service';
import { Router } from '@angular/router';

@Component({
  selector: 'kt-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  wipBadge = 0
  confBadge = 0
  isAccountManager // SHOULD BE FALSE TO ENTER

  constructor(private router: Router, private partnersService: PartnersService, private chRef: ChangeDetectorRef) {   

    this.isAccountManager = false
  }

  async ngOnInit() {

  }

  setWIPBadge(wipNr){
    if(wipNr){
      this.wipBadge = wipNr
    } else{
      console.log('Unknown wip nr')
    }
  }

  setConfirmedBadge(confNr){
    if(confNr){
      this.confBadge = confNr
    } else{
      console.log('Unknown conf nr')
    }
  }

  ngOnDestroy() {
    this.chRef.detach(); // do this
  }
}
