import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import { AuthenticationService } from "./authentication.service";
import gql from "graphql-tag";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: "root",
})
export class AccountPartnersService {
	wipPartners;
	myPartners;
	wipLength = 0;
	partnersLength = 0;
	wipReady = false;
	partnersReady = false;

	allPartners = []; // STORE ALL POSSIBLE DATA FOR A SINGLE PARTNER [ARRAY]
	newPartners = []; // NEW PARTNERS MAT TABLE INFO
	agents = []; // ALL ACCOUNT AGENTS
	mainContractor = [];

	// FOR QUERIES
	partnerTemp;
	contTemp;

	constructor(
		private http: HttpClient,
		private apollo: Apollo,
		private authService: AuthenticationService
	) {}

	async createPartner(partner) {
		console.log(partner);
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        createPartnerPartner(input:{
          clientMutationId:"1"
          partnerPartner:{
            createdAt:"${new Date().toISOString().split("T")[0]}"
            updatedAt:"${new Date().toISOString().split("T")[0]}"
            deleted:false
            ragioneSociale:"${partner.ragioneSociale.toUpperCase()}"
            nomeCognomeLegale:"${partner.nomeCognomeLegale}"
            telefonoGenerale:"${partner.telefonoGenerale}"
            nomeCognomeAmm:"${partner.nomeCognomeAmm}"
            emailAmm:"${partner.emailAmm.toLowerCase()}"
            telefonoAmm:"${partner.telefonoAmm}"
            nomeCognomeBo: "${partner.nomeCognomeBo}"
            numeroUscita:"${partner.numeroUscita}"
            emailBo: "${partner.emailBo.toLowerCase()}"
            status:"new"
            pivaCodiceFiscale:"${partner.pivaCodiceFiscale}"
            roc:"${partner.roc}"
            notes:"${partner.notes}"
            refEmail:"${partner.refEmail.toLowerCase()}"
            type:"${partner.type}"
            accountId: ${partner.accountId}
            bu: "${partner.businessUnit ? partner.businessUnit : ""}"
			idMainContractor:${partner.mainContractor ? partner.mainContractor.id : null}
			mainContractor:${partner.isMainContractor}
			idPadre:${partner.padre ? partner.padre.id : null}
			nomePadre:"${this.padre(partner)}"
			padre:${partner.isPadre}
			nomeMainContractor:"${
				partner.mainContractor
					? partner.mainContractor.nomeCognomeLegale
					: ""
			}"
            accountConfirmation:false
            analystConfirmation:false
          }
        }){
          partnerPartner{
            id
          }
        }
      }
      `,
			})
			.toPromise();
	}

	padre(partner) {
		if (partner.padre) {
			return partner.padre.nomeCognomeLegale
				? partner.padre.nomeCognomeLegale
				: partner.padre.ragioneSociale;
		}
		return "";
	}
	async saveEmails(idPartner, email) {
		await this.authService.verifyToken();
		return await this.apollo
			.mutate({
				mutation: gql`
					mutation {
						createContactEmailPartner(
							input: {
								contactEmailPartner: {
									email: "${email.toLowerCase()}"
									idPartner: ${idPartner}
								}
								clientMutationId: "1"
							}
						) {
							contactEmailPartner {
								email
								idPartner
							}
						}
					}
				`,
			})
			.toPromise();
	}
	async deleteEmails(email, partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
					mutation {
						deleteContactEmailPartnerByEmailAndIdPartner(						
							input: { email: "${email}", idPartner: ${partnerId} }
						  ) {
							deletedContactEmailPartnerId
						  }
					}
				`,
			})
			.toPromise();
	}

	async deactivatePartner(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          id:${partnerId}
          partnerPartnerPatch:{
            deleted: true
          }
        }){
          clientMutationId
        }
      }
      `,
			})
			.toPromise();
	}

	async reactivatePartner(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          id:${partnerId}
          partnerPartnerPatch:{
            deleted: false
          }
        }){
          clientMutationId
        }
      }
      `,
			})
			.toPromise();
	}

	async assignAccount(partnerId, accountId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${partnerId}
          partnerPartnerPatch:{
            accountId:${accountId}
          }
        }){
          agentUserByAccountId{
            id
            username
            firstName
            lastName
          }
        }
      }
      `,
			})
			.toPromise();
	}
	async creaAssociazioneAccountManager(partnerId, accountId, current) {
		await this.authService.verifyToken();
		let date;
		if (current) {
			date = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
				.toISOString()
				.split("T")[0];
		} else {
			date = new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				new Date().getDay()
			)
				.toISOString()
				.split("T")[0];
		}

		return await this.apollo
			.mutate({
				mutation: gql`
					mutation {
						createAccountManagerPartner(
							input: {
								accountManagerPartner: {
									createdAt: "${date}"
									deleted: false
									partnerId: ${partnerId}
									accountId: "${accountId}"
								}
							}
						) {
							accountManagerPartner {
								accountId
								createdAt
								deleted
								partnerId
							}
						}
					}
				`,
			})
			.toPromise();
	}

	getDeals(contract) {
		var deals = [];
		if (!!contract.partnerDealsByContractId) {
			for (const deal of contract.partnerDealsByContractId.nodes) {
				deals.push({
					id: deal.id,
					isActive: deal.isActive,
					startMonth: this.parseItalianDate(deal.startMonth),
					date: deal.startMonth,
					brand: deal.partnerBrandByBrandId.name,
					brandId: deal.partnerBrandByBrandId.id,
					campaign: deal.partnerCampaignByCampaignId.name,
					campaignId: deal.partnerCampaignByCampaignId.id,
					listing: deal.partnerListingByListingId.name,
					listingId: deal.partnerListingByListingId.id,
					uploaded: !!deal.document
						? deal.document === ""
							? false
							: true
						: false, // IF NULL FALSE, IF EXISTS CHECK IF EMPTY
					partnerDetailsByDealId: deal.partnerDetailsByDealId, //DETAILS OF THIS DEAL
				});
			}
			//console.log(this.deals)
		} else {
		}
		return deals;
	}

	// UPDATE STATUS TO SENDING DOCS AND ACCOUNT-CONFIRMED: TRUE
	async updateToNotConfirmed(partnerId, mailType, domain) {
		await this.authService.verifyToken();

		let mut = "";

		if (!!mailType && !!domain) {
			mut = `
        mailType:"${mailType}"
        domain:"${domain}"
      `;
		}

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${partnerId}
          partnerPartnerPatch:{
            status:"not_confirmed"
            ${mut}
          }
        }){
          partnerPartner{
            status
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async getAllAgents(type) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allAgentUsers(condition:{
          userType: ${type}
          isActive: true
        }){
          nodes{
            id
            firstName
            lastName
            email
            userType
            isSuperuser
            isActive
          }
        }
      }
      `,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getEmailPartnerFromDB(idPartner) {
		let emailsFromDB;
		let emails = [];
		await this.getPartnerEmail(idPartner).then((res) => {
			emailsFromDB = res.data;
			emailsFromDB = emailsFromDB.allContactEmailPartners.nodes;
		});
		emailsFromDB.forEach((email) => {
			emails.push({
				id: email.email,
				rowId: email.id,
				text: email.email,
				selected: true,
			});
		});
		return emails;
	}

	async getPartnerEmail(idPartner) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allContactEmailPartners(
							condition: { idPartner: ${idPartner} }
						) {
							nodes {
								email
								id
							}
						}
					}
				`,
			})
			.toPromise();
	}

	// GET UNCONFIRMED PARTNERS
	async getUnconfirmedPartners() {
		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners(
							condition: { status: "not_confirmed" }
						) {
							nodes {
								id
								nomeCognomeLegale
								ragioneSociale
								status
								type
								mainContractor
								nomeMainContractor
								emailAmm
								emailGenerale
								agentUserByAccountId {
									username
									email
								}
							}
						}
					}
				`,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}

	async getUnconfirmedPartnersFromDB() {
		await this.authService.verifyToken();

		let unconfPartners = [];

		await this.getUnconfirmedPartners()
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allPartnerPartners.nodes;
				for (const partner of this.partnerTemp) {
					unconfPartners.push({
						id: partner.id,
						nomeCognomeLegale: partner.nomeCognomeLegale,
						ragioneSociale: partner.ragioneSociale || "-",
						isMainContractor: partner.mainContractor,

						status:
							partner.status[0].toUpperCase() +
							partner.status.slice(1),
						type:
							partner.type === "gy"
								? "Agency"
								: partner.type === "ge"
								? "Agente"
								: "-",
						emailAmm: !!partner.emailAmm
							? partner.emailAmm
							: partner.emailGenerale,
						emailGenerale: partner.emailGenerale,
						agent: partner.agentUserByAccountId
							? partner.agentUserByAccountId.username
							: "-",
						agentEmail: partner.agentUserByAccountId
							? partner.agentUserByAccountId.email
							: "-",
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});

		return unconfPartners;
	}

	async getPartnerDeals(pId) {
		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(condition:{
          id:${pId}
        }){
          nodes{
            partnerContractByPartnerId{
              partnerDealsByContractId{
				  nodes{
				  mandataria
                  id
                  createdAt
                  partnerBrandByBrandId{
                    name
                  }
                  partnerCampaignByCampaignId{
                    name
                  }
                  partnerListingByListingId{
                    name
                  }
                }
              }
            }
          }
        }
      }    
      `,
				fetchPolicy: "cache-first",
			})
			.toPromise();
	}

	async getPartnerDealsFromDB(pId) {
		await this.authService.verifyToken();

		let deals = [];

		await this.getPartnerDeals(pId)
			.then((res: any) => {
				this.partnerTemp =
					res.data.allPartnerPartners.nodes[0].partnerContractByPartnerId.partnerDealsByContractId.nodes;
			})
			.catch((err) => {
				console.log(err);
			});

		deals = this.partnerTemp.length > 0 ? this.partnerTemp : [];
		return deals;
	}

	// GET FRESH PARTNERS FROM FORMS THAT DO NOT HAVE AN ACCOUNT YET
	async getNewPartners() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners(
							condition: {
								accountConfirmation: false
								analystConfirmation: false
								accountId: null
								deleted: false
							}
						) {
							nodes {
								id
								nomeCognomeLegale
								ragioneSociale
								status
								type
								mainContractor
								emailAmm
								emailGenerale
							}
						}
					}
				`,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}

	async getNewPartnersFromDB() {
		await this.authService.verifyToken();

		let newPartners = [];
		// console.log('getting from db')

		await this.getNewPartners()
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allPartnerPartners.nodes;
				for (const partner of this.partnerTemp) {
					newPartners.push({
						id: partner.id,
						nomeCognomeLegale: partner.nomeCognomeLegale,
						ragioneSociale: partner.ragioneSociale || "-",
						isMainContractor: partner.mainContractor,
						nomeMainContractor: partner.nomeMainContractor,

						status:
							partner.status[0].toUpperCase() +
							partner.status.slice(1),
						type:
							partner.type === "gy"
								? "Agency"
								: partner.type === "ge"
								? "Agente"
								: "-",
						emailAmm: !!partner.emailAmm
							? partner.emailAmm
							: partner.emailGenerale,
						emailGenerale: partner.emailGenerale,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});

		this.newPartners = newPartners;
		// console.log(newPartners)
		return newPartners;
	}

	// GET ALL AGENTS LOCAL CALL
	async getAllAgentsFromDB() {
		if (this.agents.length > 0) {
			return this.agents;
		}

		await this.authService.verifyToken();

		let agents = [];
		await this.getAllAgents(3)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allAgentUsers.nodes;
				for (const user of this.partnerTemp) {
					agents.push({
						id: user.id,
						name:
							user.firstName.length > 0 &&
							user.lastName.length > 0
								? user.firstName + " " + user.lastName
								: "-",
						username: user.username,
						superuser: user.isSuperuser,
						type: user.userType,
						active: user.isActive,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
		await this.getAllAgents(4)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allAgentUsers.nodes;
				for (const user of this.partnerTemp) {
					agents.push({
						id: user.id,
						name:
							user.firstName.length > 0 &&
							user.lastName.length > 0
								? user.firstName + " " + user.lastName
								: "-",
						username: user.username,
						superuser: user.isSuperuser,
						type: user.userType,
						active: user.isActive,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});

		this.agents = agents;
		return agents;
	}

	// CALL DB TO GET PARTNER INFO
	async getPartnerInfo(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(
          condition:{
            id: ${partnerId}
          }
        ){
          nodes{
            id
            nomeCognomeLegale
            ragioneSociale
            pivaCodiceFiscale
            telefonoGenerale
            emailGenerale
            emailAmm
            nomeCognomeAmm
            telefonoAmm
            nomeCognomeBo
            emailBo
            numeroUscita
            refEmail
            roc
            notes
			bu
			nomeMainContractor
			idMainContractor
			idPadre
			padre
			mainContractor
            type
            analystConfirmation
            accountConfirmation
            status
            agentUserByAccountId{
              id
              username
              firstName
              lastName
            }
            partnerContractByPartnerId{
              partnerDealsByContractId(condition:{
                isActive:true
              }){
                nodes{
                  id
                  isActive
                  startMonth
                  mailToBo
				  mandataria
                  partnerCampaignByCampaignId{
                    name
                    id
                  }
                  partnerBrandByBrandId{
                    name
                    id
                  }
                  partnerListingByListingId{
                    name
                    id
                  }
                }
              }
            }
          }
        }
      }`,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}
	// CALL DB TO GET PARTNER INFO
	async getPartnerPadre() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(
          condition:{
			padre:${true}
		}
        ){
          nodes{
            id
            nomeCognomeLegale
            ragioneSociale
          }
        }
      }`,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}
	async getPartnerPadreFromDB() {
		let partner;
		await this.getPartnerPadre().then((res: any) => {
			partner = res.data.allPartnerPartners.nodes;
		});
		return partner;
	}

	// GET ALL INFO, DEALS FOR A SINGLE PARTNER
	async getPartnerInfoFromDB(partnerId) {
		await this.authService.verifyToken();

		let partner = {};
		await this.getPartnerInfo(partnerId)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allPartnerPartners.nodes[0];
				partner = {
					id: this.partnerTemp.id,
					nomeCognomeLegale: this.partnerTemp.nomeCognomeLegale,
					ragioneSociale: this.partnerTemp.ragioneSociale,
					pivaCodiceFiscale: this.partnerTemp.pivaCodiceFiscale,
					telefonoGenerale: this.partnerTemp.telefonoGenerale,
					emailGenerale: this.partnerTemp.emailGenerale,
					emailAmm: this.partnerTemp.emailAmm,
					nomeCognomeAmm: this.partnerTemp.nomeCognomeAmm,
					telefonoAmm: this.partnerTemp.telefonoAmm,
					nomeCognomeBo: this.partnerTemp.nomeCognomeBo,
					emailBo: this.partnerTemp.nomeCognomeBo,
					numeroUscita: this.partnerTemp.numeroUscita,
					refEmail: this.partnerTemp.refEmail,
					roc: this.partnerTemp.roc,
					notes: this.partnerTemp.notes,
					type: this.partnerTemp.type,
					analystConfirmation: this.partnerTemp.analystConfirmation,
					accountConfirmation: this.partnerTemp.accountConfirmation,
					status: this.partnerTemp.status,
					bu: this.partnerTemp.bu,
					contract: this.partnerTemp.partnerContractByPartnerId,
					idMainContractor: this.partnerTemp.idMainContractor,
					isMainContractor: this.partnerTemp.mainContractor,
					nomeMainContractor: this.partnerTemp.nomeMainContractor,
					isPadre: this.partnerTemp.padre,
					idPadre: this.partnerTemp.idPadre,
					deals: this.getDealsFromContract(
						this.partnerTemp.partnerContractByPartnerId
					),
				};

				this.allPartners[partnerId] = partner;
				console.log("GETTING PARTNER INFO FROM DB ", partner);
				console.log(this.partnerTemp);
			})
			.catch((err) => {
				console.log(err);
			});
		return partner;
	}

	// QUERY TO GET ALL WIP AND MY-PARTNERS FOR THE USER
	async getAllPartners(analyst, account) {
		await this.authService.verifyToken();
		const accountFilter = this.authService.canViewAllPartners()
			? ""
			: `accountId:${this.getUserId()}`;

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(condition:{
          ${accountFilter}
          analystConfirmation:${analyst}
          accountConfirmation:${account}
          deleted:false
        }){
          nodes{
            id
            nomeCognomeLegale
            ragioneSociale
            pivaCodiceFiscale
            telefonoGenerale
            emailGenerale
            emailAmm
            nomeCognomeAmm
            telefonoAmm
            nomeCognomeBo
            emailBo
            refEmail
            type
            analystConfirmation
            accountConfirmation
            status
            partnerContractByPartnerId{
              id
              isNew
              isActive
            }
          }
        }
      }
      
      `,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}
	// GET ( LOCAL OPERATIONS )
	async getAllMainContractorFromDB() {
		let resTemp;
		if (this.mainContractor.length > 0) {
			// console.log('mainContractr from service')
			return this.mainContractor;
		}

		await this.getAllPartnersMainContractor(true, true)
			.then((res) => {
				resTemp = res.data;
				resTemp = resTemp.allPartnerPartners.nodes;
				// console.log(this.resTemp)
			})
			.catch((err) => {
				console.log(err);
			});
		this.mainContractor = resTemp;
		return this.mainContractor;
	}

	// QUERY TO GET ALL WIP AND MY-PARTNERS FOR THE USER
	async getAllPartnersMainContractor(analyst, account) {
		await this.authService.verifyToken();
		let result;

		const accountFilter = this.authService.canViewAllPartners()
			? ""
			: `accountId:${this.getUserId()}`;

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(condition:{
          ${accountFilter}
          analystConfirmation:${analyst}
          accountConfirmation:${account}
          deleted:false
		  mainContractor:${true}
        }){
          nodes{
            id
            nomeCognomeLegale
            ragioneSociale
            pivaCodiceFiscale
            partnerContractByPartnerId{
              id
              isNew
              isActive
            }
          }
        }
      }
      
      `,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}

	// get all partner
	async getAllPartnersNoFilter() {
		await this.authService.verifyToken();

		const accountFilter = this.authService.canViewAllPartners()
			? ""
			: `accountId:${this.getUserId()}`;

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners(condition: {}) {
							nodes {
								id
								pivaCodiceFiscale
								emailGenerale
							}
						}
					}
				`,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}

	getStatus(status) {
		if (status === "new") {
			return "New";
		} else if (status === "listino") {
			return "Listino";
		} else if (status === "sending_documentation") {
			return "Sending Documentation";
		} else if (status === "docs_confirmed") {
			return "Docs Confirmed";
		} else if (status === "teamwork") {
			return "External app";
		} else if (status === "confirmed") {
			return "Confirmed";
		}
	}

	async getAllWIPPartnersFromDB(analyst, account) {
		// console.log('wip partners', this.wipPartners)
		await this.getAllPartners(analyst, account)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allPartnerPartners.nodes;
			})
			.catch((err) => {
				console.log(err);
			});

		this.wipPartners = this.partnerTemp;
		this.wipReady = true;
		this.wipLength = this.wipPartners.length;
		return this.wipPartners;
	}

	async getAllMyPartnersFromDB(analyst, account) {
		let myPartners = [];
		await this.getAllPartners(analyst, account)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allPartnerPartners.nodes;
				for (const partner of this.partnerTemp) {
					// console.log(partner.type)
					partner.realStatus = partner.status;
					partner.type = partner.type == "gy" ? "Agency" : "Agente";
					partner.status = this.getStatus(partner.status);
					myPartners.push(partner);
				}
			})
			.catch((err) => {
				console.log(err);
			});

		this.myPartners = myPartners;
		this.partnersReady = true;
		this.partnersLength = this.myPartners.length;
		return myPartners;
	}

	async updatePartner(partnerId, partnerData) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          id:${partnerId}
          clientMutationId:"${this.getUserId()}"
          partnerPartnerPatch:{
            nomeCognomeLegale:"${partnerData.nomeCognomeLegale}",
            telefonoGenerale:"${partnerData.telefonoGenerale}",
            emailGenerale:"${partnerData.emailGenerale}",
            pivaCodiceFiscale:"${partnerData.pivaCodiceFiscale}",
            ragioneSociale:"${partnerData.ragioneSociale}",
            numeroUscita:"${partnerData.numeroUscita}",
            emailAmm:"${partnerData.emailAmm}",
            nomeCognomeAmm:"${partnerData.nomeCognomeAmm}", 
            telefonoAmm:"${partnerData.telefonoAmm}",
            nomeCognomeBo:"${partnerData.nomeCognomeBo}",
            emailBo:"${partnerData.emailBo}",
            refEmail:"${partnerData.refEmail}",
            roc:"${partnerData.roc}",
            notes:"${partnerData.notes}",
            type:"${partnerData.type}",
            status:"listino"
          }
        }){
          partnerPartner{
            nomeCognomeLegale
            emailGenerale
            pivaCodiceFiscale
            ragioneSociale
            numeroUscita
            emailAmm
            nomeCognomeAmm
            telefonoAmm
            nomeCognomeBo
            emailBo
            refEmail
            roc
            notes
            type
          }
        }
      }
      
      `,
			})
			.toPromise();
	}

	async updatePartnerInDB(partnerId, partnerData) {
		// await this.authService.verifyToken()

		let updated = false;

		await this.updatePartner(partnerId, partnerData)
			.then((res) => {
				updated = true;
				this.allPartners[partnerId].nomeCognomeLegale =
					partnerData.nomeCognomeLegale;
				this.allPartners[partnerId].telefonoGenerale =
					partnerData.telefonoGenerale;
				this.allPartners[partnerId].pivaCodiceFiscale =
					partnerData.pivaCodiceFiscale;
				this.allPartners[partnerId].ragioneSociale =
					partnerData.ragioneSociale;
				this.allPartners[partnerId].numeroUscita =
					partnerData.numeroUscita;
				this.allPartners[partnerId].emailAmm = partnerData.emailAmm;
				this.allPartners[partnerId].nomeCognomeAmm =
					partnerData.nomeCognomeAmm;
				this.allPartners[partnerId].telefonoAmm =
					partnerData.telefonoAmm;
				this.allPartners[partnerId].nomeCognomeBo =
					partnerData.nomeCognomeBo;
				this.allPartners[partnerId].emailBo = partnerData.emailBo;
				this.allPartners[partnerId].refEmail = partnerData.refEmail;
				this.allPartners[partnerId].roc = partnerData.roc;
				this.allPartners[partnerId].notes = partnerData.notes;
				this.allPartners[partnerId].type = partnerData.type;
				this.allPartners[partnerId].status = "listino";
				// console.log(this.allPartners[partnerId])

				this.wipPartners[partnerId].nomeCognomeLegale =
					partnerData.nomeCognomeLegale;
				this.wipPartners[partnerId].emailAmm = partnerData.emailAmm;
				this.wipPartners[partnerId].ragioneSociale =
					partnerData.ragioneSociale;
				this.wipPartners[partnerId].status = "Listino";
				this.wipPartners[partnerId].realStatus = "listino";
				this.wipPartners[partnerId].type =
					partnerData.type === "gy" ? "Agency" : "Agente";
			})
			.catch((err) => {
				console.log(err);
			});

		// console.log(partnerData)
		return updated;
	}

	async createContract(partnerId, start, end) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        createPartnerContract(input:{
          clientMutationId:""
          partnerContract:{
            deleted:false
            partnerId:${partnerId}
            startDate:"${start}"
            endDate:"${end}"
            isActive: true
            isNew: true
            createdAt:"${new Date().toISOString().split("T")[0]}"
            updatedAt:"${new Date().toISOString().split("T")[0]}"
          }
        }){
          partnerContract{
            id
            partnerId
            startDate
            endDate
            isActive
            isNew
          }
        }
      }
      `,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}

	async getContract(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerContracts(condition:{
          partnerId:${partnerId}
        }){
          nodes{
            id
            partnerId
          }
        }
      }
      `,
				fetchPolicy: "no-cache",
			})
			.toPromise();
	}

	async createContractInDB(partnerId, start, end) {
		await this.authService.verifyToken();

		let cId = -1;
		// console.log('Creating contract', partnerId, start, end)

		let hasContract = false;
		await this.getContract(partnerId)
			.then((res) => {
				this.contTemp = res.data;
				this.contTemp = this.contTemp.allPartnerContracts.nodes;
				if (this.contTemp.length > 0) {
					hasContract = true;
					cId = this.contTemp[0].id;
				}
			})
			.catch((err) => {
				console.log(err);
			});

		if (hasContract) {
			// console.log('It has contract with id ', cId)
		} else {
			// console.log('Doesnt have a contract, should create a new one')
			await this.createContract(partnerId, start, end)
				.then((res) => {
					this.contTemp = res.data;
					this.contTemp =
						this.contTemp.createPartnerContract.partnerContract;
					cId = this.contTemp.id;
					// console.log('Created contract with id ', cId)
				})
				.catch((err) => {
					console.log(err);
				});
		}

		return cId;
	}

	async createDeal(deal, contractId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        createPartnerDeal(input:{
          clientMutationId:"${this.getUserId()}"
          partnerDeal:{
            contractId:${contractId}
            brandId:${deal.brandId}
            campaignId:${deal.campaignId}
            listingId:${deal.listingId}
			mandataria:"${deal.mandataria}"
            createdAt:"${new Date().toISOString().split("T")[0]}"
            updatedAt:"${new Date().toISOString().split("T")[0]}"
            deleted:false
            isActive:true
            startMonth:"${deal.date}"
          }
        }){
          partnerDeal{
            id
            campaignId
            contractId
            listingId
			mandataria
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async createDealsInDB(deals, contractId) {
		await this.authService.verifyToken();
		let uploaded = [];

		for (let i = 0; i < deals.length; i++) {
			if (deals[i].exitsInDb) {
			} else {
				await this.createDeal(deals[i], contractId)
					.then((res) => {
						console.log(res);
						uploaded[i] = 1;
					})
					.catch((err) => {
						console.log(err);
						uploaded[i] = 0;
					});
			}
		}

		return uploaded;
	}

	async changeStatusToNotConfirmed(partnerId, mailType, domain) {
		await this.authService.verifyToken();

		let statusChanged = false;
		await this.updateToNotConfirmed(partnerId, mailType, domain)
			.then((res) => {
				console.log(res);
				statusChanged = true;
			})
			.catch((err) => {
				console.log(err);
			});
		return statusChanged;
	}

	async updateToSendingDocsFromUnconfirmed(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"5"
          id: ${partnerId}
          partnerPartnerPatch:{
            accountConfirmation:true
            accountConfirmationTimestamp:"${new Date().toISOString()}"
            status:"sending_documentation"
          }
        }){
          partnerPartner{
            accountConfirmation
            accountConfirmationTimestamp
            status
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async sendEmailConfirmation(name, deals, accountName, accountEmail) {
		await this.authService.verifyToken();

		var mailBody = {
			to: "raimondo.davino@nsone.it",
			// "cc": cc,
			from: "noreply@wearefiber.com",
			text: "Fiber",
			partner_name: `${name}`,
			details: deals, // OR DEALS
			type: `confirmation`, // Faturazzione vs Bonifico
			accountName: `${accountName}`,
			accountEmail: `${accountEmail}`,
		};

		console.log(mailBody);

		return await this.http
			.post(`${environment.API_URL}sendgrid`, mailBody, {
				headers: {
					"Content-Type": "application/json",
					responseType: "text",
				},
			})
			.toPromise();
	}

	getDealsFromContract(contract) {
		if (!contract) {
			// console.log('No contract, no deals')
			return [];
		}

		let deals = [];
		for (const deal of contract.partnerDealsByContractId.nodes) {
			deals.push({
				id: deal.id,
				campaign: deal.partnerCampaignByCampaignId.name,
				campaignId: deal.partnerCampaignByCampaignId.id,
				brand: deal.partnerBrandByBrandId.name,
				brandId: deal.partnerBrandByBrandId.id,
				listing: deal.partnerListingByListingId.name,
				listingId: deal.partnerListingByListingId.id,
				date: deal.startMonth,
				startMonth: this.parseItalianDate(deal.startMonth),
				isActive: deal.isActive,
				exitsInDb: true,
				mandataria: deal.mandataria,
			});
		}
		return deals;
	}

	parseItalianDate(date) {
		const dates = date.split("-");
		return `${dates[1]}-${dates[0]}`;
	}

	getUserId() {
		// TO DO, CHANGE AFTER TOKEN ID
		var myRawToken = localStorage.getItem("anagraficaToken");
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(myRawToken);
		return decodedToken.data.id;
	}
}
