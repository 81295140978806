export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},
			items: []
		},
		aside: {
			self: {},
			items: [
				{
					title: 'New Partners',
					root: true,
					icon: 'flaticon-users-1',
					page: '/new-partners',
					translate: 'MENU.NEW_PARTNERS',
					bullet: 'dot',
					roles: [4]
				},
				{
					title: 'Unconfirmed partners',
					root: true,
					icon: 'flaticon-users-1',
					page: '/unconfirmed',
					bullet: 'dot',
					roles: [4]
				},
				{
					title: 'Partners',
					root: true,
					icon: 'flaticon-users',
					page: '/account-partner',
					translate: 'MENU.PARTNERS',
					bullet: 'dot',
					roles: [3,4]
				},
				{
					title: 'Partners',
					root: true,
					icon: 'flaticon-users',
					page: '/partners',
					translate: 'MENU.PARTNERS',
					bullet: 'dot',
					roles: [1,2]
				},
				{
					title: 'Details',
					root: true,
					alignment: 'left',
					icon: 'flaticon-notepad',
					toggle: 'click',
					roles: [2],
					submenu: [
						{
							title: 'Details',
							page: '/details',
							icon: 'flaticon-notepad',
						},
						{
							title: 'Analysis',
							page: '/analysis',
							icon: 'flaticon-analytics',
						},
						{
							title: 'Export',
							icon: 'flaticon-download',
							submenu: [
								{
									title: 'Export deals',
									page: '/settings/export-deals',
									icon: 'flaticon-clock-2',
								},
								{
									title: 'Export details',
									page: '/settings/export-details',
									icon: 'flaticon-notepad',
								},
								{
									
									title: 'Docs Sent',
									icon: 'flaticon-multimedia',
									page: '/settings/history',
								}
							]
						}
					]
				},
				{
					title: 'Feedback',
					root: true,
					icon: 'flaticon-envelope',
					page: '/feedback',
					translate: 'MENU.FEEDBACK',
					bullet: 'dot',
					roles:[1,2,3,4]
				},
				
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
