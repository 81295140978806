import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import { AuthenticationService } from "./authentication.service";
import gql from "graphql-tag";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
	providedIn: "root",
})
export class PartnersService {
	wipPartners = [];
	confirmedPartners = [];
	allPartners = []; // STORE DATA FOR EACH PARTNER WIP-CONFIRMED

	agents = []; // ALL ACCOUNT AGENTS

	// FOR QUERIES
	partnerTemp;

	constructor(
		private http: HttpClient,
		private apollo: Apollo,
		private authService: AuthenticationService
	) {}

	async createRunner(dealId, amount, start, end) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        createRunner(input:{
          clientMutationId:"4"
          runner:{
            createdAt:"${new Date().toISOString().split("T")[0]}"
            updatedAt:"${new Date().toISOString().split("T")[0]}"
            deleted:false
            startDate:"${start}"
            endDate:"${end}"
            dealId:${dealId}
            amount: "${amount}"
          }    
        }){
          clientMutationId
        }
      }
      `,
			})
			.toPromise();
	}

	async deactivateRunner(runnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updateRunnerById(input:{
          clientMutationId:"4"
          id: ${runnerId}
          runnerPatch:{
            deleted:true
          }
        }){
          clientMutationId
        }
      }
      `,
			})
			.toPromise();
	}

	async deactivateDeal(dealId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerDealById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${dealId}
          partnerDealPatch:{
            isActive: false
          }
        }){
          partnerDeal{
            id
            isActive
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async getPartners(accConf, anConf) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(
          condition:{
            accountConfirmation:${accConf}
            deleted:false
          }
        ){
          nodes{
            id
            accountConfirmationTimestamp
            accountConfirmation
            analystConfirmation
            status
            type
            emailAmm
            emailGenerale
            nomeCognomeLegale
            ragioneSociale
			mainContractor
			bu
			nomeMainContractor
            agentUserByAccountId{
              id
              username
              firstName
              lastName
            }
          }
        }
      }
      `,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}
	async getPartnersAccount() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners(condition: { deleted: false }) {
							nodes {
								id
								accountConfirmationTimestamp
								accountConfirmation
								analystConfirmation
								status
								type
								emailAmm
								emailGenerale
								nomeCognomeLegale
								ragioneSociale
								nomeMainContractor
								mainContractor
								bu
								nomeMainContractor
								agentUserByAccountId {
									id
									username
									firstName
									lastName
								}
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getAllAgents(type) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allAgentUsers(condition:{
          userType: ${type}
          isActive: true
        }){
          nodes{
            id
            firstName
            lastName
            email
            userType
            isSuperuser
            isActive
          }
        }
      }
      `,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getNewPartners() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerPartners(
							condition: {
								accountConfirmation: false
								analystConfirmation: false
								accountId: null
								deleted: false
							}
						) {
							nodes {
								id
								nomeCognomeLegale
								ragioneSociale
								status
								type
								emailAmm
								emailGenerale
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getPartnerInfo(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
      {
        allPartnerPartners(
          condition:{
            id: ${partnerId}
            deleted:false
          }
        ){
          nodes{
            id
            nomeCognomeLegale
            ragioneSociale
            pivaCodiceFiscale
            telefonoGenerale
            emailGenerale
            emailAmm
            nomeCognomeAmm
            telefonoAmm
            nomeCognomeBo
            emailBo
            numeroUscita
            refEmail
            roc
            notes
            type
            mailType
            domain
            analystConfirmation
            accountConfirmation
            status
			bu
			mainContractor
			nomeMainContractor
			idMainContractor
			padre
			nomePadre
            partnerDocumentsByDocumentoRiconoscimentoId{
              nodes{
                id
                doc
                createdAt
              }
            }
            partnerDocumentsByRocId{
              nodes{
                id
                doc
                createdAt
              }
            }
            partnerDocumentsByVisuraCameraleId{
              nodes{
                id
                doc
                createdAt
              }
            }
            partnerTeamworksByPartnerId{
              nodes{
                id
                name
              }
            }
            partnerContractByPartnerId{
              id
              partnerDocumentByDocumentId{
                id
                doc
                createdAt
              }
              partnerDealsByContractId{
                nodes{
                  id
                  isActive
                  startMonth
                  document
				  mandataria
                  partnerBrandByBrandId{
                    name
                    id
                  }
                  partnerCampaignByCampaignId{
                    name
                    id
                  }
                  partnerListingByListingId{
                    name
                    id
                  }
                  partnerDetailsByDealId{
                    nodes{
                      id
                      meseDiCompetenza
                      amountOut
                    }
                  }
                  runnersByDealId{
                    nodes{
                      id
                      amount
                      startDate
                      endDate
                      deleted
                    }
                  }
                }
              }
            }
            agentUserByAccountId{
              firstName
              lastName
              email
              id
            }
          }
        }
      }`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	parseItalianDate(date) {
		const dates = date.split("-");
		return `${dates[1]}-${dates[0]}`;
	}

	getDeals(contract) {
		var deals = [];
		if (!!contract.partnerDealsByContractId) {
			for (const deal of contract.partnerDealsByContractId.nodes) {
				deals.push({
					id: deal.id,
					isActive: deal.isActive,
					startMonth: this.parseItalianDate(deal.startMonth),
					date: deal.startMonth,
					brand: deal.partnerBrandByBrandId.name,
					brandId: deal.partnerBrandByBrandId.id,
					campaign: deal.partnerCampaignByCampaignId.name,
					campaignId: deal.partnerCampaignByCampaignId.id,
					listing: deal.partnerListingByListingId.name,
					listingId: deal.partnerListingByListingId.id,
					mandataria: deal.mandataria,
					uploaded: !!deal.document
						? deal.document === ""
							? false
							: true
						: false, // IF NULL FALSE, IF EXISTS CHECK IF EMPTY
					partnerDetailsByDealId: deal.partnerDetailsByDealId, //DETAILS OF THIS DEAL
					runnersByDealId: !!deal.runnersByDealId
						? deal.runnersByDealId.nodes
						: [],
					runnersLength: !!deal.runnersByDealId
						? deal.runnersByDealId.nodes.length
						: 0,
				});
			}
			//console.log(this.deals)
		} else {
			// console.log('DEALS UNDEFINED')
		}
		return deals;
	}

	async getPartnerInfoFromDB(partnerId) {
		let partner = {};
		await this.getPartnerInfo(partnerId)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allPartnerPartners.nodes[0];
				partner = {
					id: this.partnerTemp.id,
					nomeCognomeLegale: this.partnerTemp.nomeCognomeLegale,
					ragioneSociale: this.partnerTemp.ragioneSociale,
					pivaCodiceFiscale: this.partnerTemp.pivaCodiceFiscale,
					telefonoGenerale: this.partnerTemp.telefonoGenerale,
					emailGenerale: this.partnerTemp.emailGenerale,
					emailAmm: this.partnerTemp.emailAmm,
					mailType: this.partnerTemp.mailType,
					domain: this.partnerTemp.domain,
					nomeCognomeAmm: this.partnerTemp.nomeCognomeAmm,
					telefonoAmm: this.partnerTemp.telefonoAmm,
					nomeCognomeBo: this.partnerTemp.nomeCognomeBo,
					emailBo: this.partnerTemp.nomeCognomeBo,
					numeroUscita: this.partnerTemp.numeroUscita,
					refEmail: this.partnerTemp.refEmail,
					roc: this.partnerTemp.roc,
					app: this.partnerTemp.app,
					externalApp: this.partnerTemp.externalApp,
					typeApp: this.partnerTemp.typeApp,
					notes: this.partnerTemp.notes,
					type: this.partnerTemp.type,
					analystConfirmation: this.partnerTemp.analystConfirmation,
					accountConfirmation: this.partnerTemp.accountConfirmation,
					status: this.partnerTemp.status,
					bu: this.partnerTemp.bu,
					isMainContractor: this.partnerTemp.mainContractor,
					mainContractor: this.partnerTemp.idMainContractor,
					nomeMainContractor: this.partnerTemp.nomeMainContractor,
					nomePadre: this.partnerTemp.nomePadre,
					isPadre: this.partnerTemp.padre,
					teamwork:
						this.partnerTemp.partnerTeamworksByPartnerId.nodes
							.length > 0
							? this.partnerTemp.partnerTeamworksByPartnerId
									.nodes[
									this.partnerTemp.partnerTeamworksByPartnerId
										.nodes.length - 1
							  ].name
							: "-",
					account: !!this.partnerTemp.agentUserByAccountId
						? this.partnerTemp.agentUserByAccountId.firstName +
						  " " +
						  this.partnerTemp.agentUserByAccountId.lastName
						: "-",
					accountId: !!this.partnerTemp.agentUserByAccountId
						? this.partnerTemp.agentUserByAccountId.id
						: -1,
					accountEmail:
						!!this.partnerTemp.agentUserByAccountId &&
						!!this.partnerTemp.agentUserByAccountId.email
							? this.partnerTemp.agentUserByAccountId.email
							: "-",
					partnerContractByPartnerId:
						this.partnerTemp.partnerContractByPartnerId,
					doc_ric:
						this.partnerTemp
							.partnerDocumentsByDocumentoRiconoscimentoId.nodes
							.length > 0,
					vis_cam:
						this.partnerTemp.partnerDocumentsByVisuraCameraleId
							.nodes.length > 0,
					general:
						this.partnerTemp.partnerDocumentsByRocId.nodes.length >
						0,
					contract:
						!!this.partnerTemp.partnerContractByPartnerId &&
						!!this.partnerTemp.partnerContractByPartnerId
							.partnerDocumentByDocumentId &&
						this.partnerTemp.partnerContractByPartnerId
							.partnerDocumentByDocumentId.doc !== "",
					document_vis_cam:
						this.partnerTemp.partnerDocumentsByVisuraCameraleId
							.nodes.length > 0
							? this.partnerTemp
									.partnerDocumentsByVisuraCameraleId.nodes
							: [],
					document_general:
						this.partnerTemp.partnerDocumentsByRocId.nodes.length >
						0
							? this.partnerTemp.partnerDocumentsByRocId.nodes
							: [],
					document_doc_ric:
						this.partnerTemp
							.partnerDocumentsByDocumentoRiconoscimentoId.nodes
							.length > 0
							? this.partnerTemp
									.partnerDocumentsByDocumentoRiconoscimentoId
									.nodes
							: [],
					document_contract:
						!!this.partnerTemp.partnerContractByPartnerId &&
						!!this.partnerTemp.partnerContractByPartnerId
							.partnerDocumentByDocumentId &&
						this.partnerTemp.partnerContractByPartnerId
							.partnerDocumentByDocumentId.doc !== ""
							? this.partnerTemp.partnerContractByPartnerId
									.partnerDocumentByDocumentId.doc
							: "-",
					document_contract_created:
						!!this.partnerTemp.partnerContractByPartnerId &&
						!!this.partnerTemp.partnerContractByPartnerId
							.partnerDocumentByDocumentId &&
						this.partnerTemp.partnerContractByPartnerId
							.partnerDocumentByDocumentId.doc !== ""
							? this.partnerTemp.partnerContractByPartnerId
									.partnerDocumentByDocumentId.createdAt
							: "-",
					contractId: !!this.partnerTemp.partnerContractByPartnerId
						? this.partnerTemp.partnerContractByPartnerId.id
						: -1,
				};

				this.allPartners[partnerId] = partner;
			})
			.catch((err) => {
				console.log(err);
			});
		return partner;
	}

	async getAllAgentsFromDB() {
		if (this.agents.length > 0) {
			return this.agents;
		}

		let agents = [];
		await this.getAllAgents(3)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allAgentUsers.nodes;
				for (const user of this.partnerTemp) {
					agents.push({
						id: user.id,
						name:
							user.firstName.length > 0 &&
							user.lastName.length > 0
								? user.firstName + " " + user.lastName
								: "-",
						username: user.username,
						superuser: user.isSuperuser,
						type: user.userType,
						active: user.isActive,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
		await this.getAllAgents(4)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allAgentUsers.nodes;
				for (const user of this.partnerTemp) {
					agents.push({
						id: user.id,
						name:
							user.firstName.length > 0 &&
							user.lastName.length > 0
								? user.firstName + " " + user.lastName
								: "-",
						username: user.username,
						superuser: user.isSuperuser,
						type: user.userType,
						active: user.isActive,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});

		this.agents = agents;
		return agents;
	}

	async addDeal(deal) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        createPartnerDeal(input:{
          clientMutationId:"${this.getUserId()}"
          partnerDeal:{
            contractId:${deal.contractId}
            brandId:${deal.brandId}
            campaignId:${deal.campaignId}
            listingId:${deal.listingId}
            createdAt:"${new Date().toISOString().split("T")[0]}"
            updatedAt:"${new Date().toISOString().split("T")[0]}"
            deleted:false
            isActive:true
            startMonth:"${deal.date}"
          }
        }){
          partnerDeal{
            id
            campaignId
            contractId
            listingId
          }
        }
      }
      `,
			})
			.toPromise();
	}

	getUserId() {
		// TO DO, CHANGE AFTER TOKEN ID
		var myRawToken = localStorage.getItem("anagraficaToken");
		const helper = new JwtHelperService();
		const decodedToken = helper.decodeToken(myRawToken);
		return decodedToken.data.id;
	}

	async addDealToDB(deal) {
		let dealId = -1;

		await this.addDeal(deal)
			.then((res) => {
				// console.log(res)
				this.partnerTemp = res.data;
				this.partnerTemp =
					this.partnerTemp.createPartnerDeal.partnerDeal;

				dealId = this.partnerTemp.id;
			})
			.catch((err) => {
				console.log(err);
				dealId = -1;
			});

		return dealId;
	}

	async updateToDocsConfirmed(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${partnerId}
          partnerPartnerPatch:{
            status:"docs_confirmed"
          }
        }){
          partnerPartner{
            status
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async updateToDocsConfirmedInDB(partnerId) {
		// await this.authService.verifyToken()

		let updated = false;

		await this.updateToDocsConfirmed(partnerId)
			.then((res) => {
				console.log(res);
				updated = true;
			})
			.catch((err) => {
				console.log(err);
			});

		return updated;
	}

	async updateToSendingDocs(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${partnerId}
          partnerPartnerPatch:{
            status:"sending_documentation"
          }
        }){
          partnerPartner{
            status
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async updateToSendingDocsInDB(partnerId) {
		// await this.authService.verifyToken()

		let updated = false;

		await this.updateToSendingDocs(partnerId)
			.then((res) => {
				console.log(res);
				updated = true;
			})
			.catch((err) => {
				console.log(err);
			});

		return updated;
	}

	async updateToTeamwork(partnerId, idApp, idExternalApp) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${partnerId}
          partnerPartnerPatch:{
            status:"teamwork"
			id_external_app:${idExternalApp}
			type:${idApp}
          }
        }){
          partnerPartner{
            status
          }
        }
      }
      `,
			})
			.toPromise();
	}
	async updateToTeamworkStatus(partnerId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${partnerId}
          partnerPartnerPatch:{
            status:"teamwork"
          }
        }){
          partnerPartner{
            status
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async updateToTeamworkInDB(partnerId, idApp, idExternalApp) {
		// await this.authService.verifyToken()

		let updated = false;

		await this.updateToTeamwork(partnerId, idApp, idExternalApp)
			.then((res) => {
				console.log(res);
				updated = true;
			})
			.catch((err) => {
				console.log(err);
			});

		return updated;
	}

	async updateToConfirmed(partnerId) {
		await this.authService.verifyToken();
		console.log(`
		mutation{
			updatePartnerPartnerById(input:{
			  clientMutationId:"${this.getUserId()}"
			  id:${partnerId}
			  partnerPartnerPatch:{
				status:"confirmed"
				analystConfirmation: true
			  }
			}){
			  partnerPartner{
				status 
			  }
			}
		  }
`);
		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
          clientMutationId:"${this.getUserId()}"
          id:${partnerId}
          partnerPartnerPatch:{
            status:"confirmed"
            analystConfirmation: true
          }
        }){
          partnerPartner{
            status 
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async updateToConfirmedInDB(partnerId) {
		// await this.authService.verifyToken()

		let updated = false;

		await this.updateToConfirmed(partnerId)
			.then((res) => {
				console.log(res);
				updated = true;
			})
			.catch((err) => {
				console.log(err);
			});

		return updated;
	}

	getStatus(status) {
		if (status === "new") {
			return "New";
		} else if (status === "listino") {
			return "Listino";
		} else if (status === "sending_documentation") {
			return "Sending Documentation";
		} else if (status === "docs_confirmed") {
			return "Docs Confirmed";
		} else if (status === "teamwork") {
			return "External app";
		} else if (status === "confirmed") {
			return "Confirmed";
		}
	}
}
