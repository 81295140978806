import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	MatDialog,
	MatDialogConfig,
	MatPaginator,
	MatSort,
} from "@angular/material";
import { MatTableDataSource } from "@angular/material/table";
import { SuperpowersService } from "../../../../app/services/superpowers.service";
import { AccountPartnersService } from "../../../../app/services/account-partners.service";
import { DocumentsService } from "../../../../app/services/documents.service";

@Component({
	selector: "kt-unconfirmed",
	templateUrl: "./unconfirmed.component.html",
	styleUrls: ["./unconfirmed.component.scss"],
})
export class UnconfirmedComponent implements OnInit {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	expandedElement;

	unconfirmedPartners = [];
	displayedColumns: string[] = [
		"type",
		"business_unit",
		"main_contractor",
		"nomeCognomeLegale",
		"ragioneSociale",
		"agent",
		"agentEmail",
		"actions",
		"actions2",
	];
	dataSource: MatTableDataSource<any>;

	dialogConfig;
	selectedPartner;
	deals = [];
	partnerForm: FormGroup;
	myPartner: any;
	pattern =
		"^[a-zA-Z]{6}[0-9]{2}[a-zA-Z]{1}[0-9]{2}[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{1}$|^[0-9]{11}$|^[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}$";
	temp: any;

	constructor(
		private accountService: AccountPartnersService,
		private fb: FormBuilder,
		private superPowers: SuperpowersService,
		private accountPartnersService: AccountPartnersService,
		private docService: DocumentsService,
		private dialog: MatDialog,
		private chRef: ChangeDetectorRef
	) {
		this.dialogConfig = new MatDialogConfig();
		this.dialogConfig.disableClose = true;
		this.dialogConfig.autoFocus = true;
		this.dialogConfig.minWidth = "400px";
	}

	async ngOnInit() {
		this.unconfirmedPartners =
			await this.accountService.getUnconfirmedPartnersFromDB();

		this.initTable();
	}

	async accept() {
		console.log("Accepting partner", this.selectedPartner);

		await this.accountService
			.updateToSendingDocsFromUnconfirmed(this.selectedPartner.id)
			.then((res) => {
				this.sendConfirmationMail();
			})
			.catch((e) => {
				console.error(e);
			});
	}

	async sendConfirmationMail() {
		const name = !!this.myPartner.ragioneSociale
			? !!this.myPartner.ragioneSociale
			: this.myPartner.nomeCognomeLegale;
		const accountName = !!this.myPartner.agentUserByAccountId
			? this.myPartner.agentUserByAccountId.username
			: "Not found";
		const accountEmail = !!this.myPartner.agentUserByAccountId
			? this.myPartner.agentUserByAccountId.email
			: "Not found";

		this.accountService
			.sendEmailConfirmation(name, this.deals, accountName, accountEmail)
			.then((res) => {
				this.removePartnerFromTable();
				this.dialog.closeAll();
			})
			.catch((e) => {
				console.log(e);
			});
	}

	async reject() {
		console.log("Rejecting partner ", this.selectedPartner);

		await this.superPowers
			.changeStatus(this.selectedPartner.id, "listino")
			.then((res) => {
				this.dialog.closeAll();
			})
			.catch((e) => {
				console.error(e);
			});
	}

	removePartnerFromTable() {
		var partners = [];

		for (const p of this.unconfirmedPartners) {
			if (p.id === this.myPartner.id) {
			} else {
				partners.push(p);
			}
		}

		this.unconfirmedPartners = partners;
		this.initTable();
	}

	openEditPartner(editPartnerContent, row) {
		this.selectedPartner = row;

		this.superPowers
			.getPartnerInfo(this.selectedPartner.id)
			.then((res: any) => {
				this.myPartner = res.data.allPartnerPartners.nodes[0];
				this.myPartner.teamwork =
					!!this.myPartner.partnerTeamworksByPartnerId &&
					this.myPartner.partnerTeamworksByPartnerId.nodes.length > 0
						? this.myPartner.partnerTeamworksByPartnerId.nodes[
								this.myPartner.partnerTeamworksByPartnerId.nodes
									.length - 1
						  ].name
						: " - ";

				this.initPartnerForm();

				this.dialog.open(editPartnerContent);
			})
			.catch((err) => {
				console.error(err);
			});
	}

	async openDealsPartner(dealsContent, row) {
		this.deals = await this.accountPartnersService.getPartnerDealsFromDB(
			row.id
		);

		this.dialog.open(dealsContent, this.dialogConfig);
	}

	async updatePartner() {
		if (this.partnerForm.invalid) {
			this.partnerForm.markAllAsTouched();
			console.log("Invalid form");
			return;
		}

		if (this.partnerForm.value.teamwork !== this.myPartner.teamwork) {
			const created = await this.docService.createTeamworkInDB(
				this.partnerForm.value.teamwork,
				this.myPartner.id
			);
		}

		this.superPowers
			.updatePartner(this.selectedPartner.id, this.partnerForm.value)
			.then((res) => {
				this.temp = res;
				this.temp =
					this.temp.data.updatePartnerPartnerById.partnerPartner;

				this.selectedPartner.nomeCognomeLegale =
					this.temp.nomeCognomeLegale;
				// this.selectedPartner.telefonoGenerale = this.temp.telefonoGenerale
				// this.selectedPartner.emailGenerale = this.temp.emailGenerale
				// this.selectedPartner.pivaCodiceFiscale = this.temp.pivaCodiceFiscale
				this.selectedPartner.ragioneSociale = this.temp.ragioneSociale;
				// this.selectedPartner.numeroUscita = this.temp.numeroUscita
				this.selectedPartner.emailAmm = this.temp.emailAmm;
				// this.selectedPartner.nomeCognomeAmm = this.temp.nomeCognomeAmm
				// this.selectedPartner.telefonoAmm = this.temp.telefonoAmm
				// this.selectedPartner.nomeCognomeBo = this.temp.nomeCognomeBo
				// this.selectedPartner.emailBo = this.temp.emailBo
				// this.selectedPartner.refEmail = this.temp.refEmail
				// this.selectedPartner.roc = this.temp.roc
				// this.selectedPartner.notes = this.temp.notes
				this.selectedPartner.type =
					this.temp.type === "ge" ? "Agente" : "Agency";

				this.dialog.closeAll();
				this.chRef.detectChanges();
			})
			.catch((err) => {
				console.error(err);
			});
	}

	// HELPERS
	initPartnerForm() {
		this.partnerForm = this.fb.group({
			nomeCognomeLegale: [
				this.myPartner.nomeCognomeLegale,
				[Validators.required],
			],
			telefonoGenerale: [
				this.myPartner.telefonoGenerale,
				[Validators.required],
			],
			emailGenerale: [
				this.myPartner.emailGenerale,
				[Validators.required, Validators.email],
			],
			pivaCodiceFiscale: [
				this.myPartner.pivaCodiceFiscale,
				[Validators.required, Validators.pattern(this.pattern)],
			],
			ragioneSociale: [this.myPartner.ragioneSociale, []],
			numeroUscita: [this.myPartner.numeroUscita, []],
			emailAmm: [this.myPartner.emailAmm, [Validators.email]],
			nomeCognomeAmm: [this.myPartner.nomeCognomeAmm, []],
			telefonoAmm: [this.myPartner.telefonoAmm, []],
			nomeCognomeBo: [this.myPartner.nomeCognomeBo, []],
			emailBo: [this.myPartner.emailBo, [Validators.email]],
			refEmail: [this.myPartner.refEmail, [Validators.email]],
			roc: [this.myPartner.roc, []],
			notes: [this.myPartner.notes, []],
			type: [this.myPartner.type, [Validators.required]],
			teamwork: [this.myPartner.teamwork, []],
		});
	}

	initTable() {
		this.dataSource = new MatTableDataSource(this.unconfirmedPartners);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	// HELPERS
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	get p() {
		return this.partnerForm.controls;
	}

	promptReject(content, row) {
		this.selectedPartner = row;
		this.dialog.open(content, this.dialogConfig);
	}

	async promptAccept(content, row) {
		this.selectedPartner = row;
		this.dialog.open(content, this.dialogConfig);

		this.superPowers
			.getPartnerInfo(this.selectedPartner.id)
			.then((res: any) => {
				this.myPartner = res.data.allPartnerPartners.nodes[0];
			})
			.catch((err) => {
				console.error(err);
			});
		this.deals = await this.accountPartnersService.getPartnerDealsFromDB(
			row.id
		);
	}

	ngOnDestroy() {
		this.chRef.detach(); // do this
	}
}
