// Angular
import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";

import { AuthService, GoogleLoginProvider } from "angularx-social-login";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../../../../app/services/authentication.service";
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
	selector: "kt-user-profile",
	templateUrl: "./user-profile.component.html",
})
export class UserProfileComponent implements OnInit {
	// Public properties

	@Input() avatar = true;
	@Input() greeting = false;
	@Input() badge: boolean;
	@Input() icon: boolean;

	isSuperUser = false;
	isAnalystManager = false;

	hideSettings = true;
	hideSuperpowers = true;

	_user;

	/**
	 * Component constructor
	 *
	 *
	 */
	constructor(
		private googleAuth: AuthService,
		private router: Router,
		private authService: AuthenticationService,
		private chRef: ChangeDetectorRef
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.isSuperUser = this.authService.isSuperUser();
		this.isAnalystManager = this.authService.getUserRole() === 2;

		this.hideSuperpowers = !this.authService.isSuperUser();
		this.hideSettings = !(this.isAnalystManager || this.isSuperUser);
		this.chRef.markForCheck();

		this._user = JSON.parse(localStorage.getItem("googleUser"));

		// LOGGED IN WITH GOOGLE
		if (!!this._user) {
			this.googleAuth.authState.subscribe((user) => {
				this._user = user;
				if (!this._user) {
					console.log("USER IS NOT LOGGED IN WITH GOOGLE");
				}
			});
		}
		// NORMAL LOGIN
		else {
			const rawToken = localStorage.getItem("anagraficaToken");
			const helper = new JwtHelperService();
			const decodedToken = helper.decodeToken(rawToken);

			this._user = decodedToken.data;
			// this._user.photoUrl = "./assets/media/logos/fiber-nobg.png"
			this._user.name =
				this._user.first_name + " " + this._user.last_name;
		}
	}

	/**
	 * Log out
	 */
	logout() {
		this.googleAuth
			.signOut()
			.then((res) => {
				this.clearAndAuthenticate();
			})
			.catch((err) => {
				console.log(err);
				this.clearAndAuthenticate();
			});
	}

	clearAndAuthenticate() {
		window.location.replace("/auth/login");
	}

	ngOnDestroy() {
		this.chRef.detach();
	}
}
