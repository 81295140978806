import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import { AuthenticationService } from "./authentication.service";
import gql from "graphql-tag";

import SweetAlert from "sweetalert2";
import { environment } from "../../environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
	providedIn: "root",
})
export class SettingsService {
	brands = [];
	mandatarie = [];
	businessUnit = [];
	campaigns = [];
	listinos = [];
	users = [];
	resTemp; // TEMPORARY VARIBALE FOR QUERY RESULTS

	constructor(
		private http: HttpClient,
		private apollo: Apollo,
		private authService: AuthenticationService
	) {}

	// GET OPERATIONS (CALLS IN DB)
	async getHistory() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnersUpdateCommisionings {
							nodes {
								createdAt
								to
								cc
								subject
								partnerCampaignByCampaignId {
									id
									name
									partnerBrandByBrandId {
										id
										name
									}
								}
								partnerListingByListingId {
									id
									name
								}
								file
							}
						}
					}
				`,
				fetchPolicy: "cache-first",
			})
			.toPromise();
	}

	// GET OPERATIONS (CALLS IN DB)
	async getAccountMangers() {
		await this.authService.verifyToken();
		return await this.apollo
			.query({
				query: gql`
					{
						allAgentUsers(condition: { userType: 4 }) {
							nodes {
								email
							}
						}
					}
				`,
				fetchPolicy: "cache-first",
			})
			.toPromise();
	}

	// GET OPERATIONS (CALLS IN DB)
	async getAllBrands() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerBrands(condition: { deleted: false }) {
							nodes {
								id
								name
								mailtobo
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}
	// GET OPERATIONS (CALLS IN DB)
	async getAllAssociazione(idPartner) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allBuPartners(
							condition: { partnerId:${idPartner},deleted: false  }
							orderBy: ID_DESC
						) {
							nodes {
								bu
								createdAt
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}
	// GET OPERATIONS (CALLS IN DB)
	async getAllAssociazioneAccountManager(idPartner) {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allAccountManagerPartners(
							condition: { partnerId:${idPartner},deleted: false  }
							orderBy: CREATED_AT_DESC
						) {
							nodes {
								accountId
								createdAt
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}
	// GET OPERATIONS (CALLS IN DB)
	async getAllMandatarie() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allMandataries(condition: { deleted: false }) {
							nodes {
								id
								name
								pIva
								templateMail
								templateMailMainContractor
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}
	// GET OPERATIONS (CALLS IN DB)
	async getAllBusinessUnit() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allBusinessUnits(condition: { deleted: false }) {
							nodes {
								id
								name
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getAllCampaigns() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerCampaigns(condition: { deleted: false }) {
							nodes {
								id
								name
								mailtobo
								mandataria
								partnerBrandByBrandId {
									id
									name
								}
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getAllListinos() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerListings {
							nodes {
								id
								name
								flagConsorzio
								fee
								notes
								deleted
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	async getAllUsers() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allAgentUsers {
							nodes {
								id
								username
								firstName
								lastName
								email
								userType
								isSuperuser
								isActive
								email
								viewAllPartners
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
		// 1 #analyst
		// 2 #analyst manager
		// 3 #account
		// 4 #account manager
		// - #super user
	}

	async getAllTokens() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allAgentUsertokens {
							nodes {
								id
								token
								createdAt
								deleted
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise()
			.then((res) => {
				// console.log(res)
				this.resTemp = res;
				return this.resTemp.data.allAgentUsertokens.nodes;
			})
			.catch((err) => {
				console.log(err);
				return [];
			});
	}

	async disableToken(id, newToken) {
		let disabled = false;
		let createdObj = {
			status: false,
			createdAt: "",
			id: -1,
		};

		await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updateAgentUsertokenById(input:{
          clientMutationId:"3"
          id: ${id}
          agentUsertokenPatch:{
            deleted: true
          }
        }){
          clientMutationId
        }
      }
      `,
			})
			.toPromise()
			.then((res) => {
				console.log(res);
				disabled = true;
			})
			.catch((err) => console.error(err));

		if (disabled) {
			await this.apollo
				.mutate({
					mutation: gql`
        mutation{
          createAgentUsertoken(input:{
            clientMutationId:"3"
            agentUsertoken:{
              createdAt:"${new Date().toISOString().split("T")[0]}"
              updatedAt:"${new Date().toISOString().split("T")[0]}"
              deleted: false
              token:"${newToken}"
            }
          }){
            agentUsertoken{
              token
              id
              createdAt
            }
          }
        }
        `,
				})
				.toPromise()
				.then((res) => {
					console.log(res);
					this.resTemp = res;

					createdObj.id =
						this.resTemp.data.createAgentUsertoken.agentUsertoken.id;
					createdObj.createdAt =
						this.resTemp.data.createAgentUsertoken.agentUsertoken.createdAt;
					createdObj.status = true;
				})
				.catch((err) => {
					console.error(err);
				});
		}

		return createdObj;
	}

	// GET ( LOCAL OPERATIONS )
	async getLastAssocizioneFromDB(idPartner) {
		await this.getAllAssociazione(idPartner)
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.allBuPartners.nodes;
			})
			.catch((err) => {
				console.log(err);
			});

		// console.log('Brands from db')
		return this.resTemp[0];
	}
	// GET ( LOCAL OPERATIONS )
	async getLastAssociazioneAccountManagerFromDB(idPartner) {
		await this.getAllAssociazioneAccountManager(idPartner)
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.allAccountManagerPartners.nodes;
			})
			.catch((err) => {
				console.log(err);
			});
		return this.resTemp[0];
	}

	// GET ( LOCAL OPERATIONS )
	async getAllBrandsFromDB() {
		if (this.brands.length > 0) {
			// console.log('brands from service')
			return this.brands;
		}

		await this.getAllBrands()
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.allPartnerBrands.nodes;
				// console.log(this.resTemp)
			})
			.catch((err) => {
				console.log(err);
			});
		// console.log('Brands from db')
		this.brands = this.resTemp;
		return this.brands;
	}
	// GET ( LOCAL OPERATIONS )
	async getAllMandatarieFromDB() {
		if (this.mandatarie.length > 0) {
			// console.log('mandatarie from service')
			return this.mandatarie;
		}

		await this.getAllMandatarie()
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.allMandataries.nodes;
				// console.log(this.resTemp)
			})
			.catch((err) => {
				console.log(err);
			});
		// console.log('Brands from db')
		this.mandatarie = this.resTemp;
		return this.mandatarie;
	}
	// GET ( LOCAL OPERATIONS )
	async getAllBusinessUnitFromDB() {
		if (this.businessUnit.length > 0) {
			// console.log('mandatarie from service')
			return this.businessUnit;
		}

		await this.getAllBusinessUnit()
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.allBusinessUnits.nodes;
				// console.log(this.resTemp)
			})
			.catch((err) => {
				console.log(err);
			});
		// console.log('Brands from db')
		this.businessUnit = this.resTemp;
		return this.businessUnit;
	}

	async getAllCampaignsFromDB() {
		if (this.campaigns.length > 0) {
			// console.log('campaigns from service')
			return this.campaigns;
		}

		var campaigns = [];
		await this.getAllCampaigns()
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.allPartnerCampaigns.nodes;
				for (const campaign of this.resTemp) {
					campaigns.push({
						id: campaign.id,
						name: campaign.name,
						brandId: campaign.partnerBrandByBrandId.id,
						brandName: campaign.partnerBrandByBrandId.name,
						mailtobo: campaign.mailtobo,
						mandataria: campaign.mandataria,
					});
				}
				// console.log('Camapigns from db ', campaigns)
				this.campaigns = campaigns;
			})
			.catch((err) => {
				console.log(err);
			});
		return this.campaigns;
	}

	async getAllListinosFromDB() {
		if (this.listinos.length > 0) {
			// console.log('Listings from service')
			return this.listinos;
		}

		await this.getAllListinos()
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.allPartnerListings.nodes;
				// console.log(this.resTemp)
			})
			.catch((err) => {
				console.log(err);
			});
		// console.log('Listinos ', this.resTemp)
		this.listinos = this.resTemp;
		return this.resTemp;
	}

	async getAllUsersFromDB() {
		if (this.users.length > 0) {
			// console.log('users from service')
			return this.users;
		}

		let users = [];
		await this.getAllUsers()
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.allAgentUsers.nodes;
				for (const user of this.resTemp) {
					users.push({
						id: user.id,
						username: user.username,
						role: this.getRole(user.userType),
						superuser: user.isSuperuser,
						type: user.userType,
						active: user.isActive,
						email: user.email,
						firstname: user.firstName,
						surname: user.lastName,
						viewAllPartners: user.viewAllPartners,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
		// console.log('Users from db')
		this.users = users;
		return this.users;
	}

	// GET ROLE BASED ON NUMBER
	getRole(num) {
		if (num === 1) {
			return "Analyst";
		}
		if (num === 2) {
			return "Analyst Manager";
		}
		if (num === 3) {
			return "Account";
		}
		if (num === 4) {
			return "Account Manager";
		}
		return "-";
	}

	// UPDATE OPERATIONS (CALLS IN DB)
	async updateBrand(brandId, name) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerBrandById(input:{
          clientMutationId:"1"
          id:${brandId}
          partnerBrandPatch:{
			name:"${name}"
          }
        }){
          partnerBrand{
            id
            name
          }
        }
      }
      `,
			})
			.toPromise();
	}

	// UPDATE BU (CALLS IN DB)
	async updateBu(buId, name) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
			clientMutationId:"1"
			id:${buId}
          	partnerPartnerPatch:{
			bu:"${name}"
          }
        }){
			partnerPartner {
				bu
			  }
        }
      }
      `,
			})
			.toPromise();
	}
	// UPDATE Account manager (CALLS IN DB)
	async updateAccountManager(partnerId, accountId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerPartnerById(input:{
			clientMutationId:"1"
			id:${partnerId}
          	partnerPartnerPatch:{
			accountId:${accountId}
          }
        }){
			partnerPartner {
				accountId
			  }
        }
      }
      `,
			})
			.toPromise();
	}

	// UPDATE OPERATIONS (CALLS IN DB)
	async updateMandataria(MandatariaId, name, pIva) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updateMandatarieById(input:{
          clientMutationId:"1"
          id:${MandatariaId}
          mandatariePatch:{
			name:"${name}"
			pIva:"${pIva}"
          }
        }){
          mandatarie{
            id
            name
          }
        }
      }
      `,
			})
			.toPromise();
	}
	// UPDATE OPERATIONS (CALLS IN DB)
	async updateBusinessUnit(BusinessUnit) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updateBusinessUnitById(input:{
          clientMutationId:"1"
          id:${BusinessUnit.id}
          businessUnitPatch:{
			name:"${BusinessUnit.name}"
          }
        }){
          businessUnit{
            id
            name
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async updateCampaign(campaignId, mail, brandId, name, mandataria) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
				mutation{
					updatePartnerCampaignById(input:{
					clientMutationId:"1"
					id:${campaignId}
					partnerCampaignPatch:{
						name:"${name}"
						brandId:${brandId}
						mailtobo: ${mail}
						mandataria:"${mandataria}"
					}
					}){
					partnerCampaign{
						id
						name
						brandId
						partnerBrandByBrandId{
						id
						name
						}
					}
					}
				}
				`,
			})
			.toPromise();
	}

	async updateListing(listingId, name, flagC, fee, notes) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerListingById(input:{
          clientMutationId:"1"
          id:${listingId}
          partnerListingPatch:{
            name:"${name}"
            flagConsorzio:${flagC}
            fee:"${fee}"
            notes:"${notes}"
          }
        }){
          partnerListing{
            id
            name
            flagConsorzio
            fee
            notes
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async updateUser(
		userId,
		type,
		superuser,
		active,
		email,
		username,
		name,
		surname,
		viewAllPartners
	) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updateAgentUserById(
          input:{
            clientMutationId:"1"
            id:${userId}
            agentUserPatch:{
              isSuperuser: ${superuser}
              userType: ${type}
              isActive:${active}
              email: "${email}"
              username: "${username}"
              firstName: "${name}"
              lastName: "${surname}"
			  viewAllPartners: ${viewAllPartners}
            }
          }
        ){
          agentUser{
            isSuperuser
            userType
            username
            isActive
			viewAllPartners
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async resetPassword(email, password) {
		return this.http
			.post(`${environment.API_URL}resetpwd`, {
				email: email,
				password: password,
				token: localStorage.getItem("anagraficaToken"),
			})
			.toPromise();
	}

	// UPDATE OPERATIONS( LOCAL CALLS )
	async updateBrandInDB(brandId, name) {
		// await this.authService.verifyToken()
		let updated = false;

		await this.updateBrand(brandId, name)
			.then((res) => {
				updated = true;
			})
			.catch((err) => {
				console.log(err);
				updated = false;
			});

		// console.log(updated)
		return updated;
	}
	// UPDATE OPERATIONS( LOCAL CALLS )
	async updateMandatariaInDB(MandatariaId, name, pIva) {
		// await this.authService.verifyToken()
		let updated = false;

		await this.updateMandataria(MandatariaId, name, pIva)
			.then((res) => {
				updated = true;
			})
			.catch((err) => {
				console.log(err);
				updated = false;
			});

		// console.log(updated)
		return updated;
	}
	// UPDATE OPERATIONS( LOCAL CALLS )
	async updateBusinessUnitInDB(BusinessUnitId) {
		// await this.authService.verifyToken()
		let updated = false;

		await this.updateBusinessUnit(BusinessUnitId)
			.then((res) => {
				updated = true;
			})
			.catch((err) => {
				console.log(err);
				updated = false;
			});

		// console.log(updated)
		return updated;
	}

	async updateCampaignInDB(campaignId, mail, brandId, name, mandataria) {
		// await this.authService.verifyToken()
		let updated = false;

		await this.updateCampaign(campaignId, mail, brandId, name, mandataria)
			.then((res) => {
				updated = true;
			})
			.catch((err) => {
				console.log(err);
				updated = false;
			});

		return updated;
	}

	async updateListingInDB(listingId, name, flagC, fee, notes) {
		// await this.authService.verifyToken()
		let updated = false;

		await this.updateListing(listingId, name, flagC, fee, notes)
			.then((res) => {
				updated = true;
			})
			.catch((err) => {
				console.log(err);
				updated = false;
			});

		return updated;
	}

	async updateUserInDB(
		userId,
		type,
		superuser,
		active,
		email,
		username,
		name,
		surname,
		viewAllPartners
	) {
		// await this.authService.verifyToken()
		let updated = false;

		await this.updateUser(
			userId,
			type,
			superuser,
			active,
			email,
			username,
			name,
			surname,
			viewAllPartners
		)
			.then((res) => {
				updated = true;
			})
			.catch((err) => {
				console.log(err);
				updated = false;
			});

		return updated;
	}

	async resetPasswordInDb(email, password) {
		// await this.authService.verifyToken()
		let updated = false;

		await this.resetPassword(email, password)
			.then((res) => {
				updated = true;
			})
			.catch((err) => {
				console.log(err);
				updated = false;
			});

		return updated;
	}

	// DELETE OPERATIONS ( CALLS )
	async deleteBrand(brandId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerBrandById(input:{
          clientMutationId:"1"
          id:${brandId}
          partnerBrandPatch:{
            deleted: true
          }
        }){
          partnerBrand{
            id
            name
            deleted
          }
        }
      }
      `,
			})
			.toPromise();
	}
	// DELETE OPERATIONS ( CALLS )
	async deleteBusinessUnit(BusinessUnitId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updateBusinessUnitById(input:{
          clientMutationId:"1"
          id:${BusinessUnitId}
          businessUnitPatch:{
            deleted: true
          }
        }){
			businessUnit{
            id
            name
            deleted
          }
        }
      }
      `,
			})
			.toPromise();
	}
	async deleteMandataria(Id) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updateMandatarieById(input:{
          clientMutationId:"1"
          id:${Id}
          mandatariePatch:{
            deleted: true
          }
        }){
			mandatarie{
            id
            name
            deleted
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async deleteCampaign(campaignId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerCampaignById(input:{
          clientMutationId:"1"
          id:${campaignId}
          partnerCampaignPatch:{
            deleted: true
          }
        }){
          partnerCampaign{
            id
            name
            deleted
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async deleteListing(listingId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerListingById(input:{
          clientMutationId:"1"
          id:${listingId}
          partnerListingPatch:{
            deleted: true
          }
        }){
          partnerListing{
            id
            name
            deleted
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async reactivateListing(listingId) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        updatePartnerListingById(input:{
          clientMutationId:"1"
          id:${listingId}
          partnerListingPatch:{
            deleted: false
          }
        }){
          partnerListing{
            id
            name
            deleted
          }
        }
      }
      `,
			})
			.toPromise();
	}

	// DELETE OPERATIONS (LOCAL CALLS)
	async deleteBrandInDB(brandId) {
		// await this.authService.verifyToken()
		let deleted = false;

		await this.deleteBrand(brandId)
			.then((res) => {
				deleted = true;
			})
			.catch((err) => {
				console.log(err);
				deleted = false;
			});

		// console.log(deleted)
		return deleted;
	}
	// DELETE OPERATIONS (LOCAL CALLS)
	async deleteBusinessUnitInDB(BusinessUnit) {
		// await this.authService.verifyToken()
		let deleted = false;

		await this.deleteBusinessUnit(BusinessUnit)
			.then((res) => {
				deleted = true;
			})
			.catch((err) => {
				console.log(err);
				deleted = false;
			});

		return deleted;
	}
	// DELETE OPERATIONS (LOCAL CALLS)
	async deleteMandatariaInDB(Mandataria) {
		// await this.authService.verifyToken()
		let deleted = false;

		await this.deleteBusinessUnit(Mandataria)
			.then((res) => {
				deleted = true;
			})
			.catch((err) => {
				console.log(err);
				deleted = false;
			});

		return deleted;
	}

	async deleteCampaignInDB(campaignId) {
		// await this.authService.verifyToken()
		let deleted = false;

		await this.deleteCampaign(campaignId)
			.then((res) => {
				deleted = true;
			})
			.catch((err) => {
				console.log(err);
				deleted = false;
			});

		// console.log(deleted)
		return deleted;
	}

	async deleteListingInDB(listingId) {
		// await this.authService.verifyToken()
		let deleted = false;

		await this.deleteListing(listingId)
			.then((res) => {
				console.log(res);
				deleted = true;
			})
			.catch((err) => {
				console.log(err);
				deleted = false;
			});

		// console.log(deleted)
		return deleted;
	}

	async reactivateListingInDB(listingId) {
		// await this.authService.verifyToken()
		let reactivated = false;

		await this.reactivateListing(listingId)
			.then((res) => {
				console.log(res);
				reactivated = true;
			})
			.catch((err) => {
				console.log(err);
				reactivated = false;
			});

		// console.log(deleted)
		return reactivated;
	}

	// CREATE OPERATIONS (DB CALLS)
	async createBrand(name) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
						mutation {
							createPartnerBrand(
								input: {
									partnerBrand: {
										createdAt:"${new Date().toISOString().split("T")[0]}"
										updatedAt:"${new Date().toISOString().split("T")[0]}"
										deleted: false
										name: "${name}"
									}
								}
							) {
								partnerBrand {
									id
									createdAt
									deleted
									name
									mailtobo
								}
							}
						}
					`,
			})
			.toPromise();
	}

	// CREATE OPERATIONS (DB CALLS)
	async createMandataria(name, pIva) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
						mutation {
							createMandatarie(
								input: {
									mandatarie: {
										createdAt:"${new Date().toISOString().split("T")[0]}"
										updatedAt:"${new Date().toISOString().split("T")[0]}"
										deleted: false
										name: "${name}"
										pIva :"${pIva}"
									}
								}
							) {
								mandatarie {
									id
									createdAt
									deleted
									name
									pIva
								}
							}
						}
					`,
			})
			.toPromise();
	}
	// CREATE OPERATIONS (DB CALLS)
	async createBusinessUnit(name) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
						mutation {
							createBusinessUnit(
								input: {
									businessUnit: {
										createdAt:"${new Date().toISOString().split("T")[0]}"
										updatedAt:"${new Date().toISOString().split("T")[0]}"
										deleted: false
										name: "${name}"
									}
								}
							) {
								businessUnit {
									id
									createdAt
									deleted
									name
								}
							}
						}
					`,
			})
			.toPromise();
	}

	async createCampaign(name, mail, brandId, mandataria) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
					mutation{
						createPartnerCampaign(input:{
						clientMutationId:"${this.getUserId()}"
						partnerCampaign:{
							createdAt:"${new Date().toISOString().split("T")[0]}"
							updatedAt:"${new Date().toISOString().split("T")[0]}"
							deleted:false
							name:"${name}"
							brandId:${brandId}
							mailtobo: ${mail}
							mandataria:"${mandataria}"
						}
						}){
						partnerCampaign{
							id
							name
							deleted
							mandataria
							partnerBrandByBrandId{
							id
							name
							}
						}
						}
					}
					`,
			})
			.toPromise();
	}
	async createAssociazioneBuPartner(partnerId, bu) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
					mutation{
						createBuPartner(input:{
						clientMutationId:"${this.getUserId()}"
						buPartner:{
							createdAt:"${new Date().toISOString().split("T")[0]}"
							deleted:false
							partnerId:${partnerId}
							bu:"${bu}"
						}
						}){
							buPartner{
							id
							bu
						}
						}
					}
					`,
			})
			.toPromise();
	}
	async createAssociazioneBuPartnerSetDate(partnerId, bu, current) {
		await this.authService.verifyToken();
		let date;
		if (current) {
			date = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
				.toISOString()
				.split("T")[0];
		} else {
			date = new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				new Date().getDay()
			)
				.toISOString()
				.split("T")[0];
		}

		return await this.apollo
			.mutate({
				mutation: gql`
					mutation {
						createBuPartner(
							input: {
								buPartner: {
									createdAt: "${date}"
									deleted: false
									partnerId: ${partnerId}
									bu: "${bu}"
								}
							}
						) {
							buPartner {
								bu
								createdAt
								deleted
								partnerId
							}
						}
					}
				`,
			})
			.toPromise();
	}

	async createListing(name, flagC, fee, notes) {
		await this.authService.verifyToken();

		return await this.apollo
			.mutate({
				mutation: gql`
      mutation{
        createPartnerListing(input:{
          clientMutationId:"${this.getUserId()}"
          partnerListing:{
            createdAt:"${new Date().toISOString().split("T")[0]}"
            updatedAt:"${new Date().toISOString().split("T")[0]}"
            deleted:false
            name:"${name}"
            flagConsorzio:${flagC}
            fee:"${fee}"
            notes:"${notes}"
          }
        }){
          partnerListing{
            id
            name
            notes
            deleted
            fee
            flagConsorzio
          }
        }
      }
      `,
			})
			.toPromise();
	}

	async createUser(newUser) {
		return this.http
			.post(`${environment.API_URL}signup`, newUser)
			.toPromise();
	}

	// CREATE OPERATIONS (LOCAL CALLS)
	async createBrandInDB(name) {
		// await this.authService.verifyToken()
		let added = false;

		await this.createBrand(name)
			.then((res: any) => {
				this.brands.push(res.data.createPartnerBrand.partnerBrand);
			})
			.catch((err) => {
				console.log(err);
				added = false;
			});
		return added;
	}
	// CREATE OPERATIONS (LOCAL CALLS)
	async createMandatarieInDB(name) {
		// await this.authService.verifyToken()
		let added = false;

		await this.createBrand(name)
			.then((res: any) => {
				this.brands.push(res.data.createPartnerBrand.partnerBrand);

				added = true;
			})
			.catch((err) => {
				console.log(err);
				added = false;
			});
		return added;
	}

	async createCampaignInDB(name, mail, brandId, mandataria) {
		// await this.authService.verifyToken()
		let added = false;

		await this.createCampaign(name, mail, brandId, mandataria)
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp =
					this.resTemp.createPartnerCampaign.partnerCampaign;
				this.campaigns.push({
					id: this.resTemp.id,
					name: this.resTemp.name,
					brandId: this.resTemp.partnerBrandByBrandId.id,
					brandName: this.resTemp.partnerBrandByBrandId.name,
					mailtobo: this.resTemp.mailtobo,
				});
				added = true;
			})
			.catch((err) => {
				console.log(err);
				added = false;
			});
		return added;
	}

	async createListingInDB(name, flagC, fee, notes) {
		// await this.authService.verifyToken()
		let added = false;

		await this.createListing(name, flagC, fee, notes)
			.then((res) => {
				this.resTemp = res.data;
				this.resTemp = this.resTemp.createPartnerListing.partnerListing;
				this.listinos.push(this.resTemp);
				added = true;
			})
			.catch((err) => {
				console.log(err);
				added = false;
			});
		return added;
	}

	async getDealsForExport() {
		await this.authService.verifyToken();

		return await this.apollo
			.query({
				query: gql`
					{
						allPartnerDeals {
							nodes {
								id
								startMonth
								partnerContractByContractId {
									partnerPartnerByPartnerId {
										id
										pivaCodiceFiscale
										nomeCognomeLegale
										emailAmm
										emailGenerale
										emailBo
										type
										notes
										telefonoGenerale
										deleted
										agentUserByAccountId {
											firstName
											email
										}
										partnerTeamworksByPartnerId {
											nodes {
												name
											}
										}
									}
								}
								partnerListingByListingId {
									name
									fee
									flagConsorzio
									id
								}
								partnerCampaignByCampaignId {
									name
									id
								}
								partnerBrandByBrandId {
									name
								}
								runnersByDealId {
									nodes {
										id
										amount
										deleted
									}
								}
								isActive
							}
						}
					}
				`,
				fetchPolicy: "network-only",
			})
			.toPromise();
	}

	//RETURN DOCUMENT NAME
	async uploadDocument(document) {
		var data = new FormData();
		data.append("file", document);

		return await this.http
			.post(`${environment.API_URL}/upload`, data)
			.toPromise();
	}

	async createUserInDB(newUser) {
		let added = {
			status: false,
			message: "",
			user: {
				userType: -1,
				username: "",
				id: -1,
				isActive: false,
				isSuperuser: false,
				email: "",
			},
		};

		await this.createUser(newUser)
			.then((res) => {
				this.resTemp = res;
				added.status = true;

				const helper = new JwtHelperService();
				const decodedToken = helper.decodeToken(this.resTemp.token);

				added.user = JSON.parse(decodedToken.data.extra_data);
				added.user.id = decodedToken.data.id;
			})
			.catch((err) => {
				console.log(err);
				added.status = false;
				added.message = err;
			});
		return added;
	}

	getAllDetails(conditions, startMonth) {
		return this.apollo
			.query({
				query: gql`
			{
				allPartnerDeals${conditions} {
				  nodes {
					partnerDetailsByDealId(filter: {
						meseDiCompetenza:{greaterThan:"${startMonth}"}
					}) {
					  nodes {
						id
						deleted
						googleSheetLink
						status
						dealId
						amountIn
						amountOut
						anticipo
						meseDiCompetenza
						volume
						emailStatus
						partnerPartnerByPartnerId{
						  id
						  nomeCognomeLegale
						  ragioneSociale
						  emailAmm
						}
						partnerDealByDealId{
							partnerCampaignByCampaignId{
							  name
							}
						  }
					  }
					}
				  }
				}
			  }
			`,
			})
			.toPromise();
	}

	notify(msg) {
		SweetAlert.fire({
			title: msg,
			timer: 1500,
		});
	}

	getUserId() {
		// TO DO, CHANGE AFTER TOKEN ID
		return 2;
	}
}
