import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AccountPartnersService } from "../../../../../app/services/account-partners.service";
import { AuthenticationService } from "../../../../../app/services/authentication.service";
import { Router } from "@angular/router";

export interface PartnerData {
	status: string;
	type: string;
	emailAmm: string;
	emailGenerale;
	ragioneSociale: string;
}

@Component({
	selector: "kt-new-partners",
	templateUrl: "./new-partners.component.html",
	styleUrls: ["./new-partners.component.scss"],
})
export class NewPartnersComponent implements OnInit {
	partners = [];
	agents = [];
	partnerTemp;
	dialogConfig;
	partnerIdToBeAssigned = -1;
	partnerIdToBeDeleted = -1;
	selectedAgent = 0;

	isSuperuser = false;
	displayedColumns: string[] = [
		"status",
		"type",
		"main_contractor",
		"nomeCognomeLegale",
		"ragioneSociale",
		"actions",
	];
	dataSource: MatTableDataSource<PartnerData>;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(
		private router: Router,
		private accountPartnersService: AccountPartnersService,
		private dialog: MatDialog,
		private authService: AuthenticationService
	) {
		this.dialogConfig = new MatDialogConfig();
		this.dialogConfig.disableClose = true;
		this.dialogConfig.autoFocus = true;
		this.dialogConfig.minWidth = "400px";
	}

	async ngOnInit() {
		this.isSuperuser = this.authService.isSuperUser();
		this.partners =
			await this.accountPartnersService.getNewPartnersFromDB();
		this.initTable();
		this.agents = await this.accountPartnersService.getAllAgentsFromDB();
		// console.log(this.partners, this.agents)
	}

	openAccountContent(accountContent, partnerId) {
		this.partnerIdToBeAssigned = partnerId;
		this.selectedAgent = 0;
		this.dialog.open(accountContent, this.dialogConfig);
	}

	promptDeactivate(deactivateContent, partnerId) {
		this.partnerIdToBeDeleted = partnerId;

		this.dialog.open(deactivateContent, this.dialogConfig);
	}

	async deactivateAccount() {
		await this.accountPartnersService
			.deactivatePartner(this.partnerIdToBeDeleted)
			.then((res) => {
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
			});
	}

	async assignAccount() {
		if (!(this.partnerIdToBeAssigned > 0) || !(this.selectedAgent > 0)) {
			console.log("Invalid inputs for assigning");
			return;
		}

		let assigned = false;
		await this.accountPartnersService
			.assignAccount(this.partnerIdToBeAssigned, this.selectedAgent)
			.then((res) => {
				// console.log(res)
				assigned = true;
			})
			.catch((err) => {
				console.log(err);
			});

		if (assigned) {
			for (
				let i = 0;
				i < this.accountPartnersService.newPartners.length;
				i++
			) {
				if (
					this.accountPartnersService.newPartners[i].id ===
					this.partnerIdToBeAssigned
				) {
					// console.log('Found partner which has been assigned an account')
					this.accountPartnersService.newPartners.splice(i, 1);
					break;
				}
			}
			this.partners = this.accountPartnersService.newPartners;
			this.dataSource.data = this.partners;
			this.dialog.closeAll();
		} else {
			// console.log('Could not assign account to partner')
		}
	}

	initTable() {
		this.dataSource = new MatTableDataSource(this.partners);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	// HELPERS
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	findPartnerById(partnerId) {
		//loop to find partner by id
	}

	/**
	 * Returens item CSS Class Name by status
	 *
	 * @param status: string
	 */
	getStatus(status: string = "new"): string {
		switch (status) {
			case "new":
				return "danger";
			case "listino":
				return "brand";
			case "sending_documentation":
				return "info";
			case "docs_confirmed":
				return "primary";
			case "teamwork":
				return "warning";
			case "confirmed":
				return "success";
		}
		return "";
	}
}
