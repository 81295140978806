import {
	Component,
	OnInit,
	ViewChild,
	Output,
	EventEmitter,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PartnersService } from "../../../../../app/services/partners.service";

export interface PartnerData {
	nr: string;
	status: string;
	type: string;
	emailAmm: string;
	ragioneSociale: string;
	account: string;
	accountConfirmationTimestamp: string;
	docs: any;
}

@Component({
	selector: "kt-wip",
	templateUrl: "./wip.component.html",
	styleUrls: ["./wip.component.scss"],
})
export class WipComponent implements OnInit {
	partners = [];
	partnerTemp;

	@Output() wipNr = new EventEmitter<number>();

	displayedColumns: string[] = [
		"nr",
		"status",
		"type",
		"ragioneSociale",
		"account",
		"business_unit",
		"main_contractor",
		"confTime",
		"docs",
	];
	dataSource: MatTableDataSource<PartnerData>;
	arrayStatus = [
		"new",
		"listino",
		"sending documentation",
		"docs confirmed",
		"external app",
		"confirmed",
	];

	filter: any = "";
	filterStatus;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	constructor(private partnersService: PartnersService) {}

	async ngOnInit() {
		await this.getPartnersFromDB();

		this.generateTable();
		this.wipNr.emit(this.partners.length);
	}

	/**
	 * Returens item CSS Class Name by status
	 *
	 * @param status: string
	 */
	getStatus(status: string = "new"): string {
		switch (status) {
			case "new":
				return "danger";
			case "listino":
				return "brand";
			case "sending_documentation":
				return "info";
			case "docs_confirmed":
				return "primary";
			case "teamwork":
				return "warning";
			case "confirmed":
				return "success";
		}
		return "";
	}

	generateTable() {
		this.dataSource = new MatTableDataSource(this.partners);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;

		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {
				case "confTime": {
					return new Date(item.accountConfirmationTimestamp);
				}
				default:
					return item[property];
			}
		};
	}

	async getPartnersFromDB() {
		await this.partnersService
			.getPartners(true, false)
			.then((res) => {
				this.partnerTemp = res.data;
				this.partnerTemp = this.partnerTemp.allPartnerPartners.nodes;

				for (const partner of this.partnerTemp) {
					// console.log(partner)
					this.partners.push({
						id: partner.id,
						accountConfirmationTimestamp:
							!!partner.accountConfirmationTimestamp
								? new Date(
										partner.accountConfirmationTimestamp
								  ).toDateString()
								: "-",
						accountConfirmation: partner.accountConfirmation,
						analystConfirmation: partner.analystConfirmation,
						status: this.partnersService.getStatus(partner.status),
						realStatus: partner.status,
						bu: partner.bu,
						isMainContractor: partner.mainContractor,
						mainContractor: partner.nomeMainContractor,
						type:
							partner.type === "gy"
								? "Agency"
								: partner.type === "ge"
								? "Agente"
								: "-",
						emailAmm: !!partner.emailAmm
							? partner.emailAmm
							: partner.emailGenerale,
						nomeCognomeLegale: !!partner.nomeCognomeLegale
							? partner.nomeCognomeLegale
							: "-",
						ragioneSociale: !!partner.ragioneSociale
							? partner.ragioneSociale
							: "-",
						account: !!partner.agentUserByAccountId
							? partner.agentUserByAccountId.firstName +
							  " " +
							  partner.agentUserByAccountId.lastName
							: "-",
					});
				}
				console.log("WIP FROM DB", this.partners);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	// HELPERS
	applyFilter(event: Event) {
		if (event) {
			if (event.target) {
				this.filter = (event.target as HTMLInputElement).value;
				this.filterStatus = null;
			} else {
				this.filter = event;
			}
			this.dataSource.filter = this.filter.trim().toLowerCase();

			if (this.dataSource.paginator) {
				this.dataSource.paginator.firstPage();
			}
		} else {
			this.dataSource.filter = null;
		}
	}
}
