import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";

import * as $ from "jquery";
import { AccountPartnersService } from "../../../../../app/services/account-partners.service";
import { DocumentsService } from "../../../../../app/services/documents.service";
import { AuthenticationService } from "../../../../../app/services/authentication.service";
import Swal from "sweetalert2";
import { SettingsService } from "../../../../services/settings.service";

@Component({
	selector: "kt-create",
	templateUrl: "./create.component.html",
	styleUrls: ["./create.component.scss"],
})
export class CreateComponent implements OnInit {
	partnerForm: FormGroup;
	pattern =
		"^[a-zA-Z]{6}[0-9]{2}[a-zA-Z]{1}[0-9]{2}[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{1}$|^[0-9]{11}$|^[a-zA-Z]{1}[0-9]{8}[a-zA-Z]{1}$";
	valid = ["png", "jpg", "pdf", "doc", "jpeg"];

	docRic;
	visCam;
	businessUnit;
	docRicValid = false;
	visCamValid = false;
	submitted = false;
	partnersMain;
	loading = false;
	emails = [];
	allPadri;
	temp;
	docTemp;
	// True se è stato trovato un duplicato prima dell'inserimento nel db
	duplicate = false;

	isAccount = false;

	constructor(
		private authService: AuthenticationService,
		private docsService: DocumentsService,
		private fb: FormBuilder,
		public dialog: MatDialog,
		private router: Router,
		private accountPartnerService: AccountPartnersService,
		private _cdr: ChangeDetectorRef,
		private settingService: SettingsService
	) {}

	ngOnInit() {
		this.isAccount = this.authService.getUserRole() === 3;
		// Initialize sendTo
		$(".sendTo").select2({
			tags: true, // Allow custom values
			allowClear: true,
			theme: "classic",
			multiple: true,
			data: [],
			createTag: function (params) {
				const re =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var term = $.trim(params.term);

				if (!re.test(String(term).toLowerCase())) {
					return null;
				}

				return {
					id: term,
					text: term,
					newTag: true, // add additional parameters
				};
			},
		});

		this.authService.getUserId();
		this.initNewPartnerStep();
		this.fileNameAppear();
	}

	async initNewPartnerStep() {
		this.partnerForm = this.fb.group({
			nomeCognomeLegale: ["", [Validators.required]],
			telefonoGenerale: [
				"",
				[Validators.required, Validators.pattern("[- +()0-9]+")],
			],
			pivaCodiceFiscale: [
				"",
				[Validators.required, Validators.pattern(this.pattern)],
			],
			ragioneSociale: ["", []],
			numeroUscita: ["", []],
			emailAmm: ["", [Validators.email]],
			nomeCognomeAmm: ["", []],
			telefonoAmm: ["", []],
			nomeCognomeBo: ["", []],
			emailBo: ["", [Validators.email]],
			refEmail: ["", [Validators.email]],
			roc: ["", []],
			notes: ["", []],
			mainContractor: [null, []],
			isMainContractor: [false, []],
			padre: [null, []],
			isPadre: [false, []],
			type: ["", [Validators.required]],
			businessUnit: ["", [Validators.required]],
			accountId: [this.authService.getUserId()],
		});
		this.businessUnit =
			await this.settingService.getAllBusinessUnitFromDB();
		this.partnersMain =
			await this.accountPartnerService.getAllMainContractorFromDB();
		this.allPadri =
			await this.accountPartnerService.getPartnerPadreFromDB();
		console.log(this.allPadri);
	}

	async createPartner() {
		this.emails = $("#sendTo").val();

		this.submitted = true;
		let duplicate = false;
		let pIvaCF = this.partnerForm.get("pivaCodiceFiscale").value;
		if (
			this.partnerForm.invalid ||
			!this.visCamValid ||
			!this.docRicValid ||
			this.emails.length == 0
		) {
			return;
		}
		await this.accountPartnerService
			.getAllPartnersNoFilter()
			.then((res) => {
				let partnerTemp: any = res.data;
				partnerTemp = partnerTemp.allPartnerPartners.nodes;

				partnerTemp.some((partner) => {
					return (duplicate = partner.pivaCodiceFiscale === pIvaCF);
				});
				this.duplicate = duplicate;
				this._cdr.detectChanges();
			});
		if (!duplicate) {
			this.loading = true;
			let pId = await this.accountPartnerService
				.createPartner(this.partnerForm.value)
				.then((res) => {
					this.temp = res.data;
					this.temp =
						this.temp.createPartnerPartner.partnerPartner.id;
					this.settingService
						.createAssociazioneBuPartner(
							this.temp,
							this.partnerForm.get("businessUnit").value
						)
						.then();
					this.saveEmail(this.temp);
					this.docsService
						.uploadDocument(this.docRic)
						.then((res) => {
							this.docTemp = res;
							this.docTemp = this.docTemp.data.name;
						})
						.catch((err) => {
							console.log(err);
						});

					this.docsService
						.addDocument(this.docTemp, null, null, this.temp)
						.then((res) => {})
						.catch((err) => {
							console.log(err);
						});

					this.docsService
						.uploadDocument(this.visCam)
						.then((res) => {
							this.docTemp = res;
							this.docTemp = this.docTemp.data.name;
						})
						.catch((err) => {
							console.log(err);
						});

					this.docsService
						.addDocument(this.docTemp, null, this.temp, null)
						.then((res) => {})
						.catch((err) => {
							console.log(err);
						});
					this.loading = false;
					Swal.fire(
						"Success!",
						"Partner Created successfully.",
						"success"
					);
					this.router.navigateByUrl("/account-partner");
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}

	// Get control to Form easily
	get p() {
		return this.partnerForm.controls;
	}

	fileNameAppear() {
		// Add the following code if you want the name of the file appear on select
		$(".custom-file-input").on("change", function () {
			var fileName = $(this).val().split("\\").pop();
			$(this)
				.siblings(".custom-file-label")
				.addClass("selected")
				.html(fileName);
		});
	}

	onFileSelectedVisura(event) {
		this.visCam = event.target.files[0];

		if (
			this.valid.includes(this.visCam.name.split(".").pop()) &&
			this.visCam.size < 14076672
		) {
			console.log("File extension and size valid");
			this.visCamValid = true;
		} else {
			console.log("Invalid File");
			this.visCamValid = false;
		}
	}

	onFileSelectedDoc(event) {
		this.docRic = event.target.files[0];

		if (
			this.valid.includes(this.docRic.name.split(".").pop()) &&
			this.docRic.size < 14076672
		) {
			console.log("File extension and size valid");
			this.docRicValid = true;
		} else {
			console.log("Invalid File");
			this.docRicValid = false;
		}
	}
	disableMainContractor() {
		let isMainContractor: boolean =
			!this.partnerForm.value.isMainContractor;

		if (isMainContractor) {
			this.partnerForm.get("mainContractor").reset();
			this.partnerForm.get("mainContractor").disable();
			this.partnerForm.get("padre").reset();
			this.partnerForm.get("padre").disable();
		} else {
			this.partnerForm.get("mainContractor").enable();
			this.partnerForm.get("padre").enable();
		}
	}

	async saveEmail(idPartner) {
		let emails = $("#sendTo").val();
		emails.forEach((email) => {
			this.accountPartnerService.saveEmails(idPartner, email);
		});
	}
}
