import { NgModule} from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import { HttpLinkModule, HttpLink,} from 'apollo-angular-link-http';
import { InMemoryCache} from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

const url = `${environment.API_URL}graphql`; // <-- add the URL of the GraphQL server here

const middleware = new ApolloLink((operation, forward) => {
  // Check if token exists
  const token = localStorage.getItem('anagraficaToken');
  if (!token) return forward(operation);

  //console.log('ADDING TOKEN TO HEADERS ', token)
  operation.setContext({
    headers: new HttpHeaders().set(
      'Authorization',
      `Bearer ${token}`,
    ),
  });
  return forward(operation);
});

export function createApollo(httpLink: HttpLink) {
  const http = httpLink.create({ uri: url });

  const link = middleware.concat(http);
  //const updatedLink = errorLink.concat(link)

  return {
    link: link,
    cache: new InMemoryCache({
      addTypename: false
    })
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}